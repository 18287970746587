import { ActivityIntervalDto, DailyActivityRowItem } from 'backend'
import { Button, Form, HFlow, Styles, Text, Tooltip, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { CircularProgressBar } from 'components/CircularProgress'
import { IconButton } from 'components/IconButton'
import OnBlur from 'components/OnBlur'
import { TimeField } from 'components/time/TimeField'
import { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { getTodayInFormat } from 'utils/DateUtils'
import { asPrettyTime, currentTime } from 'utils/TimeUtils'
import ObservationModal from 'view/dailyregister/modal/ObservationModal'

import { isDayWork } from './utils/DailyRegisterUtils'

export interface TodayRegisterViewProps extends WithStylesProps {
  dailyRegister: DailyActivityRowItem
  todayAmount: moment.Duration
  percent: number
  disabled?: boolean
  onValueChange(item: DailyActivityRowItem): any
  load(): any
  save?(form: DailyActivityRowItem): Promise<any>
  onSubmitSucceeded(): void
}

export interface TodayRegisterViewState {
  openObservationModal: boolean
  openJustificationModal: boolean
}

interface LastTimeDto {
  name: string
  isStart: boolean
  allTimesFilled: boolean
}

@withStyles
class TodayRegisterView extends React.Component<TodayRegisterViewProps, TodayRegisterViewState> {
  constructor(props) {
    super(props)
    this.state = {
      openObservationModal: false,
      openJustificationModal: false,
    }
  }

  componentDidMount() {
    if (this.props.dailyRegister) {
      this.props.onValueChange(this.props.dailyRegister)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dailyRegister && !isEqual(this.props.dailyRegister, prevProps.dailyRegister)) {
      this.props.onValueChange(this.props.dailyRegister)
    }
  }

  componentWillMount() {
    this.props.load()
  }

  render() {
    const { dailyRegister } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitSucceeded={this.props.onSubmitSucceeded}
        render={this.renderForm}
        initialValues={dailyRegister}
        subscription={{ values: true, submitErrors: true }}
      />
    )
  }

  renderForm = (props: FormRenderProps) => {
    const { css, percent, todayAmount, theme } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        color: theme.pallete.text.main,
        border: `solid 1px ${theme.pallete.divider}`,
        height: '7.25rem',
        borderRadius: '0.25rem',
        padding: '2rem 1rem',
        display: 'flex',
        alignItems: 'center',
      },
      startend: {
        paddingLeft: '1rem',
      },
      timefield: {
        display: 'flex',
      },
      buttons: {
        paddingLeft: '2.5rem',
        marginTop: '1.2rem',
      },
      dailycount: {
        marginTop: '1.2rem',
        flex: 1,
      },
      buttonInOutContainer: {
        width: '9rem',
        paddingTop: '1.25rem',
      },
      buttonInOut: {
        padding: '0.25rem 0.5rem',
      },
    }

    const value = props.values as DailyActivityRowItem
    const lastEmptyTime = this.getLastEmptyTimeField(value) as LastTimeDto
    const rowReadOnly: boolean = !isDayWork(this.props.dailyRegister)

    return (
      <div className={css(styles.container)}>
        <OnBlur callback={props.form.submit} ignoreFields={['noteText', 'absenceType']} />
        <TodayInfo />
        <HFlow style={styles.buttonInOutContainer} justifyContent='flex-end'>
          {lastEmptyTime.allTimesFilled ? null : (
            <Tooltip text={rowReadOnly ? 'Não há horário semanal para este dia' : null}>
              <Button
                skin='outline'
                kind='primary'
                onClick={this.onChegueiClick(props, lastEmptyTime.name)}
                style={styles.buttonInOut}
                disabled={rowReadOnly ? true : this.props.disabled}
              >
                {lastEmptyTime.isStart ? 'Cheguei!' : 'Tô saindo'}
              </Button>
            </Tooltip>
          )}
        </HFlow>
        <TodayTimeInputs
          containerStyle={css(styles.timefield)}
          startEndStyle={css(styles.startend)}
          disabled={rowReadOnly ? true : this.props.disabled}
          rowReadOnly={rowReadOnly}
        />
        <div className={css(styles.buttons)}>
          <HFlow hSpacing={0}>
            <Tooltip text={rowReadOnly ? 'Não há horário semanal para este dia' : 'Observação'}>
              <IconButton
                icon='fileEmptyOutline'
                onClick={this.onObservationClick}
                kind={value.noteText ? 'primary' : 'normal'}
                disabled={rowReadOnly ? true : this.props.disabled}
              />
            </Tooltip>
          </HFlow>
          <ObservationModal
            isOpen={this.state.openObservationModal}
            onCloseOrBackdropClick={this.onCloseOrBackdropClick}
            initialValue={value}
            onSaveClick={this.onObservationSave(props)}
            onRemoveClick={this.onObservationRemove(props)}
          />
        </div>
        <DailyAmount
          style={css(styles.dailycount)}
          expectedAmount={value.expectedDailyTimeAmount}
          totalDailyAmount={todayAmount}
          percent={percent}
        />
      </div>
    )
  }

  handleSubmit = (value: DailyActivityRowItem) => {
    return this.props.save(value).then(res => {
      this.props.onValueChange(value)
      return res
    })
  }

  onObservationClick = () => {
    this.setState({ openObservationModal: true })
  }

  onObservationSave = (formProps: FormRenderProps) => value => {
    value.absenceHours = moment.duration(value.absenceHours).toISOString()
    formProps.form.change('noteText', value.noteText)
    formProps.form.change('absenceHours', value.absenceHours)
    formProps.handleSubmit()
  }

  onObservationRemove = (formProps: FormRenderProps) => () => {
    formProps.form.change('noteText', undefined)
    formProps.form.change('absenceHours', undefined)
    formProps.handleSubmit()
  }

  onCloseOrBackdropClick = () => {
    this.setState({ openObservationModal: false, openJustificationModal: false })
  }

  onChegueiClick = (formProps: FormRenderProps, name: string) => () => {
    formProps.form.change(name, currentTime())
    formProps.handleSubmit()
  }

  getLastEmptyTimeField = (value: DailyActivityRowItem) => {
    let lastField = {}
    if (value && value.activityIntervals && value.activityIntervals.length > 0) {
      let fieldsFilled = 0
      value.activityIntervals.forEach(item => {
        if (item.startTime && item.endTime) {
          fieldsFilled = fieldsFilled + 1
        }
      })

      if (fieldsFilled === 3) {
        return { allTimesFilled: true }
      }

      lastField = this.getLastTimeFieldOnInterval(value.activityIntervals[0], 0)
      if (lastField) {
        return lastField
      }

      lastField = this.getLastTimeFieldOnInterval(value.activityIntervals[1], 1)
      if (lastField) {
        return lastField
      }

      lastField = this.getLastTimeFieldOnInterval(value.activityIntervals[2], 2)
      if (lastField) {
        return lastField
      }
    }
    return {
      name: `activityIntervals[${0}].startTime`,
      isStart: true,
    }
  }

  getLastTimeFieldOnInterval = (interval: ActivityIntervalDto, index: number) => {
    if (!interval || !interval.startTime) {
      return { name: `activityIntervals[${index}].startTime`, isStart: true }
    }
    if (!interval.endTime) {
      return { name: `activityIntervals[${index}].endTime`, isStart: false }
    }
    return null
  }
}

const TodayInfo = () => {
  return (
    <VFlow vSpacing={0}>
      <Text color='secondary'> Hoje </Text>
      <Text fontWeight='bold' fontSize={0.875}>
        {getTodayInFormat('D [de] MMM')}
      </Text>
      <Text color='secondary'> {getTodayInFormat('dddd')} </Text>
    </VFlow>
  )
}

const TodayTimeInputs = ({ containerStyle, startEndStyle, disabled, rowReadOnly }) => {
  return (
    <div className={containerStyle}>
      <HFlow hSpacing={0.5}>
        <StartEndField style={startEndStyle} index={0} disabled={disabled} rowReadOnly={rowReadOnly} />
        <StartEndField style={startEndStyle} index={1} disabled={disabled} rowReadOnly={rowReadOnly} />
        <StartEndField style={startEndStyle} index={2} disabled={disabled} rowReadOnly={rowReadOnly} />
      </HFlow>
    </div>
  )
}

const StartEndField = ({ style, index, disabled, rowReadOnly }) => {
  return (
    <div className={style}>
      <HFlow hSpacing={0.5}>
        <VFlow vSpacing={0.1}>
          <Text fontWeight='bold'> Entrada </Text>
          <Tooltip text={rowReadOnly ? 'Não há horário semanal para este dia' : null}>
            <TimeField disabled={disabled} name={`activityIntervals[${index}].startTime`} />
          </Tooltip>
        </VFlow>
        <VFlow vSpacing={0.1}>
          <Text fontWeight='bold'> Saída </Text>
          <Tooltip text={rowReadOnly ? 'Não há horário semanal para este dia' : null}>
            <TimeField disabled={disabled} name={`activityIntervals[${index}].endTime`} />
          </Tooltip>
        </VFlow>
      </HFlow>
    </div>
  )
}

const DailyAmount = ({ style, totalDailyAmount, expectedAmount, percent }) => {
  return (
    <div className={style}>
      <HFlow justifyContent='flex-end'>
        <CircularProgressBar sqSize={32} percentage={percent} strokeWidth={5} />
        <VFlow alignItems='flex-end' vSpacing={0}>
          <Text fontWeight='bold' fontSize={0.875}>
            {' '}
            {asPrettyTime(totalDailyAmount)}{' '}
          </Text>
          <Text color='secondary'> de {asPrettyTime(moment.duration(expectedAmount))} </Text>
        </VFlow>
      </HFlow>
    </div>
  )
}

export default TodayRegisterView
