import { messages as ptBr } from './messages'

export type MessageKey =
  | 'beforeEqual130Years'
  | 'beforeEqualToday'
  | 'empty'
  | 'invalid'
  | 'length'
  | 'minLength'
  | 'maxLength'
  | 'match'
  | 'nome'
  | 'nomeInvalido'
  | 'nomeOneLetter'
  | 'nomeTwoLetters'
  | 'range'
  | 'minRange'
  | 'maxRange'
  | 'required'
  | 'senha'
  | 'senhaSpecialChars'

export type ValidationMessages = { [key in MessageKey]: (value: any, ...args: any[]) => string }

export const msg = (key: MessageKey, value: any, ...args: any[]): string => ptBr[key](value, ...args)
