import { ProfileDto } from 'backend'
import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import * as React from 'react'

interface TelephonePanelProps {
  profileDto: ProfileDto
}

export default function TelephonePanel(props: TelephonePanelProps) {
  const { profileDto } = props

  const phoneMasked = (phone: string) => {
    if (profileDto.phone && profileDto.phone.length === 11) {
      const part1 = phone.slice(0, 2)
      const part2 = phone.slice(2, 7)
      const part3 = phone.slice(7, 11)
      return `(${part1}) ${part2}-${part3}`
    } else {
      const part1 = phone.slice(0, 2)
      const part2 = phone.slice(2, 6)
      const part3 = phone.slice(6, 10)
      return `(${part1}) ${part2}-${part3}`
    }
  }

  return (
    <Grid wrap>
      <Cell xs={5}>
        <InfoLabel title='Sala' placeholder='-'>
          <Text>{profileDto.room ? profileDto.room : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={6}>
        <InfoLabel title='Ramal' placeholder='-'>
          <Text>{profileDto.phoneLineExtension ? profileDto.phoneLineExtension : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={5}>
        <InfoLabel title='Celular' placeholder='-'>
          <Text>{profileDto.phone ? phoneMasked(profileDto.phone) : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={6}>
        <InfoLabel title='Celular (2)' placeholder='-'>
          <Text>{profileDto.secondaryPhone ? phoneMasked(profileDto.secondaryPhone) : '-'}</Text>
        </InfoLabel>
      </Cell>
    </Grid>
  )
}
