import { SelectJobFunctionDto } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'

export interface FunctionSelectFieldProps {
  jobFunctions: SelectJobFunctionDto[]
  required: boolean
  label: string
  name: string
}

export const FunctionSelectField = (props: FunctionSelectFieldProps) => {
  const { jobFunctions, ...rest } = props

  const itemToString = (item: SelectJobFunctionDto) => {
    return item && item.name
  }

  return <SelectField<SelectJobFunctionDto> items={jobFunctions} itemToString={itemToString} {...rest} />
}
