import { AxiosError } from 'axios'
import { DailyActivityRowItem } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import DailyRegisterTable from 'view/dailyregister/table/DailyRegisterTable'

export interface OwnProps extends RouteComponentProps {
    onValueChange(values: Object): void
    load(): any
    onSubmitSucceeded(): void
}

export const mapStateToProps = (state, ownProps: OwnProps) => ({
    onValueChange: ownProps.onValueChange,
    dailyRegisters: DailyRegisterRequester.fetchAll.getResult(state),
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
    save: (forms: DailyActivityRowItem[]) => {
        dispatch(DailyRegisterRequester.createAll.setParams(forms))
        return dispatch(DailyRegisterRequester.createAll.request()).catch((res: AxiosError) => {
            const errors = { dailyRegisters: res.response.data.values }
            return Promise.reject({ response: { data: errors, status: 400 } })
        })
    },
    onSubmitSuccess() {
        ownProps.onSubmitSucceeded()
        ownProps.history.push(`/dailyregister/current/visualize`)
    },
})

export default withRouter(connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps)(DailyRegisterTable))
