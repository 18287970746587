import { AbsenceDto } from 'backend'
import { TableCell, TableRow, Text, useStyles } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { createCollaboratorVacationTableStyles } from 'view/collaborator/vacation/table/CollaboratorVacationTableStyles'

export interface CollaboratorVacationTableRowItemProps {
  data: AbsenceDto
}

export function CollaboratorVacationTableRowItem(props: CollaboratorVacationTableRowItemProps) {
  const { classes } = useStyles(createCollaboratorVacationTableStyles)
  const startVacationDate = new Date(props.data.startDate)
  const endVacationDate = new Date(props.data.endDate)
  const difference = moment(endVacationDate).diff(startVacationDate)
  const differenceInDays = moment.duration(difference).asDays() + 1
  let dateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC',
  })
  let textStyle = endVacationDate <= new Date() ? classes.disabled : classes.normal
  return (
    <TableRow style={classes.row}>
      <TableCell>
        <Text style={textStyle}>{dateTimeFormat.format(startVacationDate)}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle}>{dateTimeFormat.format(endVacationDate)}</Text>
      </TableCell>
      <TableCell>
        <Text style={textStyle}>{differenceInDays}</Text>
      </TableCell>
    </TableRow>
  )
}
