export const onlyNumbers = (value: string) => value && value.replace(/[^\d]/g, '')

export const masks = {
  telefone: rawValue => {
    if (!rawValue || rawValue.length < 11) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  rg: rawValue => {
    if (!rawValue || rawValue.length < 8) {
      return [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
    }
    return [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/]
  },
}
