import axios, { AxiosPromise } from 'axios'
import { ProfileDto, ProfileSelectsDto, SelectTeamDto } from 'backend'

export class Profile {
  loadSelects(): AxiosPromise<ProfileSelectsDto> {
    return axios.get('/api/profile/loadSelects')
  }

  loadProfile(): AxiosPromise<ProfileDto> {
    return axios.get('/api/profile/loadProfile')
  }

  loadProfileById(userId: number): AxiosPromise<ProfileDto> {
    return axios.get('/api/profile/loadProfileById', { params: { id: userId } })
  }

  loadProfileAndAdmData(userId: number): AxiosPromise<ProfileDto> {
    return axios.get('/api/profile/loadProfileAndAdmData', { params: { id: userId } })
  }

  save(form: ProfileDto): AxiosPromise<ProfileDto> {
    return axios.post('/api/profile', form)
  }

  update(form: ProfileDto): AxiosPromise<ProfileDto> {
    return axios.put('/api/profile/' + form.id, form)
  }

  loadTeams(): AxiosPromise<SelectTeamDto[]> {
    return axios.get('/api/profile/loadTeams')
  }
}
