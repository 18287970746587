import { Styles, Text, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import clock from 'images/clock.png'
import moment from 'moment'
import React from 'react'
import { asPrettyTime } from 'utils/TimeUtils'
import { getBalanceStyle } from 'view/dailyregister/table/visualize/DailyRegisterRowVisualize'

export interface TimeBalanceProps extends WithStylesProps {
  timeBalance: string
}

@withStyles
class TimeBalance extends React.Component<TimeBalanceProps> {
  render() {
    const { css, theme, timeBalance } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        height: '8.375rem',
        borderRadius: '0.25rem',
        width: '13rem',
        border: `1px solid ${theme.pallete.divider}`,
        color: theme.pallete.text.secondary,
      },
      header: {
        padding: '1rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
        color: theme.pallete.text.main,
      },
      content: {
        paddingLeft: '1rem',
        paddingTop: '1.5rem',
      },
      image: {
        backgroundImage: `url('${clock}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        height: '8.375rem',
      },
      text: {
        fontWeight: 'normal',
      },
    }

    const balance = moment.duration(timeBalance)
    const balanceStyle = css(getBalanceStyle(balance, theme).text, styles.text)

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.image)}>
          <div className={css(styles.header)}>
            <Text fontWeight='bold'> Banco de horas </Text>
          </div>
          <VFlow style={styles.content}>
            <Text fontSize={1.5} style={balanceStyle}>
              {asPrettyTime(balance, true)}
            </Text>
          </VFlow>
        </div>
      </div>
    )
  }
}

export default TimeBalance
