import { HFlow, Styles, Text, withStyles, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'

export interface StartEndTextIntervalProps extends WithStylesProps {
  startTime: string
  endTime: string
}

// tslint:disable no-string-literal

@withStyles
class StartEndTextInterval extends React.Component<StartEndTextIntervalProps> {
  render() {
    const { startTime, endTime, theme } = this.props

    const styles: Styles = {
      active: {
        color: theme.pallete.gray.c50,
      },
      disable: {
        color: theme.pallete.gray.c80,
      },
    }

    return (
      <HFlow justifyContent='center'>
        {startTime ? (
          <Text style={styles.active}> {this.getFotmatedTime(startTime)} </Text>
        ) : (
          <Text style={styles.disable}> {'00:00'} </Text>
        )}
        {endTime ? (
          <Text style={styles.active}> {this.getFotmatedTime(endTime)} </Text>
        ) : (
          <Text style={styles.disable}> {'00:00'} </Text>
        )}
      </HFlow>
    )
  }

  getFotmatedTime(time: string) {
    return moment(time, 'HH:mm:ss').format('HH:mm')
  }
}

export default StartEndTextInterval
