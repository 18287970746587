import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from 'store/modules'

export const middleware = [
    thunkMiddleware,
]

const composeEnhancers = process.env.NODE_ENV === 'development' ?
    ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose

export const configureStore = () => {
    return createStore(rootReducer, composeEnhancers(
        applyMiddleware(...middleware)
    ))
}

export default configureStore()
