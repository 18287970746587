import { ScheduleDto } from 'backend'
import { Form, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import ScheduleTableEdit from 'view/schedule/table/edit/ScheduleTableEdit'
import ScheduleTableEditHeader from 'view/schedule/table/edit/ScheduleTableEditHeader'
import { createTableStyles } from 'view/schedule/table/tableStyles'

interface ScheduleTableEditViewProps extends WithStylesProps {
  schedule: ScheduleDto
  load(): any
  update?(form: ScheduleDto): Promise<any>
  onSubmitSuccess(): void
}

@withStyles
class ScheduleTableEditView extends React.Component<ScheduleTableEditViewProps> {
  componentWillMount() {
    this.props.load()
  }

  render() {
    const { schedule, update, onSubmitSuccess } = this.props
    return (
      <Form
        onSubmit={update}
        render={this.renderForm}
        initialValues={schedule}
        onSubmitSucceeded={onSubmitSuccess}
        subscription={{ values: true, submitErrors: true }}
      />
    )
  }

  renderForm = (formProps: FormRenderProps) => {
    const { css, theme } = this.props

    const styles = createTableStyles(theme)
    const values = formProps.values as ScheduleDto

    return (
      <div className={css(styles.tableContainer)}>
        <ScheduleTableEditHeader
          intervals={values && values.weekdayIntervals}
          handleSubmit={formProps.handleSubmit}
          pristine={formProps.pristine}
        />
        <ScheduleTableEdit schedule={values} />
      </div>
    )
  }
}

export default ScheduleTableEditView
