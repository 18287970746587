import { CitizenStatusEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { civilStatus } from 'types/enums'

export interface CivilStatusSelectFieldProps {
  label: string
  name: string
  required: boolean
}

export const CivilStatusSelectField = (props: CivilStatusSelectFieldProps) => {
  const { ...rest } = props

  const itemToString = (item: CitizenStatusEnum) => {
    return civilStatus[item]
  }

  const items = Object.keys(civilStatus) as CitizenStatusEnum[]
  return <SelectField<CitizenStatusEnum> items={items} itemToString={itemToString} {...rest} />
}
