import { CollaboratorVacationDto } from 'backend'
import React from 'react'
import { CollaboratorVacationTable } from 'view/collaborator/vacation/table/CollaboratorVacationTable'

export interface CollaboratorVacationRowItemProps {
  data: CollaboratorVacationDto
}

export function CollaboratorVacationRowItem(props: CollaboratorVacationRowItemProps) {
  return <CollaboratorVacationTable data={props.data} />
}

export default CollaboratorVacationRowItem
