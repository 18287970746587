import api from 'api'
import { ProfileDto } from 'backend'
import { Theme, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { useEffect } from 'react'
import { useRequester } from 'requester/useRequester'

import CollaboratorDataHeader from '../collaborator/dailyregister/CollaboratorDataHeader'

import ProfileContent from './ProfileContent'

export default function ProfileVisualizeView() {
  const { request: requestProfile, result: profileDto } = useRequester<ProfileDto>(api.profile.loadProfile)
  const { classes } = useStyles(createStyles)

  useEffect(() => {
    requestProfile()
  }, [])

  if (!profileDto) {
    return null
  }

  return (
    <div className={classes.container}>
      <VFlow vSpacing={2}>
        <CollaboratorDataHeader profileDto={profileDto} url={'/profile/edit'} showEditButton={true} />
        <ProfileContent profileDto={profileDto} />
      </VFlow>
    </div>
  )
}

export const createStyles = (theme: Theme) => ({
  container: {
    padding: '2rem 0rem',
  } as React.CSSProperties,

  photo: {
    borderRadius: '50%',
    width: '8rem',
    hight: '8rem',
  } as React.CSSProperties,

  card: {
    border: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
  } as React.CSSProperties,

  label: {
    paddingRight: '8px',
  } as React.CSSProperties,

  telephonePanelDivider: {
    borderLeft: `1px solid ${theme.pallete.divider}`,
    paddingLeft: '1rem',
  } as React.CSSProperties,
})
