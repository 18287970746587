import { ProfileDto } from 'backend'
import { Cell, Grid, Heading, HFlow, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import React from 'react'
import { jobBonds } from 'types/enums'

export interface CollaboratorDataHeaderProps {
  profileDto: ProfileDto
  url: string
  showEditButton: boolean
}

export const CollaboratorDataHeader = (props: CollaboratorDataHeaderProps) => {
  const { classes } = useStyles(createStyles)
  const { profileDto, url, showEditButton } = props

  if (!profileDto) {
    return null
  }

  const jobMail = profileDto.googleEmail.split('@', 2)

  return (
    <>
      <Grid alignItems={'center'}>
        <Cell>
          {profileDto.pictureLink ? (
            <img src={profileDto.pictureLink} className={classes.photo} alt={'Foto do colaborador'} />
          ) : (
            <Icon icon='userCard' />
          )}
        </Cell>
        <Cell flexGrow={1}>
          <HFlow alignItems='center'>
            <Heading level={2}>{profileDto.name}</Heading>
            {showEditButton && (
              <ButtonLink skin='ghost' size='small' to={url}>
                <Icon icon='penFilled' />
              </ButtonLink>
            )}
          </HFlow>

          <VFlow vSpacing={0.5}>
            <HFlow hSpacing={0.25}>
              <Text>
                {jobMail && jobMail[0]}@{jobMail && jobMail[1]}
              </Text>
              <Text>|</Text>
              <Text>{profileDto.jobBond ? jobBonds[profileDto.jobBond] : ''}</Text>
            </HFlow>

            {profileDto.job && profileDto.team && (
              <HFlow hSpacing={0.5}>
                <Tag type='info' style={classes.tag}>
                  {profileDto.job && profileDto.job.name}
                </Tag>
                <Tag type='info' style={classes.tag}>
                  {profileDto.team && profileDto.team.name}
                </Tag>
              </HFlow>
            )}
          </VFlow>
        </Cell>
      </Grid>
    </>
  )
}

const createStyles = () => ({
  photo: {
    borderRadius: '50%',
    width: '8rem',
    hight: '8rem',
  } as React.CSSProperties,
  tag: {
    background: '#0099ff',
  } as React.CSSProperties,
})

export default CollaboratorDataHeader
