import axios from 'axios'
import { Form } from 'bold-ui'
import { alert } from 'components/alert'
import { FORM_ERROR } from 'final-form'
import moment from 'moment'
import 'moment/locale/pt-br'
import qs from 'qs'

export * from './history'

export default () => {
  moment.locale('pt-BR')

  axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, { skipNulls: true, allowDots: true, encode: false, arrayFormat: 'repeat' })
  }

  Form.defaultProps.onSubmitFailed = errors => {
    // tslint:disable-next-line:no-console
    alert('danger', 'Existem campos preenchidos de forma incorreta.')
  }

  Form.defaultProps.transformResult = result => {
    if (!result) {
      return result
    }

    if (isPromise(result)) {
      return result
        .then(res => Promise.resolve())
        .catch(error => {
          if (error && error.response && error.response.status === 400) {
            if (error.response.data.errors) {
              return Promise.resolve(error.response.data.errors)
            }
            return Promise.resolve(error.response.data)
          } else {
            return Promise.resolve({ [FORM_ERROR]: error && error.reponse && error.response.data })
          }
        })
    }
  }
}

const isPromise = (arg: any): arg is Promise<any> => {
  return arg.catch !== undefined
}
