import { CollaboratorFilter } from 'backend'
import { connect } from 'react-redux'
import { PageParams } from 'requester/index'
import CollaboratorRequester from 'store/requesters/CollaboratorRequester'

import CollaboratorScheduleView from './CollaboratorScheduleView'

export const mapState = (state) => {
    const result = CollaboratorRequester.listSchedule.getResult(state)
    return {
        collaboratorsSchedules: result && result.content,
    }
}

export const mapDispatch = (dispatch) => ({
    load: (filter: CollaboratorFilter & PageParams) => {
        dispatch(CollaboratorRequester.listSchedule.setParams(filter))
        dispatch(CollaboratorRequester.listSchedule.request())
    },
    clear: () => {
        dispatch(CollaboratorRequester.listSchedule.clearResult())
    },
})

export default connect(mapState, mapDispatch)(CollaboratorScheduleView)
