import { AbsenceDto } from 'backend'
import { Table, TableBody, TableHeader, TableRow, Text, Theme, useStyles, VFlow, WithStylesProps } from 'bold-ui'
import React, { CSSProperties } from 'react'

import VacationRowPastVisualize from './VacationRowPastVisualize'

interface VacationListViewProps extends WithStylesProps {
  title: string
  vacations: AbsenceDto[]
}

export function VacationListPastView(props: VacationListViewProps) {
  const { classes } = useStyles(styles)
  if (!props.vacations) {
    return null
  }

  const { title, vacations } = props

  return (
    <div>
      <VFlow vSpacing={0.5}>
        <Text style={classes.title}> {title} </Text>
        <Table>
          <TableBody>
            <TableRow style={classes.header}>
              <TableHeader>
                <Text>Início</Text>
              </TableHeader>
              <TableHeader>
                <Text>Fim</Text>
              </TableHeader>
              <TableHeader>
                <Text>Nº de dias</Text>
              </TableHeader>
              <TableHeader />
            </TableRow>
            <>
              {vacations.map((value, i) => (
                <VacationRowPastVisualize key={i} vacation={value} />
              ))}
            </>
          </TableBody>
        </Table>
      </VFlow>
    </div>
  )
}

export const styles = (theme: Theme) => ({
  header: {
    padding: '1rem',
  } as CSSProperties,
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  } as CSSProperties,
})

export default VacationListPastView
