import api from 'api'
import { AddressDto, ProfileDto, ProfileSelectsDto, UniversityEducationDto } from 'backend'
import { Button, Cell, Form, Grid, metaPath, useStyles } from 'bold-ui'
import { alert } from 'components/alert'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import createDecorator from 'final-form-calculate'
import React, { useEffect } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useRequester } from 'requester/useRequester'
import { Role } from 'utils/RoleUtils'
import { beforeEqualToday, createValidator, email, ErrorObject, minLength, required, telefone } from 'utils/validation'
import { AdministrationFieldGroup } from 'view/profile/edit/AdministrationFieldGroup'
import CheckRole from 'view/user/CheckRole'
import { AddressFieldGroup } from './edit/AddressFieldGroup'
import { BankDataFieldGroup } from './edit/BankDataFieldGroup'
import { CollaboratorInformationFieldGroup } from './edit/CollaboratorInformationFieldGroup'
import { CourseInformationFieldGroup } from './edit/CourseInformationFieldGroup'
import { SummaryInformationFieldGroup } from './edit/SummaryInformationFieldGroup'
import { TelephoneFieldGroup } from './edit/TelephoneFieldGroup'
import { profileStyles } from './profileStyles'

export interface ProfileEditViewProps {
  profileDto: ProfileDto
  goBackUrl: string
  onProfileUpdated?(): void
  onSubmit(value: ProfileDto): Promise<any>
  handleSuccess(): void
}

const meta = metaPath<ProfileDto>()

const calculateForm = createDecorator(
  {
    field: meta.universityEducation.studying.absolutePath(),
    updates: {
      [meta.universityEducation.academicRegistration.absolutePath()]: (
        _,
        allValues: ProfileDto,
        prevValues: ProfileDto
      ) => (prevValues.universityEducation ? undefined : allValues.universityEducation.academicRegistration),

      [meta.universityEducation.supposedDate.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.universityEducation ? undefined : allValues.universityEducation.supposedDate,

      [meta.universityEducation.shift.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.universityEducation ? undefined : allValues.universityEducation.shift,
    },
  },

  {
    field: meta.universityEducation.degree.absolutePath(),
    updates: {
      [meta.universityEducation.academicRegistration.absolutePath()]: (
        _,
        allValues: ProfileDto,
        prevValues: ProfileDto
      ) => (prevValues.universityEducation ? undefined : allValues.universityEducation.academicRegistration),

      [meta.universityEducation.supposedDate.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.universityEducation ? undefined : allValues.universityEducation.supposedDate,

      [meta.universityEducation.shift.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.universityEducation ? undefined : allValues.universityEducation.shift,
    },
  },
  {
    field: meta.accounts.get(0).bank.absolutePath(),
    updates: {
      [meta.accounts.get(0).agencyNumber.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.accounts ? undefined : allValues.accounts[0].agencyNumber,

      [meta.accounts.get(0).accountNumber.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.accounts ? undefined : allValues.accounts[0].accountNumber,
    },
  },
  {
    field: meta.accounts.get(1).bank.absolutePath(),
    updates: {
      [meta.accounts.get(1).agencyNumber.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.accounts ? undefined : allValues.accounts[1].agencyNumber,

      [meta.accounts.get(1).accountNumber.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.accounts ? undefined : allValues.accounts[1].accountNumber,
    },
  },
  {
    field: meta.address.postalCode.absolutePath(),
    updates: {
      [meta.address.street.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.address ? undefined : allValues.address.street,

      [meta.address.city.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.address ? undefined : allValues.address.city,

      [meta.address.hood.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.address ? undefined : allValues.address.hood,

      [meta.address.addOn.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.address ? undefined : allValues.address.addOn,

      [meta.address.number.absolutePath()]: (_, allValues: ProfileDto, prevValues: ProfileDto) =>
        prevValues.address ? undefined : allValues.address.number,
    },
  }
)

export function ProfileEditView(props: ProfileEditViewProps) {
  const { profileDto, goBackUrl, onSubmit } = props
  const { request: requestProfileSelect, result: profileSelectDto } = useRequester<ProfileSelectsDto>(
    api.profile.loadSelects
  )
  const { classes } = useStyles(profileStyles)
  const handleSuccess = () => {
    alert('success', 'Registro alterado com sucesso!')
    props.handleSuccess()
  }

  useEffect(() => {
    requestProfileSelect()
  }, [])

  if (!profileDto || !profileSelectDto) {
    return null
  }

  const renderForm = (formProps: FormRenderProps) => {
    const imgLink = profileDto && profileDto.pictureLink

    return (
      <div className={classes.container}>
        <Grid style={classes.header}>
          <Cell size={2}>
            {imgLink ? (
              <img src={imgLink} className={classes.photo} alt={'Foto do colaborador'} />
            ) : (
              <Icon icon='userCard' />
            )}
          </Cell>
          <Cell size={10}>
            <SummaryInformationFieldGroup profileSelectDto={profileSelectDto} />
          </Cell>
        </Grid>

        <Grid wrap>
          <Cell size={6}>
            <CollaboratorInformationFieldGroup citizenShipDto={profileSelectDto.citizenShipDto} />
          </Cell>
          <Cell size={6} style={classes.telephone}>
            <TelephoneFieldGroup />
          </Cell>
          <Cell size={6}>
            <CourseInformationFieldGroup profileDto={profileDto} courses={profileSelectDto.graduateCourses} />
          </Cell>
          <Cell size={6}>
            <BankDataFieldGroup formProps={formProps} bankDto={profileSelectDto.bankDto} profileDto={profileDto} />
          </Cell>
          <Cell size={12}>
            <AddressFieldGroup formProps={formProps} />
          </Cell>
          <CheckRole roles={[Role.ADMINISTRATOR]}>
            <Cell size={6}>
              <AdministrationFieldGroup />
            </Cell>
          </CheckRole>

          <Cell size={12} style={classes.footer}>
            <ButtonLink skin='outline' size='medium' kind='normal' style={{ marginRight: '1rem' }} to={goBackUrl}>
              Cancelar
            </ButtonLink>
            <Button onClick={formProps.handleSubmit} size='medium' kind='primary' style={{ width: '9rem' }}>
              Salvar
            </Button>
          </Cell>
        </Grid>
      </div>
    )
  }

  return (
    <Form
      initialValues={profileDto}
      validate={validate}
      onSubmit={onSubmit}
      render={renderForm}
      decorators={[calculateForm]}
      onSubmitSucceeded={handleSuccess}
    />
  )
}

const validateAdress = createValidator<AddressDto>({
  postalCode: [required, minLength(8)],
  city: [required],
  hood: [required],
  street: [required],
  number: [required],
})

const validateEducation = createValidator<UniversityEducationDto>(
  {
    degree: [required],
  },
  (allValues: UniversityEducationDto) => {
    const errors: ErrorObject<UniversityEducationDto> = {}

    if (allValues && allValues.studying) {
      errors.academicRegistration = required(allValues.academicRegistration)
      errors.shift = required(allValues.shift)
      errors.supposedDate = required(allValues.supposedDate)
    }
    if (allValues && allValues.degree && allValues.degree === 'GRADUATION') {
      errors.graduation = required(allValues.graduation)
    }
    if (allValues && allValues.degree && allValues.degree === 'MASTER') {
      errors.graduation = required(allValues.graduation)
      errors.master = required(allValues.master)
    }
    if (allValues && allValues.degree && allValues.degree === 'PHD') {
      errors.graduation = required(allValues.graduation)
      errors.master = required(allValues.master)
      errors.phd = required(allValues.phd)
    }

    return errors
  }
)

const validate = createValidator<ProfileDto>({
  name: [required],
  team: [required],
  role: [required],
  job: [required],
  dateOfBirth: [required, beforeEqualToday],
  citizenStatus: [required],
  personalEmail: [required, email],
  ssn: [required, minLength(11)],
  idRegisterNumber: [required],
  idRegisterIssuingEntity: [required],
  idRegisterEmissionDate: [required, beforeEqualToday],
  citizenShip: [required],
  room: [required],
  phoneLineExtension: [minLength(4)],
  phone: [required, telefone],
  secondaryPhone: [telefone],
  address: [validateAdress],
  universityEducation: [validateEducation],
  jobBond: [required],
})
