import axios from 'axios'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { actions } from 'store/modules/auth'

export interface AxiosErrorInterceptorProps extends RouteComponentProps {
    logout(): any
}

class AxiosErrorInterceptor extends React.Component<AxiosErrorInterceptorProps> {
    private responseInterceptor: number

    componentDidMount() {
        this.responseInterceptor = axios.interceptors.response.use(undefined, (error) => {
            if (error && error.response) {
                if (error.response.status === 403) {
                    this.props.history.push('/403')
                } else if (error.response.status === 401) {
                    this.props.logout()
                    this.props.history.push('/')
                }
            }
            return Promise.reject(error)
        })
    }

    componentWillUnmount() {
        axios.interceptors.response.eject(this.responseInterceptor)
    }

    render() {
        return null
    }
}

export const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(actions.logoutSuccess())
    },
})

export default withRouter(connect(null, mapDispatchToProps)(AxiosErrorInterceptor))
