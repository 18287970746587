import { UserDto } from 'backend'
import { Heading, HFlow, Modal, ModalBody, ModalFooter, Text, useStyles, VFlow } from 'bold-ui'
import React from 'react'
import { useSelector } from 'react-redux'
import { profileStyles } from 'view/profile/profileStyles'

import { ButtonLink } from '../../components/router/ButtonLink'
import { useRouter } from '../../hooks/useRouter'
import { RootState } from '../../store/modules'

export function CompleteRegisterModal() {
  const { location } = useRouter()
  const { classes } = useStyles(profileStyles)
  const user = useSelector<RootState, UserDto>(state => state.auth.user)

  if (!user.complete && location.pathname !== '/profile/edit') {
    return (
      <div>
        <Modal open={true} hasCloseIcon={false} style={classes.welcomeModal}>
          <VFlow vSpacing={1.5}>
            <ModalBody>
              <VFlow vSpacing={2.5}>
                <Heading level={1}> Bem-vindo, {user.name}!</Heading>
                <Text>
                  Para começar a usar o Meu Bridge, o primeiro passo é inserir as informações do seu perfil.
                  <br /> Depois, é só registrar o horário semanal e explorar as funcionalidades, ok?
                </Text>
              </VFlow>
            </ModalBody>
            <ModalFooter>
              <HFlow justifyContent='flex-end'>
                <ButtonLink to={'/profile/edit'} kind={'primary'}>
                  Editar Perfil
                </ButtonLink>
              </HFlow>
            </ModalFooter>
          </VFlow>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}
