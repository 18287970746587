import { AbsenceDto } from 'backend'
import { Styles, TableCell, TableRow, Text, Tooltip, useTheme, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import { inclusiveDiff } from 'utils/TimeUtils'
import { Icon } from '../../../components/Icon'
import { ButtonLink } from '../../../components/router/ButtonLink'

interface VacationRowVisualizeProps extends WithStylesProps {
  vacation: AbsenceDto
}

export function VacationRowPastVisualize(props: VacationRowVisualizeProps) {
  const { vacation } = props
  const theme = useTheme()

  const styles: Styles = {
    container: {
      backgroundColor: theme.pallete.surface.main,
      padding: '0rem 1rem',
      height: '3rem',
      border: `solid 1px ${theme.pallete.divider}`,
      borderRadius: '0.25rem',
    },
    tableCell: {
      width: '160px',
    },
    tableCellEnd: {
      textAlign: 'right',
    },
  }
  let balance = 0
  if (vacation.endDate && vacation.startDate) {
    balance = inclusiveDiff(vacation.endDate, vacation.startDate)
  }
  return (
    <TableRow style={styles.container}>
      <TableCell style={styles.tableCell}>
        <Text>{moment(vacation.startDate).format(DATE_PT_BR)}</Text>
      </TableCell>
      <TableCell style={styles.tableCell}>
        <Text>{moment(vacation.endDate).format(DATE_PT_BR)}</Text>
      </TableCell>
      <TableCell style={styles.tableCell}>
        <Text>{balance} dias</Text>
      </TableCell>
      <TableCell style={styles.tableCellEnd}>
        <Tooltip text='Editar'>
          <ButtonLink skin='ghost' to={`/vacation/${vacation.id}/edit`} size='small'>
            <Icon icon='penFilled' />
          </ButtonLink>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default VacationRowPastVisualize
