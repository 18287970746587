import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

interface RequesterState<T, P> {
  result: T
  params: P
  isLoading: boolean
  error: any
}

export function useRequester<T, P = {}>(fn: (params: P) => Promise<AxiosResponse<T>>) {
  const [requestScheduled, setRequestSchedule] = useState(false)
  const [state, setState] = useState<RequesterState<T, P>>({
    result: null,
    params: null,
    isLoading: false,
    error: null,
  })

  const setParams = (params: P) => setState(s => ({ ...s, params }))

  const request = () => setRequestSchedule(true)

  useEffect(() => {
    if (!requestScheduled) {
      return
    }

    setState(s => ({ ...s, isLoading: true }))

    fn(state.params)
      .then(res => {
        setState(s => ({ ...s, isLoading: false, result: res.data }))
        return res
      })
      .catch(err => {
        setState(s => ({ ...s, isLoading: false, error: err }))
        return err
      })
      .finally(() => {
        setRequestSchedule(false)
      })
  }, [requestScheduled])

  return { ...state, request, setParams }
}
