import { CollaboratorDto, ScheduleIntervalDto } from 'backend'
import { Flow, Styles, Text, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { CollaboratorIdentifier } from 'view/collaborator/CollaboratorIdentifier'
import TotalScheduleAmount from 'view/schedule/table/TotalScheduleAmount'

export interface CollaboratorScheduleRowItemHeaderProps extends WithStylesProps {
  collaborator: CollaboratorDto
  rightInfo: { [index: string]: ScheduleIntervalDto[] }
}

@withStyles
class CollaboratorScheduleRowItemHeader extends React.Component<CollaboratorScheduleRowItemHeaderProps> {
  render() {
    const { theme } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        color: theme.pallete.text.main,
        borderBottom: `1px solid ${theme.pallete.divider}`,
        height: '5rem',
        padding: '0rem 1.5rem',
      },
    }

    return (
      <Flow style={styles.container} alignItems='center' justifyContent='space-between'>
        <CollaboratorIdentifier collaboratorDto={this.props.collaborator} />
        <VFlow vSpacing={0} alignItems='flex-end'>
          <Text>Total semanal</Text>
          <TotalScheduleAmount intervals={this.props.rightInfo} />
        </VFlow>
      </Flow>
    )
  }
}

export default CollaboratorScheduleRowItemHeader
