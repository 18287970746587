import { CourseDto, ProfileDto } from 'backend'
import { Cell, CheckboxField, FieldRenderProps, Grid, Heading, TextField, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { useState } from 'react'
import { Field } from 'react-final-form'
import { StudyShiftSelectField } from 'view/profile/edit/item/StudyShiftSelectField'
import { SupposedDateSelectField } from 'view/profile/edit/item/SupposedDateSelectField'
import { profileStyles } from 'view/profile/profileStyles'
import { CourseSelectField } from './item/CourseSelectField'
import { DegreeSelectField } from './item/DegreeSelectField'

interface CourseInformationFieldGroupProps {
  profileDto: ProfileDto
  courses: CourseDto[]
}

export function CourseInformationFieldGroup(props: CourseInformationFieldGroupProps) {
  const { profileDto, courses } = props
  const { classes } = useStyles(profileStyles)
  const [isStudying, setStudying] = useState(profileDto.universityEducation && profileDto.universityEducation.studying)
  const [degree, setDegree] = useState()

  const handleCheckboxChange = (e: React.MouseEvent<HTMLInputElement>) => {
    setStudying(e ? e.currentTarget.checked : false)
  }

  const renderField = (fieldProps: FieldRenderProps) => {
    const values = fieldProps.input.value
    const fieldName = fieldProps.input.name
    const value = values.degree ? values.degree : ''
    setDegree(value)

    return (
      <VFlow>
        <Heading style={classes.title} level={2}>
          Informações acadêmicas
        </Heading>
        <div className={classes.component}>
          <Grid wrap>
            <Cell size={6}>
              <DegreeSelectField label='Grau acadêmico' name={`${fieldName}.degree`} />
            </Cell>
            <Cell alignSelf={'center'} size={6}>
              <CheckboxField
                name={`${fieldName}.studying`}
                label={'Cursando'}
                onClick={handleCheckboxChange}
                style={classes.checkbox}
              />
            </Cell>
            <Cell size={6}>
              {isStudying && (
                <TextField label='Matrícula' name={`${fieldName}.academicRegistration`} required maxLength={20} />
              )}
            </Cell>
            <Cell size={6}>
              {isStudying && (
                <SupposedDateSelectField
                  label='Previsão de conclusão'
                  name={`${fieldName}.supposedDate`}
                  required={true}
                />
              )}
            </Cell>
            <Cell size={6}>
              {isStudying && <StudyShiftSelectField label='Turno' name={`${fieldName}.shift`} required={true} />}
            </Cell>
            <Cell size={12}>
              {degree && (
                <CourseSelectField
                  label='Curso (Graduação)'
                  name={`${fieldName}.graduation`}
                  graduateCourses={courses}
                />
              )}
            </Cell>
            <Cell size={12}>
              {degree && (degree === 'MASTER' || degree === 'PHD') && (
                <TextField label={'Curso (Mestrado)'} name={fieldName + '.master'} required maxLength={100} />
              )}
            </Cell>
            <Cell size={12}>
              {degree && degree === 'PHD' && (
                <TextField label={'Curso (Doutorado)'} name={`${fieldName}.phd`} required maxLength={100} />
              )}
            </Cell>
          </Grid>
        </div>
      </VFlow>
    )
  }
  return <Field name={'universityEducation'} render={renderField} hasWrapper={false} />
}
