import { BaseFilter, ScheduleDto } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import scheduleRequester from 'store/requesters/ScheduleRequester'
import { DATE_ISO } from 'utils/DateUtils'
import ScheduleTableEditView from 'view/schedule/table/edit/ScheduleTableEditView'

export type OwnProps = RouteComponentProps<any>

export const mapStateToProps = (state: any) => ({
    schedule: scheduleRequester.list.getResult(state)
        ? scheduleRequester.list.getResult(state)[0] : null,
})

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
    load: () => {
        const filter = {
            fromDate: moment().format(DATE_ISO),
            toDate: moment().format(DATE_ISO),
        } as BaseFilter
        dispatch(scheduleRequester.list.setParams(filter))
        dispatch(scheduleRequester.list.request())
    },
    update: (dto: ScheduleDto) => {
        dispatch(scheduleRequester.edit.setParams(dto))
        return dispatch(scheduleRequester.edit.request())
    },
    onSubmitSuccess() {
        ownProps.history.push(`/schedule/current`)
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableEditView)
