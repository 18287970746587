import { ScheduleIntervalDto } from 'backend'
import { Omit, Text, TextProps, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { asPrettyTime } from 'utils/TimeUtils'
import { calculateTotalScheduleAmount } from 'view/schedule/ScheduleDateUtils'

export interface TotalScheduleAmountProps extends Omit<TextProps, 'children'>, WithStylesProps {
  intervals: { [index: string]: ScheduleIntervalDto[] }
}

@withStyles
class TotalScheduleAmount extends React.Component<TotalScheduleAmountProps> {
  render() {
    const { intervals } = this.props

    const amount = intervals && calculateTotalScheduleAmount(intervals)
    return (
      <Text {...this.props} color='primary' fontWeight='bold' fontSize={1}>
        {asPrettyTime(amount)}
      </Text>
    )
  }
}

export default TotalScheduleAmount
