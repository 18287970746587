import axios, { AxiosPromise } from 'axios'
import { TimeBalanceDto } from 'backend'

export class TimeBalance {
  fetch(userId: number): AxiosPromise<TimeBalanceDto> {
    return axios.get('/api/timeBalance/fetch', { params: { id: userId } })
  }

  fetchCurrent(): AxiosPromise<TimeBalanceDto> {
    return axios.get('/api/timeBalance/current')
  }
}
