import { ProfileDto, SelectBankDto } from 'backend'
import { Button, Cell, Grid, Heading, NumberField, TextField, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { profileStyles } from 'view/profile/profileStyles'
import { BankSelectField } from './item/BankSelectField'

interface BankDataFieldGroupProps {
  formProps: FormRenderProps
  bankDto: SelectBankDto[]
  profileDto: ProfileDto
}

export function BankDataFieldGroup(props: BankDataFieldGroupProps) {
  const { classes } = useStyles(profileStyles)
  const { formProps, bankDto, profileDto } = props

  const [isVisible, setVisible] = useState(profileDto && profileDto.accounts.length === 2)
  const handleClick = () => setVisible(true)
  const values: ProfileDto = profileDto
  const handleDisable = () => {
    setVisible(false)

    if (values) {
      formProps.form.change('accounts', [values.accounts[0]])
    }
  }
  const handleChangeAccounts = () => {
    if (values) {
      values.accounts = formProps.form.getState().values.accounts
      values.accounts.reverse()
      values.accounts[0].primary = true
      values.accounts[1].primary = false
      formProps.form.change('accounts', [values.accounts[0], values.accounts[1]])
    }
  }

  return (
    <VFlow>
      <Heading style={classes.title} level={2}>
        Dados bancários
      </Heading>
      <Heading style={classes.title} level={3}>
        Conta principal
      </Heading>
      <div className={classes.component}>
        <Grid wrap>
          <Cell size={12}>
            <BankSelectField label='Banco' name={`accounts[0].bank`} bankDto={bankDto} required={true} />
          </Cell>
          <Cell size={6}>
            <NumberField label='Agência' name={`accounts[0].agencyNumber`} required maxLength={10} />
          </Cell>
          <Cell size={6}>
            <TextField
              label='Conta corrente com dígito'
              name={`accounts[0].accountNumber`}
              required
              maxLength={15}
              placeholder='Ex.: 1203-6'
            />
          </Cell>
        </Grid>
      </div>

      {isVisible && (
        <VFlow>
          <Heading style={classes.title} level={3}>
            Conta secundária
          </Heading>

          <div className={classes.component}>
            <Grid wrap>
              <Cell size={12}>
                <BankSelectField label='Banco' name={`accounts[1].bank`} bankDto={bankDto} required={false} />
              </Cell>
              <Cell size={6}>
                <NumberField label='Agência' name={`accounts[1].agencyNumber`} maxLength={10} />
              </Cell>
              <Cell size={6}>
                <TextField
                  label='Conta corrente com dígito'
                  name={`accounts[1].accountNumber`}
                  maxLength={15}
                  placeholder='Ex.: 1203-6'
                />
              </Cell>
              <Cell size={6}>
                <Button skin='outline' size='small' kind='danger' onClick={handleDisable}>
                  Remover conta
                </Button>
              </Cell>
              <Cell size={6}>
                <Button skin='outline' size='small' kind='primary' onClick={handleChangeAccounts}>
                  Tornar conta principal
                </Button>
              </Cell>
            </Grid>
          </div>
        </VFlow>
      )}

      {!isVisible && (
        <Button skin='outline' size='small' kind='primary' disabled={isVisible} onClick={handleClick}>
          Adicionar conta secundária
        </Button>
      )}
    </VFlow>
  )
}
