import { ProfileDto } from 'backend'
import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import * as React from 'react'

interface AddressPanelProps {
  profileDto: ProfileDto
}

export default function AddressPanel(props: AddressPanelProps) {
  const { profileDto } = props

  const address = profileDto && profileDto.address
  const part1 = address && address.postalCode.slice(0, 5)
  const part2 = address && address.postalCode.slice(5, 8)

  return (
    <Grid wrap>
      <Cell xs={4}>
        <InfoLabel title='CEP' placeholder='-'>
          <Text>{address ? `${part1}-${part2}` : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={4}>
        <InfoLabel title='Município' placeholder='-'>
          <Text>{address ? address.city : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={4}>
        <InfoLabel title='Bairro' placeholder='-'>
          <Text>{address ? address.hood : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={4}>
        <InfoLabel title='Logradouro' placeholder='-'>
          <Text>{address ? address.street : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={4}>
        <InfoLabel title='Nº' placeholder='-'>
          <Text>{address ? address.number : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={4}>
        <InfoLabel title='Complemento' placeholder='-'>
          <Text>{address ? address.addOn : '-'}</Text>
        </InfoLabel>
      </Cell>
    </Grid>
  )
}
