import { useEffect } from 'react'
import RollbarBar from 'rollbar'

export function Rollbar() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      new RollbarBar({
        accessToken: '2e36f686ff0141c8ac0bd62a981af633',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: 'prod',
        },
      })
    }
  }, [])

  return null
}
