import { ProfileDto, UserDto } from 'backend'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { ProfileEditView } from './ProfileEditView'
import ProfileVisualizeView from './ProfileVisualizeView'
import { Role } from 'utils/RoleUtils'
import { RootState } from 'store/modules'
import { actions } from 'store/modules/auth'
import api from 'api'
import { useRequester } from 'requester/useRequester'
import { useRoles } from 'hooks/useRoles'
import { useRouter } from 'hooks/useRouter'

export interface ProfileRootViewProps {
  onProfileUpdated(): void
}

export function ProfileRootView(props: ProfileRootViewProps) {
  const { match, history, location } = useRouter()

  const dispatch = useDispatch()
  const roles = useRoles()
  const user = useSelector<RootState, UserDto>(state => state.auth.user)

  const getRequesterByRole = () => {
    if (roles.includes(Role.ADMINISTRATOR)) {
      return api.profile.loadProfileAndAdmData
    } else if (roles.includes(Role.LEADER)) {
      return api.profile.loadProfileById
    } else if (roles.includes(Role.COLLABORATOR)) {
      return api.profile.loadProfile
    }
  }
  const { request, result, setParams } = useRequester<ProfileDto>(getRequesterByRole())

  const onSubmit = async (value: ProfileDto) => {
    let result = {}
    value.accounts[0].primary = true
    if (roles.includes(Role.ADMINISTRATOR)) {
      result = await api.profile.update(value)
    } else {
      result = await api.profile.save(value)
    }

    await dispatch(actions.updateLogin())
    return result
  }

  const handleSuccess = () => {
    history.push('/profile/visualize')
  }

  useEffect(() => {
    if (location.pathname.endsWith('edit')) {
      setParams(user.collaboratorId)
      request()
    }
  }, [location.pathname])

  const renderProfileEditView = () => (
    <ProfileEditView
      onProfileUpdated={props.onProfileUpdated}
      onSubmit={onSubmit}
      handleSuccess={handleSuccess}
      profileDto={result}
      goBackUrl={'/profile/visualize'}
    />
  )
  return (
    <Container>
      <Switch>
        <Route exact path={`${match.url}/visualize`} component={ProfileVisualizeView} />
        <Route exact path={`${match.url}/edit`} render={renderProfileEditView} />
        <Redirect from={`${match.url}`} to={`${match.url}/visualize`} />
      </Switch>
    </Container>
  )
}
