import { DailyActivityRowItem, TimeBalanceDto } from 'backend'
import { HFlow, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import PeriodAmount from 'view/dailyregister/PeriodAmount'
import DailyRegisterTableContainer from 'view/dailyregister/table/DailyRegisterTableContainer'
import DailyRegisterTableReadOnlyContainer from 'view/dailyregister/table/visualize/DailyRegisterTableReadOnlyContainer'
import TodayRegisterContainer from 'view/dailyregister/TodayRegisterContainer'
import {
  calculatePercent,
  calculatePeriodAmount,
  calculateTotalDailyAmount,
} from 'view/dailyregister/utils/DailyRegisterUtils'

import TimeBalance from './TimeBalance'

export interface DailyRegisterViewProps extends RouteComponentProps<any>, WithStylesProps {
  timeBalance: TimeBalanceDto
  userId: number
  loadToday(): any
  loadWeek(): any
  loadBalance(userId: number): any
}

export interface DailyRegisterState {
  todayAmount: moment.Duration
  todayPercent: number
  weeklyAmount: moment.Duration
  weeklyExpected: moment.Duration
}

@withStyles
class DailyRegisterView extends React.Component<DailyRegisterViewProps, DailyRegisterState> {
  constructor(props) {
    super(props)
    this.state = {
      todayAmount: moment.duration(0),
      todayPercent: 0,
      weeklyAmount: moment.duration(0),
      weeklyExpected: moment.duration(0),
    }
  }

  componentDidMount() {
    this.props.loadBalance(this.props.userId)
  }

  render() {
    const { match, location, timeBalance } = this.props
    const editMode = location.pathname.includes(`${match.url}/edit`)
    return (
      <VFlow>
        <TodayRegisterContainer
          todayAmount={this.state.todayAmount}
          percent={this.state.todayPercent}
          onValueChange={this.onTodayRegisterChange}
          disabled={editMode}
          load={this.props.loadToday}
          onSubmitSucceeded={this.onTodaySubmitSucceeded}
        />
        <HFlow hSpacing={2}>
          <Switch>
            <Route exact path={`${match.url}/edit`} render={this.renderDailyRegisterTableContainer} />
            <Route exact path={`${match.url}/visualize`} render={this.renderDailyRegisterTableReadOnlyContainer} />
            <Redirect exact from={`${match.url}`} to={`${match.url}/visualize`} />
          </Switch>
          <VFlow vSpacing={1.5}>
            <PeriodAmount
              title='Total semanal'
              expectedPeriodAmount={this.state.weeklyExpected}
              periodAmount={this.state.weeklyAmount}
            />
            <TimeBalance timeBalance={timeBalance ? timeBalance.balance : '0'} />
          </VFlow>
        </HFlow>
      </VFlow>
    )
  }

  renderDailyRegisterTableContainer = () => {
    return (
      <DailyRegisterTableContainer
        onValueChange={this.onChange}
        load={this.props.loadWeek}
        onSubmitSucceeded={this.onWeekSubmitSucceeded}
      />
    )
  }

  renderDailyRegisterTableReadOnlyContainer = () => {
    return <DailyRegisterTableReadOnlyContainer onValueChange={this.onChange} />
  }

  onTodayRegisterChange = (value: DailyActivityRowItem) => {
    this.props.loadBalance(this.props.userId)

    let totalDailyAmount = this.state.todayAmount
    if (value && value.activityIntervals) {
      totalDailyAmount = calculateTotalDailyAmount(value.activityIntervals)
    }

    let percent = this.state.todayPercent
    if (value.expectedDailyTimeAmount && totalDailyAmount) {
      percent = calculatePercent(moment.duration(value.expectedDailyTimeAmount), totalDailyAmount)
    }

    this.setState({ todayAmount: totalDailyAmount, todayPercent: percent })
  }

  onChange = (values: DailyActivityRowItem[]) => {
    const { periodAmount, expectedPeriodAmount } = calculatePeriodAmount(values)
    this.setState({ weeklyAmount: periodAmount, weeklyExpected: expectedPeriodAmount })
  }

  onTodaySubmitSucceeded = () => {
    this.props.loadWeek()
  }

  onWeekSubmitSucceeded = () => {
    this.props.loadBalance(this.props.userId)
    this.props.loadToday()
  }
}

export default DailyRegisterView
