import { HFlow, TableHeader, Text } from 'bold-ui'
import React from 'react'

export interface StartEndTimeHeaderProps {}

class StartEndTimeHeader extends React.Component<StartEndTimeHeaderProps> {
  render() {
    return (
      <TableHeader>
        <HFlow hSpacing={0.5} justifyContent='center'>
          <Text>Entrada</Text>
          <Text>Saída</Text>
        </HFlow>
      </TableHeader>
    )
  }
}

export default StartEndTimeHeader
