import { SelectBankDto } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'

export interface BankSelectFieldProps {
  bankDto: SelectBankDto[]
  label: string
  name: string
  required: boolean
}

export const BankSelectField = (props: BankSelectFieldProps) => {
  const { bankDto, required, ...rest } = props

  const itemToString = (item: SelectBankDto) => {
    return item && `${item.name}`
  }

  return <SelectField<SelectBankDto> items={bankDto} itemToString={itemToString} {...rest} required={required} />
}
