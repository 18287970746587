import { Styles, Theme } from 'bold-ui'

export const createTableStyles = (theme: Theme): Styles => ({
  table: {
    backgroundColor: theme.pallete.surface.main,
    border: 'none',
    th: {
      textAlign: 'center',
    },
    td: {
      textAlign: 'center',
      width: '20%',
    },
  },
  tableBorderNone: {
    backgroundColor: theme.pallete.surface.main,
    border: 'none',
    th: {
      textAlign: 'center',
      border: 'none',
      paddingRight: '1rem',
    },
    td: {
      textAlign: 'center',
      border: 'none',
      width: '20%',
    },
  },
  tableHover: {},
  thead: {},
  tbody: {},
  row: {
    height: '3rem',
    border: 'none',
  },
  th: {},
  cell: { padding: '0rem' },
  pointer: {},
  tableContainer: {
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.25rem',
  },
  createTableContainer: {
    width: '51rem',
  },
})
