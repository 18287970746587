import { HFlow, Styles, Text, withStyles, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { asPrettyTime } from 'utils/TimeUtils'
import { getBalanceStyle } from 'view/dailyregister/table/visualize/DailyRegisterRowVisualize'

export interface WeeklyTableFooterProps extends WithStylesProps {
  balance: moment.Duration
  weeklyAmount: moment.Duration
  readonly?: boolean
}

@withStyles
class WeeklyTableFooter extends React.Component<WeeklyTableFooterProps> {
  render() {
    const { theme, balance, weeklyAmount, readonly } = this.props

    const styles: Styles = {
      footerContainer: {
        backgroundColor: theme.pallete.surface.background,
        height: '1.75rem',
      },
      footerTextContainer: {
        paddingRight: readonly ? '1rem' : '0.5rem',
      },
    }

    const balanceStyle = getBalanceStyle(balance, theme)
    return (
      <>
        <HFlow style={styles.footerContainer} alignItems='center' justifyContent='flex-end'>
          <HFlow style={styles.footerTextContainer} hSpacing={1.25}>
            <Text fontWeight={'bold'}> Total semanal</Text>
            <HFlow hSpacing={0.625}>
              <Text color='secondary'> {asPrettyTime(weeklyAmount)} </Text>
              <Text color='secondary' style={balanceStyle.text}>
                {asPrettyTime(balance, true)}
              </Text>
            </HFlow>
          </HFlow>
        </HFlow>
      </>
    )
  }
}

export default WeeklyTableFooter
