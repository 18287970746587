import axios, { AxiosPromise } from 'axios'
import { GraduationReportDto, TimeBalanceReportDto, VacationReportDto } from 'backend'

export class Report {
  fetchTimeBalance(): AxiosPromise<TimeBalanceReportDto[]> {
    return axios.get('/api/report/timebalance')
  }

  fetchVacation(): AxiosPromise<VacationReportDto[]> {
    return axios.get('/api/report/vacation')
  }

  fetchSupposedGraduation(): AxiosPromise<GraduationReportDto[]> {
    return axios.get('/api/report/graduation')
  }
}
