import { AlertType } from 'bold-ui'
import { RootState } from 'store/modules'

export const ADD = 'bridge/notification/ADD'
export const REMOVE = 'bridge/notification/REMOVE'
export const SET_TIMEOUT = 'bridge/notification/SET_TIMEOUT'

export interface NotificationItem {
  id: string
  type: AlertType
  message: React.ReactNode
  timeoutId?: number
  hasTimeout?: boolean
}

export interface NotificationState {
  readonly items: NotificationItem[]
}

export const notificationInicialState: NotificationState = {
  items: [],
}

export default function reducer(state = notificationInicialState, action): NotificationState {
  switch (action.type) {
    case ADD:
      return {
        ...state,
        items: [...state.items, action.payload],
      }
    case REMOVE:
      return {
        ...state,
        items: state.items.filter(elem => elem.id !== action.payload.id),
      }
    case SET_TIMEOUT:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id !== action.payload.id) {
            return item
          }
          return {
            ...item,
            timeoutId: action.payload.timeoutId,
          }
        }),
      }
    default:
      return state
  }
}

export const addNotification = (
  type: NotificationItem['type'],
  message: NotificationItem['message'],
  hasTimeout: NotificationItem['hasTimeout'] = false
) => ({
  type: ADD,
  payload: {
    id: Math.random()
      .toString(36)
      .substring(2),
    type,
    message,
    hasTimeout,
  },
})

export const removeNotification = (id: string) => ({
  type: REMOVE,
  payload: {
    id,
  },
})

export const setTimeoutId = (id: NotificationItem['id'], timeoutId: number) => ({
  type: SET_TIMEOUT,
  payload: {
    id,
    timeoutId,
  },
})

export const notify = (
  type: NotificationItem['type'],
  message: NotificationItem['message'],
  timeout = 5000
) => dispatch => {
  const n = addNotification(type, message, true)
  dispatch(n)
  const i = window.setTimeout(() => {
    dispatch(removeNotification(n.payload.id))
  }, timeout)
  dispatch(setTimeoutId(n.payload.id, i))
}

export const stopNotificationTimeout = (id: NotificationItem['id']) => (dispatch, getState: () => RootState) => {
  const root = getState()
  const items = root.notification.items.filter(elem => elem.id === id)
  items.forEach(item => {
    if (item.hasTimeout) {
      window.clearTimeout(item.timeoutId)
      dispatch(setTimeoutId(item.id, null))
    }
  })
}

export const restartNotificationTimeout = (id: NotificationItem['id']) => (dispatch, getState: () => RootState) => {
  const root = getState()
  const items = root.notification.items.filter(elem => elem.id === id)
  items.forEach(item => {
    if (item.hasTimeout) {
      const newId = window.setTimeout(() => dispatch(removeNotification(item.id)), 5000)
      dispatch(setTimeoutId(item.id, newId))
    }
  })
}
