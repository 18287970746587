import { ScheduleDto } from 'backend'
import { withStyles, WithStylesProps } from 'bold-ui'
import { isEqual } from 'lodash'
import React from 'react'
import ScheduleTableHistoryHeader from 'view/schedule/table/history/ScheduleTableHistoryHeader'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import ScheduleTableVisualize from 'view/schedule/table/visualize/ScheduleTableVisualize'

interface ScheduleHistoryVisualizeViewProps extends WithStylesProps {
  schedule?: ScheduleDto
}

interface ScheduleHistoryVisualizeViewState {
  expanded: boolean
}

@withStyles
class ScheduleHistoryVisualizeView extends React.Component<
  ScheduleHistoryVisualizeViewProps,
  ScheduleHistoryVisualizeViewState
> {
  constructor(props) {
    super(props)
    this.state = { expanded: false }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.schedule, nextProps.schedule) || !isEqual(this.state, nextState)
  }

  render() {
    const { schedule, css, theme } = this.props
    const styles = createTableStyles(theme)
    return (
      <div className={css(styles.tableContainer)}>
        <ScheduleTableHistoryHeader
          id={schedule && schedule.id}
          intervals={schedule && schedule.weekdayIntervals}
          startDate={schedule && schedule.startDate}
          endDate={schedule && schedule.endDate}
          onExpandClick={this.onExpandClick}
        />
        {this.state.expanded ? <ScheduleTableVisualize schedule={schedule} /> : null}
      </div>
    )
  }

  private onExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }
}

export default ScheduleHistoryVisualizeView
