import { Styles, Theme } from 'bold-ui'

export const createDailyTableStyles = (theme: Theme): Styles => ({
  container: {
    width: '43rem',
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.25rem',
    table: {
      border: 'none',
    },
  },
  tableHeader: {
    borderBottom: `solid 1px ${theme.pallete.divider}`,
    textAlign: 'right',
    padding: '0.5rem 1rem',
  },
  firstColumn: {
    width: '7rem',
    padding: '0.5rem 0rem 0.5rem 1rem',
    textAlign: 'center',
  },
  timeField: {
    width: '125px',
    padding: '0.5rem 0.25rem',
    textAlign: 'center',
  },
  buttons: {
    width: '5rem',
    padding: '0rem',
  },
  header: {
    height: '3rem',
  },
  balance: {
    padding: '0rem',
    width: '5.875rem',
  },
})

export const useDailyTableStyles = (
  theme: Theme
): {
  container: React.CSSProperties
  firstColumn: React.CSSProperties
  buttons: React.CSSProperties
  balance: React.CSSProperties
  timeField: React.CSSProperties
  header: React.CSSProperties
  tableHeader: React.CSSProperties
  secondaryText: React.CSSProperties
} => ({
  container: {
    width: '43rem',
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.25rem',
    table: {
      border: 'none',
    },
  } as React.CSSProperties,
  tableHeader: {
    borderBottom: `solid 1px ${theme.pallete.divider}`,
    textAlign: 'right',
    padding: '0.5rem 1rem',
  } as React.CSSProperties,
  firstColumn: {
    width: '7rem',
    padding: '0.5rem 0rem 0.5rem 1rem',
    textAlign: 'center',
  } as React.CSSProperties,
  timeField: {
    width: '125px',
    padding: '0.5rem 0.25rem',
    textAlign: 'center',
  } as React.CSSProperties,
  buttons: {
    width: '5rem',
    padding: '0rem',
  } as React.CSSProperties,
  header: {
    height: '3rem',
  } as React.CSSProperties,
  balance: {
    padding: '0rem',
    width: '5.875rem',
  } as React.CSSProperties,
  secondaryText: {
    color: theme.pallete.text.secondary,
  } as React.CSSProperties,
})
