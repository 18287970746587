import { Address } from 'api/Address'
import { Report } from 'api/Report'
import { Auth } from './Auth'
import { Collaborator } from './Collaborator'
import { DailyRegister } from './DailyRegister'
import { Profile } from './Profile'
import { Schedule } from './Schedule'
import { TimeBalance } from './TimeBalance'
import { Vacation } from './Vacation'

export default {
  auth: new Auth(),
  dailyRegister: new DailyRegister(),
  schedule: new Schedule(),
  vacation: new Vacation(),
  collaborator: new Collaborator(),
  timeBalance: new TimeBalance(),
  report: new Report(),
  profile: new Profile(),
  address: new Address(),
}
