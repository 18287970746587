import { CollaboratorFilter, CollaboratorVacationDto } from 'backend'
import { VFlow } from 'bold-ui'
import { EmptyContainer } from 'components/EmptyContainer'
import { isEqual } from 'lodash'
import React from 'react'
import { PageParams, Requester } from 'requester/index'
import CollaboratorVacationRowItem from 'view/collaborator/vacation/CollaboratorVacationRowItem'

export interface CollaboratorVacationViewProps {
  collaboratorsIds: number[]
  collaboratorsVacations: CollaboratorVacationDto[]
  requester: Requester<CollaboratorVacationDto[], CollaboratorFilter>

  load(ids: CollaboratorFilter): Promise<CollaboratorVacationDto[]>

  clear(): void
}

class CollaboratorVacationView extends React.Component<CollaboratorVacationViewProps> {
  componentDidMount() {
    this.loadData(this.props)
  }

  componentWillReceiveProps(nextProps: CollaboratorVacationViewProps) {
    if (!isEqual(this.props.collaboratorsIds, nextProps.collaboratorsIds)) {
      this.loadData(nextProps)
    }
  }

  render() {
    if (!this.props.collaboratorsVacations) {
      return <EmptyContainer message='Selecione colaboradores para ver as férias...' hasButton={false} />
    }

    return (
      <VFlow>
        {this.props.collaboratorsVacations.map((item, key) => (
          <CollaboratorVacationRowItem key={key} data={item} />
        ))}
      </VFlow>
    )
  }

  private loadData = (props: CollaboratorVacationViewProps) => {
    const ids: number[] = props.collaboratorsIds

    if (ids && ids.length > 0) {
      const filter = {
        ids,
        page: 0,
        // pagination is a future work, but the back-end is almost done
        size: 150,
      } as CollaboratorFilter & PageParams
      props.load(filter)
    } else {
      props.clear()
    }
  }
}

export default CollaboratorVacationView
