import { Theme } from 'bold-ui'
import React from 'react'

export const createCollaboratorVacationTableStyles = (
  theme: Theme
): {
  container: React.CSSProperties
  theadText: React.CSSProperties
  normal: React.CSSProperties
  pointer: React.CSSProperties
  tableHover: React.CSSProperties
  tbody: React.CSSProperties
  disabledTable: React.CSSProperties
  thead: React.CSSProperties
  cell: React.CSSProperties
  tableBorderNone: React.CSSProperties
  unabledBorder: React.CSSProperties
  createTableContainer: React.CSSProperties
  th: React.CSSProperties
  disabled: React.CSSProperties
  row: React.CSSProperties
  tableContainer: React.CSSProperties
  table: React.CSSProperties
} => ({
  table: {
    backgroundColor: theme.pallete.surface.main,
    border: `1px solid ${theme.pallete.divider}`,
    borderRadius: '2px',
    th: {
      textAlign: 'left',
    },
    td: {
      textAlign: 'left',
      width: '20%',
    },
  } as React.CSSProperties,
  container: {
    background: theme.pallete.surface.main,
    color: theme.pallete.text.main,
    height: '5rem',
    padding: '0rem 1.5rem',
  } as React.CSSProperties,
  tableBorderNone: {
    backgroundColor: theme.pallete.surface.main,
    border: 'none',
    textAlign: 'center',
    th: {
      textAlign: 'center',
      border: 'none',
      paddingRight: '1rem',
    },
    td: {
      textAlign: 'center',
      border: 'none',
      width: '20%',
    },
  } as React.CSSProperties,
  tableHover: {} as React.CSSProperties,
  thead: { fontWeight: 'bold' } as React.CSSProperties,
  tbody: {} as React.CSSProperties,
  theadText: { borderTop: `1px solid ${theme.pallete.divider}` } as React.CSSProperties,
  row: {
    height: '3rem',
    border: 'none',
  } as React.CSSProperties,
  th: {} as React.CSSProperties,
  cell: { padding: '0rem' } as React.CSSProperties,
  pointer: {} as React.CSSProperties,
  tableContainer: {
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.25rem',
  } as React.CSSProperties,
  createTableContainer: {
    width: '51rem',
  } as React.CSSProperties,
  disabled: {
    color: theme.pallete.text.disabled,
  } as React.CSSProperties,
  normal: {
    color: theme.pallete.text.main,
  } as React.CSSProperties,
  unabledBorder: {
    table: {
      border: 0,
    },
  } as React.CSSProperties,
  disabledTable: {
    background: theme.pallete.surface.main,
    borderTop: `1px solid ${theme.pallete.divider}`,
    height: '2.5rem',
    padding: '0rem 1.5rem',
  } as React.CSSProperties,
})
