import { TableRow, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import StartEndTimeHeader from 'view/schedule/table/StartEndTimeHeader'
import { createTableStyles } from 'view/schedule/table/tableStyles'

@withStyles
class StartEndTitleRow extends React.Component<WithStylesProps> {
  render() {
    return (
      <TableRow style={createTableStyles(this.props.theme).row}>
        <StartEndTimeHeader />
        <StartEndTimeHeader />
        <StartEndTimeHeader />
        <StartEndTimeHeader />
        <StartEndTimeHeader />
      </TableRow>
    )
  }
}

export default StartEndTitleRow
