import { ScheduleIntervalDto } from 'backend'
import { Styles, TableHeader, TableRow, Text, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { calculateTotalDailyAmount } from 'view/schedule/ScheduleDateUtils'
import { createTableStyles } from 'view/schedule/table/tableStyles'

export interface DailyAmoutRowProps extends WithStylesProps {
  intervals: { [index: string]: ScheduleIntervalDto[] }
  color?: string
}

// tslint:disable no-string-literal

@withStyles
class DailyAmoutRow extends React.Component<DailyAmoutRowProps> {
  render() {
    const { intervals, theme } = this.props

    const tableStyle = createTableStyles(theme)

    const styles: Styles = {
      color: {
        color: this.props.color,
      },
    }
    return (
      <TableRow style={tableStyle.row}>
        <TableHeader>
          <Text style={styles.color}>
            {intervals && intervals['MONDAY'] ? calculateTotalDailyAmount(intervals['MONDAY']) : '00:00'}
          </Text>
        </TableHeader>
        <TableHeader>
          <Text style={styles.color}>
            {intervals && intervals['TUESDAY'] ? calculateTotalDailyAmount(intervals['TUESDAY']) : '00:00'}
          </Text>
        </TableHeader>
        <TableHeader>
          <Text style={styles.color}>
            {intervals && intervals['WEDNESDAY'] ? calculateTotalDailyAmount(intervals['WEDNESDAY']) : '00:00'}
          </Text>
        </TableHeader>
        <TableHeader>
          <Text style={styles.color}>
            {intervals && intervals['THURSDAY'] ? calculateTotalDailyAmount(intervals['THURSDAY']) : '00:00'}
          </Text>
        </TableHeader>
        <TableHeader>
          <Text style={styles.color}>
            {intervals && intervals['FRIDAY'] ? calculateTotalDailyAmount(intervals['FRIDAY']) : '00:00'}
          </Text>
        </TableHeader>
      </TableRow>
    )
  }
}

export default DailyAmoutRow
