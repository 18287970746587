import { Flow, Styles, Text, withStyles, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'

export interface CollaboratorScheduleRowItemFooterProps extends WithStylesProps {
  endDate: string
}

@withStyles
class CollaboratorScheduleRowItemFooter extends React.Component<CollaboratorScheduleRowItemFooterProps> {
  render() {
    const endDate = moment(this.props.endDate)
    const { theme } = this.props

    if (!this.props.endDate || moment().isBefore(endDate)) {
      return null
    }
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        color: theme.pallete.text.secondary,
        borderBottom: `1px solid ${theme.pallete.divider}`,
        height: '3.5rem',
        padding: '0rem 1.5rem',
      },
    }

    const prettyDate = endDate.format(DATE_PT_BR)

    return (
      <Flow style={styles.container} alignItems='center'>
        <Text>Horário desatualizado ({prettyDate})</Text>
      </Flow>
    )
  }
}

export default CollaboratorScheduleRowItemFooter
