import { DailyActivityRowItem } from 'backend'
import {
  Button,
  Form,
  HFlow,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  withStyles,
  WithStylesProps,
} from 'bold-ui'
import { Icon } from 'components/Icon'
import OnBlur from 'components/OnBlur'
import { ButtonLink } from 'components/router/ButtonLink'
import { isEmpty, isEqual } from 'lodash'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import DailyRegisterRow from 'view/dailyregister/table/DailyRegisterRow'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import StartEndTimeHeader from 'view/schedule/table/StartEndTimeHeader'

interface DailyRegisterTableProps extends WithStylesProps {
  dailyRegisters?: DailyActivityRowItem[]

  load?(): any

  save?(forms: DailyActivityRowItem[]): any

  onValueChange(forms: DailyActivityRowItem[]): void

  onSubmitSuccess?(): void
}

@withStyles
class DailyRegisterTable extends React.Component<DailyRegisterTableProps> {
  componentWillMount() {
    this.props.load()
  }

  componentDidMount() {
    if (this.props.dailyRegisters) {
      this.props.onValueChange(this.props.dailyRegisters)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dailyRegisters && !isEqual(this.props.dailyRegisters, prevProps.dailyRegisters)) {
      this.props.onValueChange(this.props.dailyRegisters)
    }
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitSucceeded={this.props.onSubmitSuccess}
        render={this.renderForm}
        initialValues={{ dailyRegisters: this.props.dailyRegisters }}
        subscription={{ values: true, active: true }}
      />
    )
  }

  renderForm = (formProps: FormRenderProps) => {
    if (isEmpty(formProps.values.dailyRegisters)) {
      return null
    }

    const { css, theme } = this.props
    const values = formProps.values.dailyRegisters
    const style = createDailyTableStyles(theme)

    return (
      <div className={css(style.container)}>
        <OnBlur callback={this.onBlur} />
        <div className={css(style.tableHeader)}>
          <Tooltip text='Cancelar'>
            <ButtonLink to='/dailyregister' skin='ghost' size='small'>
              <Icon icon='timesDefault' />
            </ButtonLink>
          </Tooltip>
          <Tooltip text='Salvar'>
            <Button
              skin='ghost'
              kind='primary'
              onClick={formProps.handleSubmit}
              disabled={formProps.pristine}
              size='small'
            >
              <Icon icon='checkDefault' />
            </Button>
          </Tooltip>
        </div>
        <Table>
          <TableHead>
            <HeaderTitleRow style={css(style.header)} />
          </TableHead>
          <TableBody>
            {values.map((value, i) => (
              <DailyRegisterRow key={i} index={i} dailyRegister={value} formProps={formProps} />
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }

  onBlur = values => {
    this.props.onValueChange(values.dailyRegisters)
  }

  handleSubmit = values => {
    return this.props.save(values.dailyRegisters)
  }
}

const HeaderTitleRow = ({ style }) => {
  return (
    <TableRow className={style}>
      <TableHeader>
        <HFlow alignItems='flex-start'>
          <Text> Dia</Text>
        </HFlow>
      </TableHeader>
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <TableHeader>
        <Text> {''}</Text>
      </TableHeader>
      <TableHeader>
        <HFlow hSpacing={0.5}>
          <Text> Total</Text>
          <Text> Saldo</Text>
        </HFlow>
      </TableHeader>
    </TableRow>
  )
}

export default DailyRegisterTable
