import { AbsenceFilter } from 'backend'
import { connect } from 'react-redux'
import VacationRequester from 'store/requesters/VacationRequester'
import { todayISO, tomorrowISO } from 'utils/DateUtils'

import VacationView from './VacationView'

export const mapStateToProps = state => ({
  historyVacations: VacationRequester.listHistory.getResult(state),
  futureVacations: VacationRequester.listFuture.getResult(state),
})

export const mapDispatchToProps = (dispatch: any) => ({
  loadHistory: () => {
    const filter = {
      toDate: todayISO(),
      types: ['VACATION'],
    } as AbsenceFilter
    dispatch(VacationRequester.listHistory.setParams(filter))
    dispatch(VacationRequester.listHistory.request())
  },

  loadFuture: () => {
    const filter = {
      fromDate: tomorrowISO(),
      types: ['VACATION'],
    } as AbsenceFilter
    dispatch(VacationRequester.listFuture.setParams(filter))
    dispatch(VacationRequester.listFuture.request())
  },
  delete: (id: number) => {
    dispatch(VacationRequester.delete.setParams(id))
    return dispatch(VacationRequester.delete.request())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VacationView)
