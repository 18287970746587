import { SelectCitizenShipDto } from 'backend'
import { Cell, DateField, Grid, Heading, NumberField, TextField, VFlow } from 'bold-ui'
import * as React from 'react'
import { CpfField } from '../../../components/maskedfield/CpfField'

import { CivilStatusSelectField } from './item/CivilStatusSelectField'
import { GentileSelectField } from './item/GentileSelectField'

export interface CollaboratorInformationFieldGroupProps {
  citizenShipDto: SelectCitizenShipDto[]
}

export function CollaboratorInformationFieldGroup(props: CollaboratorInformationFieldGroupProps) {
  const { citizenShipDto } = props
  return (
    <VFlow>
      <Heading level={2}>Dados pessoais</Heading>
      <Grid wrap>
        <Cell size={6}>
          <DateField label='Data de nascimento' name='dateOfBirth' required />
        </Cell>
        <Cell size={6}>
          <CivilStatusSelectField label='Estado civil' name='citizenStatus' required />
        </Cell>
        <Cell size={12}>
          <TextField label='E-mail (Pessoal)' name='personalEmail' required maxLength={40} />
        </Cell>
        <Cell size={6}>
          <CpfField label='CPF' name='ssn' required />
        </Cell>
        <Cell size={6}>
          <NumberField label='RG' name='idRegisterNumber' maxLength={12} required />
        </Cell>
        <Cell size={6}>
          <TextField label='Órgão emissor/UF' name='idRegisterIssuingEntity' required maxLength={25} />
        </Cell>
        <Cell size={6}>
          <DateField label='Data da emissão' name='idRegisterEmissionDate' required />
        </Cell>
        <Cell size={12}>
          <GentileSelectField label='Naturalidade' name='citizenShip' citizenShipDto={citizenShipDto} />
        </Cell>
      </Grid>
    </VFlow>
  )
}
