import { ShiftEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { shifts } from 'types/enums'

export interface StudyShiftFieldProps {
  label: string
  name: string
  required: boolean
}

export const StudyShiftSelectField = (props: StudyShiftFieldProps) => {
  const { required, ...rest } = props

  const itemToString = (item: ShiftEnum) => {
    return shifts[item]
  }

  const items = Object.keys(shifts) as ShiftEnum[]
  return <SelectField<ShiftEnum> items={items} itemToString={itemToString} required={required} {...rest} />
}
