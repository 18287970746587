import { ScheduleDto } from 'backend'
import { Table, TableBody, TableHead, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import ScheduleTableCreateHeader from 'view/schedule/table/create/ScheduleTableCreateHeader'
import DailyAmoutRow from 'view/schedule/table/DailyAmoutRow'
import StartEndFieldIntervalRow from 'view/schedule/table/edit/StartEndFieldIntervalRow'
import StartEndTitleRow from 'view/schedule/table/StartEndTitleRow'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import WeeklyDayTitleRow from 'view/schedule/table/WeeklyDayTitleRow'

export interface ScheduleTableCreateProps extends WithStylesProps {
  schedule: ScheduleDto
  editMode?: boolean
}

@withStyles
class ScheduleTableCreate extends React.Component<ScheduleTableCreateProps> {
  render() {
    const styles = createTableStyles(this.props.theme)

    const { css, schedule, editMode } = this.props
    return (
      <div className={css(styles.createTableContainer)}>
        <ScheduleTableCreateHeader intervals={schedule.weekdayIntervals} editMode={editMode} />
        <Table style={styles.tableBorderNone}>
          <TableHead>
            <WeeklyDayTitleRow />
          </TableHead>
          <TableHead>
            <StartEndTitleRow />
          </TableHead>
          <TableBody>
            <StartEndFieldIntervalRow row={0} />
            <StartEndFieldIntervalRow row={1} />
            <StartEndFieldIntervalRow row={2} />
          </TableBody>
          <TableHead>
            <DailyAmoutRow intervals={schedule.weekdayIntervals} />
          </TableHead>
        </Table>
      </div>
    )
  }
}

export default ScheduleTableCreate
