import { UserDto } from 'backend'
import { combineReducers } from 'redux'
import { requesterReducer, RequesterState } from 'requester'

import authReducer, { AuthState } from './auth'
import notificationReducer, { NotificationState } from './notification'

export interface RootState {
    auth: AuthState<UserDto>
    requester: RequesterState
    notification: NotificationState
}

export default combineReducers<RootState>({
    auth: authReducer,
    requester: requesterReducer,
    notification: notificationReducer,
})
