import api from 'api'
import { GraduationReportDto, SelectTeamDto, SupposedDateEnum } from 'backend'
import { FormControl, HFlow, Select, TextInput, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import React, { useEffect, useState } from 'react'
import { useRequester } from 'requester/useRequester'
import { SupposedGraduationTable } from 'view/report/supposedgraduation/SupposedGraduationTable'

import { supposedDates } from '../../../types/enums'

export function SupposedGraduationView() {
  const { request: supposedGradRequest, result: supposedGradList, isLoading } = useRequester<GraduationReportDto[]>(
    api.report.fetchSupposedGraduation
  )
  const { request: requestTeamSelect, result: teamDto } = useRequester<SelectTeamDto[]>(api.profile.loadTeams)

  const [filter, setFilter] = useState('')
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)

  const [supposedFilter, setSupposedFilter] = useState(null)
  const itemToString = (item: string) => item && supposedDates[item]
  const handleSelectChange = item => setSupposedFilter(item)

  const [teamFilter, setTeamFilter] = useState(null)
  const teamsToString = (item: SelectTeamDto) => item && item.name
  const handleSelectTeamChange = item => setTeamFilter(item)

  useEffect(() => {
    supposedGradRequest()
    requestTeamSelect()
  }, [])

  const supposedDatesItems = Object.keys(supposedDates) as SupposedDateEnum[]

  return (
    <>
      <Container>
        <VFlow vSpacing={2}>
          <HFlow hSpacing={2}>
            <FormControl label='Colaborador'>
              <TextInput size={30} placeholder='Pesquise pelo nome do colaborador' onChange={handleFilterChange} />
            </FormControl>
            <FormControl label='Período'>
              <Select
                items={supposedDatesItems}
                itemToString={itemToString}
                value={supposedFilter}
                onChange={handleSelectChange}
              />
            </FormControl>
            <FormControl label='Equipe'>
              <Select
                items={teamDto}
                itemToString={teamsToString}
                value={teamFilter}
                onChange={handleSelectTeamChange}
              />
            </FormControl>
          </HFlow>
          <SupposedGraduationTable
            dto={supposedGradList}
            loading={isLoading}
            filter={filter}
            supposedFilter={supposedFilter}
            teamFilter={teamFilter}
          />
        </VFlow>
      </Container>
    </>
  )
}
