import { DailyActivityRowItem } from 'backend'
import { HFlow, Styles, TableCell, TableRow, Text, Tooltip, useStyles, VFlow, WithStylesProps } from 'bold-ui'
import { IconButton } from 'components/IconButton'
import moment from 'moment'
import React, { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { isNull } from 'util'
import { DAY_MONTH, WEEKDAY_SHORT } from 'utils/DateUtils'
import { Holiday } from 'utils/HolidayUtils'
import { asPrettyTime } from 'utils/TimeUtils'
import ObservationModal from 'view/dailyregister/modal/ObservationModal'
import { useDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import { getBalanceStyle } from 'view/dailyregister/table/visualize/DailyRegisterRowVisualize'
import {
  calculateBalance,
  calculateTotalDailyAmount,
  isDayWork,
  shouldIgnoreExpectedTime,
  substractAbsenceExpectedTime,
} from 'view/dailyregister/utils/DailyRegisterUtils'
import StartEndFieldInterval from 'view/schedule/table/edit/StartEndFieldInterval'

export interface DailyRegisterRowProps extends WithStylesProps {
  dailyRegister?: DailyActivityRowItem
  index: number
  formProps: FormRenderProps
  name?: string
}

export interface DailyRegisterRowState {
  openObservationModal: boolean
  openJustificationModal: boolean
  formName: string
}

export function DailyRegisterRow(props: DailyRegisterRowProps, state: DailyRegisterRowState) {
  const [openObservationModal, setOpenObservationModal] = useState(false)
  const formName = props.name ? props.name : `dailyRegisters[${props.index}]`

  const onObservationClick = () => {
    setOpenObservationModal(true)
  }

  const onObservationSave = observation => {
    props.formProps.form.change(`${formName}.noteText`, observation.noteText)
    props.formProps.form.change(`${formName}.absenceHours`, moment.duration(observation.absenceHours))
  }

  const onObservationRemove = () => {
    props.formProps.form.change(`${formName}.noteText`, undefined)
    props.formProps.form.change(`${formName}.absenceHours`, undefined)
  }

  const onCloseOrBackdropClick = () => {
    setOpenObservationModal(false)
  }

  const { dailyRegister } = props
  const { classes, theme } = useStyles(useDailyTableStyles)

  if (!dailyRegister) {
    return null
  }

  const styles: Styles = {
    dateTextContainer: {
      width: '5.875rem',
    },
  }

  let totalDailyAmount = moment.duration(0)
  let balance = moment.duration(0)
  if (dailyRegister.activityIntervals.length > 0) {
    totalDailyAmount = calculateTotalDailyAmount(dailyRegister.activityIntervals)
  }
  const ignoredExpectedTime: boolean = shouldIgnoreExpectedTime(dailyRegister)

  if (!moment(dailyRegister.date).isAfter(moment())) {
    balance = calculateBalance(
      moment.duration(dailyRegister.expectedDailyTimeAmount),
      totalDailyAmount,
      ignoredExpectedTime,
      substractAbsenceExpectedTime(dailyRegister.absenceHours)
    )
  }
  const rowReadOnly: boolean = !isDayWork(dailyRegister)
  const balanceStyle = getBalanceStyle(balance, theme)
  return (
    <TableRow>
      <TableCell style={classes.firstColumn}>
        <input hidden={true} name={`${formName}.activityDate`} />
        <VFlow vSpacing={0} alignItems='flex-start'>
          <HFlow hSpacing={0.5} style={styles.dateTextContainer}>
            <Text color='secondary' fontWeight='bold'>
              {moment(dailyRegister.date).format(DAY_MONTH)}
            </Text>
            <Text color='secondary'>
              {moment(dailyRegister.date)
                .format(WEEKDAY_SHORT)
                .replace('-feira', '')}
            </Text>
          </HFlow>
          <Text color='primary' fontWeight='bold'>
            {!isNull(dailyRegister.isAbsence) ? (dailyRegister.isAbsence ? Holiday.ABSENCE : Holiday.HOLIDAY) : null}
          </Text>
        </VFlow>
      </TableCell>
      <TableCell style={classes.timeField}>
        <StartEndFieldInterval name={`${formName}.activityIntervals[${0}]`} disabled={rowReadOnly} />
      </TableCell>
      <TableCell style={classes.timeField}>
        <StartEndFieldInterval name={`${formName}.activityIntervals[${1}]`} disabled={rowReadOnly} />
      </TableCell>
      <TableCell style={classes.timeField}>
        <StartEndFieldInterval name={`${formName}.activityIntervals[${2}]`} disabled={rowReadOnly} />
      </TableCell>
      <TableCell style={classes.buttons}>
        <HFlow hSpacing={0}>
          <VFlow>
            <Tooltip text={rowReadOnly ? 'Não há horário semanal para este dia' : 'Observação'}>
              <IconButton
                icon='fileEmptyOutline'
                onClick={onObservationClick}
                kind={dailyRegister.noteText ? 'primary' : 'normal'}
                disabled={rowReadOnly}
              />
            </Tooltip>
          </VFlow>
        </HFlow>
        <ObservationModal
          isOpen={openObservationModal}
          onCloseOrBackdropClick={onCloseOrBackdropClick}
          initialValue={dailyRegister}
          onSaveClick={onObservationSave}
          onRemoveClick={onObservationRemove}
        />
      </TableCell>
      <TableCell style={classes.balance}>
        <HFlow hSpacing={0.5} justifyContent='center'>
          <Text color='secondary'> {asPrettyTime(totalDailyAmount)}</Text>
          <Text color='secondary' style={balanceStyle.text}>
            {asPrettyTime(balance, true)}
          </Text>
        </HFlow>
      </TableCell>
    </TableRow>
  )
}

export default DailyRegisterRow
