import { CourseDto } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'

export interface CourseSelectFieldProps {
  graduateCourses: CourseDto[]
  label: string
  name: string
}

export const CourseSelectField = (props: CourseSelectFieldProps) => {
  const { graduateCourses, ...rest } = props

  const itemToString = (item: CourseDto) => {
    return item && item.name
  }

  return <SelectField<CourseDto> items={graduateCourses} itemToString={itemToString} {...rest} required />
}
