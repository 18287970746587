import { ScheduleIntervalDto } from 'backend'
import { Button, HFlow, Styles, Text, Tooltip, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import moment from 'moment'
import React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import TotalScheduleAmount from 'view/schedule/table/TotalScheduleAmount'

export interface ScheduleTableHistoryHeaderProps extends WithStylesProps {
  id: number
  intervals: { [index: string]: ScheduleIntervalDto[] }
  startDate: string
  endDate: string

  onExpandClick(): void
}

interface ScheduleTableHistoryHeaderState {
  expanded: boolean
}

@withStyles
class ScheduleTableHistoryHeader extends React.Component<
  ScheduleTableHistoryHeaderProps,
  ScheduleTableHistoryHeaderState
> {
  constructor(props) {
    super(props)
    this.state = { expanded: false }
  }

  render() {
    const { startDate, endDate, intervals, id, theme } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        borderBottom: `1px solid ${theme.pallete.divider}`,
        height: '5rem',
        padding: '0rem 1.5rem',
      },
    }

    return (
      <HFlow alignItems={'center'} justifyContent='space-between' style={styles.container}>
        <HFlow hSpacing={2.625}>
          <VFlow vSpacing={0.125}>
            <Text fontWeight='bold'>Início</Text>
            <Text color='secondary'> {this.getFormattedDate(startDate)} </Text>
          </VFlow>
          <VFlow vSpacing={0.125}>
            <Text fontWeight='bold'>Fim</Text>
            <Text color='secondary'> {this.getFormattedDate(endDate)} </Text>
          </VFlow>
          <VFlow vSpacing={0}>
            <Text fontWeight='bold'>Total semanal</Text>
            <TotalScheduleAmount color='secondary' intervals={intervals} />
          </VFlow>
        </HFlow>
        <HFlow alignItems='center' hSpacing={0.25}>
          <Tooltip text='Editar'>
            <ButtonLink skin='ghost' to={`/schedule/history/${id}/edit`} size='small'>
              <Icon icon='penFilled' />
            </ButtonLink>
          </Tooltip>
          <Button skin='ghost' size='small' onClick={this.onExpandClick}>
            <Icon icon={this.state.expanded ? 'angleUp' : 'angleDown'} />
          </Button>
        </HFlow>
      </HFlow>
    )
  }

  getFormattedDate(date: string) {
    return date ? moment(date).format(DATE_PT_BR) : 'DD/MM/AAAA'
  }

  private onExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
    this.props.onExpandClick()
  }
}

export default ScheduleTableHistoryHeader
