import { AbsenceDto } from 'backend'
import { Button, HFlow, TableBody, TableCell, TableRow, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { Icon } from 'components/Icon'
import moment from 'moment'
import React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import { inclusiveDiff } from 'utils/TimeUtils'

import { ButtonLink } from '../../../components/router/ButtonLink'

interface VacationRowFutureVisualizeProps {
  vacation: AbsenceDto

  onDelete(id: number): void
}

export function VacationRowFutureVisualize(props: VacationRowFutureVisualizeProps) {
  const { vacation, onDelete } = props
  const { classes } = useStyles(createStyles)
  const handleOnClick = (id: number) => {
    onDelete(id)
  }

  let balance = 0
  if (vacation.endDate && vacation.startDate) {
    balance = inclusiveDiff(vacation.endDate, vacation.startDate)
  }
  return (
    <HFlow style={classes.container} alignItems='center' justifyContent='space-between'>
      <TableBody>
        <TableRow>
          <TableCell style={classes.tableCell}>
            <VFlow vSpacing={0.03125}>
              <Text fontWeight='bold'>Início</Text>
              <Text>{moment(vacation.startDate).format(DATE_PT_BR)}</Text>
            </VFlow>
          </TableCell>
          <TableCell style={classes.tableCell}>
            <VFlow vSpacing={0.03125}>
              <Text fontWeight='bold'>Fim</Text>
              <Text>{moment(vacation.endDate).format(DATE_PT_BR)}</Text>
            </VFlow>
          </TableCell>
          <TableCell style={classes.tableCell}>
            <VFlow vSpacing={0.03125}>
              <Text fontWeight='bold'>Nº de dias</Text>
              <Text>{balance} dias</Text>
            </VFlow>
          </TableCell>
        </TableRow>
      </TableBody>
      <HFlow hSpacing={0.125}>
        <Tooltip text='Editar'>
          <ButtonLink skin='ghost' to={`/vacation/${vacation.id}/edit`} size='small'>
            <Icon icon='penFilled' />
          </ButtonLink>
        </Tooltip>
        <Tooltip text='Remover'>
          <Button skin='ghost' onClick={() => handleOnClick(vacation.id)} size='small'>
            <Icon icon='trashFilled' />
          </Button>
        </Tooltip>
      </HFlow>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.pallete.surface.main,
    padding: '0rem 1rem 0rem 0rem',
    height: '4.5625rem',
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.1rem',
  } as React.CSSProperties,
  tableCell: {
    width: '160px',
    border: 'none',
  } as React.CSSProperties,
})

export default VacationRowFutureVisualize
