import api from 'api'
import { BaseFilter, CollaboratorDto, DailyActivityRowItem, ProfileDto, TimeBalanceDto } from 'backend'
import { HFlow, ReferenceMonth, VFlow } from 'bold-ui'
import { useRouter } from 'hooks/useRouter'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useRequester } from 'requester/useRequester'
import { CURRENT_MONTH, DATE_ISO } from 'utils/DateUtils'
import { DailyRegisterHistoryTableReadView } from 'view/dailyregister/history/DailyRegisterHistoryTableReadView'
import { groupResultInWeeks } from 'view/dailyregister/history/utils/DailyRegisterHistoryUtils'
import PeriodAmount from 'view/dailyregister/PeriodAmount'
import TimeBalance from 'view/dailyregister/TimeBalance'
import { calculatePeriodAmount } from 'view/dailyregister/utils/DailyRegisterUtils'

export interface CollaboratorDailyRegisterProps {
  userId: any
}

export const CollaboratorDailyRegister = (props: CollaboratorDailyRegisterProps) => {
  const { userId } = props
  const { request: requestProfile, result: profileDto } = useRequester<ProfileDto>(api.profile.loadProfile)
  const [monthlyAmount, setMonthlyAmount] = useState(null)
  const [monthlyExpected, setMonthlyExpected] = useState(null)
  const [month, setMonth] = useState(CURRENT_MONTH)

  const requesterDailyActivity = useRequester<DailyActivityRowItem[]>(api.collaborator.listDailyActivity)
  const requesterCollaborator = useRequester<CollaboratorDto>(api.collaborator.fetchOne)
  const requesterTimeBalance = useRequester<TimeBalanceDto>(api.timeBalance.fetch)

  useEffect(() => {
    const filter = {
      id: userId,
    } as BaseFilter
    requesterCollaborator.setParams(filter)
    requesterCollaborator.request()

    requesterTimeBalance.setParams(userId)
    requesterTimeBalance.request()
  }, [])

  useEffect(() => {
    requestProfile()
    const mom = moment()
      .year(month.year)
      .month(month.month)
    const filter = {
      id: userId,
      fromDate: moment(mom)
        .startOf('month')
        .startOf('week')
        .format(DATE_ISO),
      toDate: moment(mom)
        .endOf('month')
        .format(DATE_ISO),
    } as BaseFilter
    requesterDailyActivity.setParams(filter)
    requesterDailyActivity.request()
  }, [month])

  useEffect(() => {
    const { expectedPeriodAmount, periodAmount } = calculatePeriodAmount(requesterDailyActivity.result)
    setMonthlyAmount(periodAmount)
    setMonthlyExpected(expectedPeriodAmount)
  }, [requesterDailyActivity.result])

  const onMonthChange = (monthFromComponent: ReferenceMonth) => {
    setMonth(monthFromComponent)
  }

  if (!requesterDailyActivity.result || !monthlyAmount || !monthlyExpected || !profileDto) {
    return null
  }

  return (
    <>
      <VFlow vSpacing={2}>
        <VFlow>
          <HFlow hSpacing={2}>
            <DailyRegisterHistoryTableReadView
              weeks={groupResultInWeeks(requesterDailyActivity.result)}
              readOnly={true}
              onMonthChange={onMonthChange}
              month={month}
            />
            <VFlow vSpacing={1.5}>
              <PeriodAmount title='Total mensal' expectedPeriodAmount={monthlyExpected} periodAmount={monthlyAmount} />
              <TimeBalance timeBalance={requesterTimeBalance.result ? requesterTimeBalance.result.balance : '0'} />
            </VFlow>
          </HFlow>
        </VFlow>
      </VFlow>
    </>
  )
}
