import { ScheduleDto } from 'backend'
import { withStyles, WithStylesProps } from 'bold-ui'
import { EmptyContainer } from 'components/EmptyContainer'
import React from 'react'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import ScheduleTableVisualize from 'view/schedule/table/visualize/ScheduleTableVisualize'
import ScheduleTableVisualizeHeader from 'view/schedule/table/visualize/ScheduleTableVisualizeHeader'

interface ScheduleVisualizeViewProps extends WithStylesProps {
  schedule: ScheduleDto
  load(): any
}

@withStyles
class ScheduleVisualizeView extends React.Component<ScheduleVisualizeViewProps> {
  componentWillMount() {
    this.props.load()
  }

  render() {
    const { schedule, css, theme } = this.props

    if (!schedule) {
      return (
        <EmptyContainer
          message='Você não possui um horário semanal registrado para a data de hoje.'
          buttonLabel='Criar novo'
          buttonLink='/schedule/create'
        />
      )
    }

    const styles = createTableStyles(theme)

    return (
      <div className={css(styles.tableContainer)}>
        <ScheduleTableVisualizeHeader
          intervals={schedule && schedule.weekdayIntervals}
          startDate={schedule && schedule.startDate}
          endDate={schedule && schedule.endDate}
        />
        <ScheduleTableVisualize schedule={schedule} />
      </div>
    )
  }
}

export default ScheduleVisualizeView
