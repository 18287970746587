import { StickyContainer } from 'bold-ui'
import { Login } from 'components/auth/Login'
import AxiosErrorInterceptor from 'components/AxiosErrorInterceptor'
import NotificationContainer from 'components/NotificationContainer'
import React from 'react'
import OauthLoginView from 'view/login/OauthLoginView'
import RootLoggedView from 'view/RootLoggedView'

export const LoginPortal = () => {
  const renderHome = () => {
    return <RootLoggedView />
  }

  const renderForm = () => {
    return <OauthLoginView />
  }
  return (
    <div>
      <StickyContainer>
        <NotificationContainer />
      </StickyContainer>
      <AxiosErrorInterceptor />
      <Login renderHome={renderHome} renderForm={renderForm} />
    </div>
  )
}
