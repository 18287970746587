import { ScheduleDto } from 'backend'
import { Table, TableBody, TableHead, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import DailyAmoutRow from 'view/schedule/table/DailyAmoutRow'
import StartEndFieldIntervalRow from 'view/schedule/table/edit/StartEndFieldIntervalRow'
import StartEndTitleRow from 'view/schedule/table/StartEndTitleRow'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import WeeklyDayTitleRow from 'view/schedule/table/WeeklyDayTitleRow'

interface ScheduleTableEditProps extends WithStylesProps {
  schedule: ScheduleDto
}

@withStyles
class ScheduleTableEdit extends React.Component<ScheduleTableEditProps> {
  render() {
    const { schedule, theme } = this.props
    const styles = createTableStyles(theme)

    return (
      <Table style={styles.table}>
        <TableHead>
          <WeeklyDayTitleRow />
        </TableHead>
        <TableHead>
          <StartEndTitleRow />
        </TableHead>
        <TableBody>
          <StartEndFieldIntervalRow row={0} />
          <StartEndFieldIntervalRow row={1} />
          <StartEndFieldIntervalRow row={2} />
        </TableBody>
        <TableHead>
          <DailyAmoutRow intervals={schedule && schedule.weekdayIntervals} />
        </TableHead>
      </Table>
    )
  }
}

export default ScheduleTableEdit
