import { Styles, Text, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { ButtonLink } from 'components/router/ButtonLink'
import emptyImg from 'images/empty_screen.svg'
import React from 'react'

export interface EmptyContainerProps extends WithStylesProps {
  message: string
  buttonLabel?: string
  buttonLink?: string
  hasButton?: boolean
}

@withStyles
export class EmptyContainer extends React.Component<EmptyContainerProps> {
  static defaultProps: Partial<EmptyContainerProps> = {
    hasButton: true,
  }

  render() {
    const { theme, message, buttonLabel, buttonLink, hasButton } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        height: '30.625rem',
        borderRadius: '0.25rem',
        border: `solid 1px ${theme.pallete.divider}`,
        padding: '2.5rem 0 4rem 0',
      },
      text: {
        display: 'block',
        color: theme.pallete.text.main,
        maxWidth: '21rem',
        wordWrap: 'break-word',
        textAlign: 'center',
      },
    }

    return (
      <VFlow style={styles.container} alignItems='center' vSpacing={3.5}>
        <img src={emptyImg} />
        <VFlow alignItems='center' vSpacing={2}>
          <Text fontSize={1.25} fontWeight='bold' style={styles.text}>
            {' '}
            {message}{' '}
          </Text>
          {hasButton && (
            <ButtonLink kind='primary' to={buttonLink}>
              {buttonLabel}
            </ButtonLink>
          )}
        </VFlow>
      </VFlow>
    )
  }
}
