import { CollaboratorDto } from 'backend'
import { Flow, Heading, Theme, useStyles } from 'bold-ui'
import { Container } from 'components/Container'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import React from 'react'
import { Role } from 'utils/RoleUtils'
import CheckRole from 'view/user/CheckRole'

export interface CollaboratorIdentifierProps {
  collaboratorDto: CollaboratorDto
}

export function CollaboratorIdentifier(props: CollaboratorIdentifierProps) {
  const { classes } = useStyles(createStyles)

  return (
    <Flow alignItems='center' hSpacing={0.75}>
      {props.collaboratorDto.imgLink ? (
        <img src={props.collaboratorDto.imgLink} className={classes.photo} alt={'Foto do colaborador'} />
      ) : (
        <Container style={classes.container}>
          <Icon icon='userCard' />
        </Container>
      )}
      <Heading level={3}>{props.collaboratorDto.name}</Heading>
      <CheckRole roles={[Role.ADMINISTRATOR]}>
        <ButtonLink to={`/collaborator/data/${props.collaboratorDto.id}/profile`} skin='ghost' size='small'>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </CheckRole>
      <CheckRole roles={[Role.LEADER]}>
        <ButtonLink to={`/collaborator/data/${props.collaboratorDto.id}/dailyregister`} skin='ghost' size='small'>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </CheckRole>
    </Flow>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    width: '3rem',
    height: '3rem',
    backgroundColor: theme.pallete.gray.c90,
    borderRadius: '0.25rem',
  } as React.CSSProperties,
  photo: {
    width: '3rem',
    height: '3rem',
    objectFit: 'contain',
    borderRadius: '0.25rem',
  } as React.CSSProperties,
})
