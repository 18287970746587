import { msg } from '..'

export function length(lengthValue: number) {
  return (value: string) => {
    if (value && value.length !== lengthValue) {
      return msg('length', value, lengthValue)
    }
    return null
  }
}

export function minLength(min) {
  return value => {
    if (value && value.length < min) {
      return msg('minLength', value, min)
    }
    return null
  }
}

export function maxLength(max) {
  return value => {
    if (value && value.length > max) {
      return msg('maxLength', value, max)
    }
    return null
  }
}
