import { MaskedField, MaskedFieldProps } from 'bold-ui'
import React from 'react'

import { masks, onlyNumbers } from 'view/dailyregister/utils/masks'

export interface TelefoneFieldProps extends MaskedFieldProps {}

export class TelefoneField extends React.Component<TelefoneFieldProps> {
  static defaultProps: Partial<TelefoneFieldProps> = {}

  render() {
    return <MaskedField mask={masks.telefone} placeholder='(__) _____-____' parse={onlyNumbers} {...this.props} />
  }
}
