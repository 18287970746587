import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'store/modules'
import { userContainsPrivileges } from 'utils/RoleUtils'

interface Props {
  roles: string[]
  showContent?: boolean
}

class CheckRole extends React.Component<Props> {
  render() {
    if (this.props.showContent) {
      return this.props.children
    }
    return null
  }
}

export default connect((state: RootState, ownProps: Props) => {
  return {
    ...ownProps,
    showContent: userContainsPrivileges(state.auth.user, ownProps.roles),
  }
})(CheckRole)
