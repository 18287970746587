import { BaseFilter } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import scheduleRequester from 'store/requesters/ScheduleRequester'
import { DATE_ISO } from 'utils/DateUtils'
import ScheduleVisualizeView from 'view/schedule/table/visualize/ScheduleVisualizeView'

export const mapStateToProps = (state) => ({
    schedule: scheduleRequester.list.getResult(state)
        ? scheduleRequester.list.getResult(state)[0] : null,
})

export const mapDispatchToProps = (dispatch: any) => ({
    load: () => {
        const filter = {
            fromDate: moment().format(DATE_ISO),
            toDate: moment().format(DATE_ISO),
        } as BaseFilter
        dispatch(scheduleRequester.list.setParams(filter))
        dispatch(scheduleRequester.list.request())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleVisualizeView)
