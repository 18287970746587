import { Cell, DateField, Grid, Heading, SwitchField, Text, TextField, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { Role } from 'utils/RoleUtils'
import { profileStyles } from 'view/profile/profileStyles'
import CheckRole from 'view/user/CheckRole'

export function AdministrationFieldGroup() {
  const { classes } = useStyles(profileStyles)

  return (
    <VFlow>
      <Heading style={classes.title} level={2}>
        Dados administrativos
      </Heading>
      <CheckRole roles={[Role.ADMINISTRATOR]}>
        <Grid wrap>
          <Cell size={6}>
            <DateField label='Data da admissão' name='admission' />
          </Cell>
          <Cell size={6}>
            <DateField label='Data do último contrato' name='lastContract' />
          </Cell>
          <Cell size={6}>
            <TextField label='Saldo de férias' name='vacationAccumulated' type='number' />
          </Cell>
          <Cell size={6}>
            <VFlow vSpacing={0.5}>
              <Text fontWeight='bold'>Vínculo</Text>
              <SwitchField label='Ativo' name='active' />
            </VFlow>
          </Cell>
        </Grid>
      </CheckRole>
    </VFlow>
  )
}
