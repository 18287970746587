import { Button, ButtonProps, IconProps } from 'bold-ui'
import { Icon } from 'components/Icon'
import React from 'react'

export interface IconButtonProps extends ButtonProps {
  icon: IconProps['icon']
}

export function IconButton(props: IconButtonProps) {
  const { icon, ...rest } = props
  return (
    <Button skin='ghost' size='small' {...rest}>
      <Icon icon={icon} />
    </Button>
  )
}
