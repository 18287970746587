import { ScheduleIntervalDto } from 'backend'
import { Button, HFlow, Styles, Text, Tooltip, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { DateField } from 'components/date/DateField'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import React from 'react'
import TotalScheduleAmount from 'view/schedule/table/TotalScheduleAmount'

export interface ScheduleTableEditHeaderProps extends WithStylesProps {
  intervals: { [index: string]: ScheduleIntervalDto[] }
  handleSubmit: (values: any) => void
  pristine?: boolean
}

@withStyles
class ScheduleTableEditHeader extends React.Component<ScheduleTableEditHeaderProps> {
  render() {
    const { intervals, handleSubmit, pristine, theme } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        borderBottom: `1px solid ${theme.pallete.divider}`,
        height: '5rem',
        padding: '0rem 1.5rem',
      },
      dateContainer: {
        width: '9rem',
      },
    }

    return (
      <HFlow alignItems={'center'} justifyContent='space-between' style={styles.container}>
        <HFlow hSpacing={1.5}>
          <VFlow vSpacing={0.125} style={styles.dateContainer}>
            <Text fontWeight='bold'>Início</Text>
            <DateField name='startDate' />
          </VFlow>
          <VFlow vSpacing={0.125} style={styles.dateContainer}>
            <Text fontWeight='bold'>Fim</Text>
            <DateField name='endDate' />
          </VFlow>
          <VFlow vSpacing={0.125}>
            <Text fontWeight='bold'>Total semanal</Text>
            <TotalScheduleAmount color='secondary' intervals={intervals} />
          </VFlow>
        </HFlow>
        <HFlow alignItems='center' hSpacing={0.25}>
          <Tooltip text='Cancelar'>
            <ButtonLink to='/schedule' skin='ghost' size='small'>
              <Icon icon='timesDefault' />
            </ButtonLink>
          </Tooltip>
          <Tooltip text='Salvar'>
            <Button skin='ghost' kind='primary' onClick={handleSubmit} disabled={pristine} size='small'>
              <Icon icon='checkDefault' />
            </Button>
          </Tooltip>
        </HFlow>
      </HFlow>
    )
  }
}

export default ScheduleTableEditHeader
