import { Styles, TextColor, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'

export interface CircularProgressBarProps extends WithStylesProps {
  sqSize: number
  percentage: number
  strokeWidth: number
  strokeBackGroundColor?: TextColor
  strokeColor?: TextColor
}

// obtained from https://codepen.io/bbrady/pen/ozrjKE
@withStyles
export class CircularProgressBar extends React.Component<CircularProgressBarProps> {
  render() {
    const { css } = this.props

    const styles: Styles = {
      circleProgress: {
        fill: 'none',
        stroke: this.props.strokeColor ? this.props.strokeColor : '#0099FF',
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        transition: '1s',
      },
      circleBackground: {
        fill: 'none',
        stroke: this.props.strokeBackGroundColor ? this.props.strokeBackGroundColor : '#ddd',
      },
    }

    // Size of the enclosing square
    const sqSize = this.props.sqSize
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100
    return (
      <svg width={this.props.sqSize} height={this.props.sqSize} viewBox={viewBox}>
        <circle
          className={css(styles.circleBackground)}
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className={css(styles.circleProgress)}
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(0 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    )
  }
}
