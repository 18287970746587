import { ProfileDto } from 'backend'
import { Cell, Grid, Heading, InfoLabel, Text, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { createStyles } from 'view/profile/ProfileVisualizeView'

interface BankDataPanelProps {
  profileDto: ProfileDto
}

export default function BankDataPanel(props: BankDataPanelProps) {
  const { classes } = useStyles(createStyles)
  const { profileDto } = props

  const haveTwoAccounts = profileDto && profileDto.accounts.length === 2
  const bank1 = profileDto.accounts[0]
  const bank2 = haveTwoAccounts && profileDto.accounts[1]

  return (
    <>
      <VFlow>
        <Heading level={2}>Dados bancários</Heading>
        <div className={classes.card}>
          <Grid wrap>
            <Cell xs={12}>
              <InfoLabel title='Banco' placeholder='-'>
                <Text>{bank1 ? bank1.bank.name : '-'}</Text>
              </InfoLabel>
            </Cell>
            <Cell xs={6}>
              <InfoLabel title='Agência' placeholder='-'>
                <Text>{bank1 ? bank1.agencyNumber : '-'}</Text>
              </InfoLabel>
            </Cell>
            <Cell xs={6}>
              <InfoLabel title='Conta corrente com dígito' placeholder='-'>
                <Text>{bank1 ? bank1.accountNumber : '-'}</Text>
              </InfoLabel>
            </Cell>
          </Grid>
        </div>

        {haveTwoAccounts && (
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Conta secundária</Heading>
            <div className={classes.card}>
              <Grid wrap>
                <Cell xs={12}>
                  <InfoLabel title='Banco' placeholder='-'>
                    <Text>{bank2 ? bank2.bank.name : '-'}</Text>
                  </InfoLabel>
                </Cell>
                <Cell xs={6}>
                  <InfoLabel title='Agência' placeholder='-'>
                    <Text>{bank2 ? bank2.agencyNumber : '-'}</Text>
                  </InfoLabel>
                </Cell>
                <Cell xs={6}>
                  <InfoLabel title='Conta corrente com dígito' placeholder='-'>
                    <Text>{bank2 ? bank2.accountNumber : '-'}</Text>
                  </InfoLabel>
                </Cell>
              </Grid>
            </div>
          </VFlow>
        )}
      </VFlow>
    </>
  )
}
