import { Heading, HFlow, Tabs, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { Styles } from 'bold-ui/lib/styles/withStyles'
import { Container } from 'components/Container'
import { ButtonLink } from 'components/router/ButtonLink'
import { TabLink } from 'components/router/TabLink'
import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { default as ScheduleCreateContainer } from 'view/schedule/ScheduleModalContainer'
import ScheduleVisualizeContainer from 'view/schedule/ScheduleVisualizeContainer'

import ScheduleEditContainer from './ScheduleEditContainer'
import { ScheduleHistoryView } from './table/history/ScheduleHistoryView'

export interface ScheduleRootViewProps extends RouteComponentProps<any>, WithStylesProps {}

@withStyles
class ScheduleRootView extends React.Component<ScheduleRootViewProps> {
  render() {
    const { theme, match } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        color: theme.pallete.text.main,
        height: '9rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
        border: `1px solid ${theme.pallete.divider}`,
      },
      headerContainer: {
        paddingTop: '2.5rem',
        paddingBottom: '0',
        borderBottom: `1px solid ${theme.pallete.divider}`,
        ul: {
          margin: '0rem',
        },
      },
      contentContainer: {
        paddingTop: '1rem',
      },
    }

    return (
      <>
        <Container style={styles.headerContainer}>
          <VFlow vSpacing={1.25}>
            <HFlow justifyContent='space-between' alignItems='center'>
              <Heading level={1}>Horário semanal</Heading>
              <ButtonLink kind='primary' to={'/schedule/create'}>
                Criar novo
              </ButtonLink>
            </HFlow>
            <Tabs>
              <TabLink to='/schedule/current'>Horário atual</TabLink>
              <TabLink to='/schedule/history'>Horários anteriores</TabLink>
            </Tabs>
          </VFlow>
        </Container>

        <Container style={styles.contentContainer}>
          <Switch>
            <Route exact path={`${match.url}/current`} component={ScheduleVisualizeContainer} />
            <Route exact path={`${match.url}/current/edit`} component={ScheduleEditContainer} />
            <Route exact path={`${match.url}/create`} component={ScheduleCreateContainer} />
            <Route path={`${match.url}/history`} component={ScheduleHistoryView} />
            <Redirect from={`${match.url}`} to={`${match.url}/current`} />
          </Switch>
        </Container>
      </>
    )
  }
}

export default ScheduleRootView
