import { BaseFilter } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import TimeBalanceRequester from 'store/requesters/TimeBalanceRequester'
import { DATE_ISO } from 'utils/DateUtils'

import DailyRegisterHistoryView from './DailyRegisterHistoryView'
import { groupResultInWeeks } from './utils/DailyRegisterHistoryUtils'

export const mapStateToProps = state => ({
  timeBalance: TimeBalanceRequester.fetchCurrent.getResult(state),
  weeks: groupResultInWeeks(DailyRegisterRequester.fetchAll.getResult(state)),
  userId: state.auth.user.userId,
})

export const mapDispatchToProps = dispatch => ({
  loadBalance: () => dispatch(TimeBalanceRequester.fetchCurrent.request()),
  loadWeeks: (date: Date) => {
    const filter = {
      fromDate: moment(date)
        .startOf('month')
        .startOf('week')
        .format(DATE_ISO),
      toDate: moment(date)
        .endOf('month')
        .endOf('week')
        .format(DATE_ISO),
    } as BaseFilter
    dispatch(DailyRegisterRequester.fetchAll.setParams(filter))
    dispatch(DailyRegisterRequester.fetchAll.request())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyRegisterHistoryView)
