import { ScheduleDto } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import scheduleRequester from 'store/requesters/ScheduleRequester'
import ScheduleCreateModal from 'view/schedule/table/create/ScheduleCreateModal'

export type OwnProps = RouteComponentProps<any>

export const mapStateToProps = () => ({

})

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
    save: (dto: ScheduleDto) => {
        dispatch(scheduleRequester.create.setParams(dto))
        return dispatch(scheduleRequester.create.request())
    },

    goBack() {
        ownProps.history.push(`/schedule/current`)
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCreateModal)
