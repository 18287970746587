import { DayOfWeek, ScheduleIntervalDto } from 'backend'
import moment from 'moment'
import { isValidTime } from 'utils/TimeUtils'

export const calculateTotalScheduleAmount =
    (scheduleIntervals: { [index: string]: ScheduleIntervalDto[] }) => {
        const totalTime: moment.Duration = moment.duration()

        const weekDays = Object.keys(scheduleIntervals) as DayOfWeek[]
        for (const weekDay of weekDays) {
            for (const interval of scheduleIntervals[weekDay]) {
                if (interval && isValidTime(interval.startTime) && isValidTime(interval.endTime)) {
                    totalTime.add(moment.duration(interval.endTime).subtract(moment.duration(interval.startTime)))
                }
            }
        }
        return totalTime
    }

export const calculateTotalDailyAmount = (dailyIntervals: Array<{ startTime: string, endTime: string }>) => {
    const totalTime: moment.Duration = moment.duration()
    for (const interval of dailyIntervals) {
        if (interval && isValidTime(interval.startTime) && isValidTime(interval.endTime)) {
            totalTime.add(moment.duration(interval.endTime).subtract(moment.duration(interval.startTime)))
        }
    }
    return moment.utc(totalTime.asMilliseconds()).format('HH:mm')
}
