import { ScheduleDto } from 'backend'
import { Flow, Styles, Table, TableBody, TableHead, Text, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import DailyAmoutRow from 'view/schedule/table/DailyAmoutRow'
import StartEndTitleRow from 'view/schedule/table/StartEndTitleRow'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import StartEndTextIntervalRow from 'view/schedule/table/visualize/StartEndTextIntervalRow'
import WeeklyDayTitleRow from 'view/schedule/table/WeeklyDayTitleRow'

interface ScheduleTableVisualizeProps extends WithStylesProps {
  schedule: ScheduleDto
}

@withStyles
class ScheduleTableVisualize extends React.Component<ScheduleTableVisualizeProps> {
  render() {
    const { schedule, theme } = this.props
    if (!this.props.schedule) {
      const withoutScheduleStyles: Styles = {
        container: {
          background: theme.pallete.surface.main,
          borderBottom: `1px solid ${theme.pallete.divider}`,
          height: '2.5rem',
          padding: '0rem 1.5rem',
        },
      }

      return (
        <Flow style={withoutScheduleStyles.container} alignItems='center' justifyContent='center'>
          <Text color='disabled' fontWeight='bold'>
            Sem horário cadastrado
          </Text>
        </Flow>
      )
    }

    const styles = createTableStyles(theme)

    return (
      <div>
        <Table style={styles.table}>
          <TableHead>
            <WeeklyDayTitleRow />
          </TableHead>
          <TableHead>
            <StartEndTitleRow />
          </TableHead>
          <TableBody>
            <StartEndTextIntervalRow row={0} intervals={schedule && schedule.weekdayIntervals} />
            <StartEndTextIntervalRow row={1} intervals={schedule && schedule.weekdayIntervals} />
            <StartEndTextIntervalRow row={2} intervals={schedule && schedule.weekdayIntervals} />
          </TableBody>
          <TableHead>
            <DailyAmoutRow color={theme.pallete.gray.c60} intervals={schedule && schedule.weekdayIntervals} />
          </TableHead>
        </Table>
      </div>
    )
  }
}

export default ScheduleTableVisualize
