import { AbsenceDto } from 'backend'
import {
  Form,
  Heading,
  HFlow,
  Modal,
  ModalBody,
  ModalFooter,
  NumberField,
  Text,
  VFlow,
  withStyles,
  WithStylesProps,
} from 'bold-ui'
import { DateField } from 'components/date/DateField'
import moment from 'moment'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { DATE_ISO } from 'utils/DateUtils'
import { inclusiveDiff } from 'utils/TimeUtils'
import SaveRemoveFooter from 'view/dailyregister/modal/SaveRemoveFooter'

export interface VacationCreateViewProps extends WithStylesProps {
  vacation?: AbsenceDto
  editMode?: boolean
  save?(form: AbsenceDto): Promise<any>
  delete?(id: Number): Promise<any>
  goBack?(): void
  load?(): any
}

@withStyles
class VacationCreateView extends React.Component<VacationCreateViewProps> {
  componentWillMount() {
    if (this.props.editMode) {
      this.props.load()
    }
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitSucceeded={this.props.goBack}
        render={this.renderForm}
        initialValues={this.props.vacation}
      />
    )
  }

  renderForm = (formProps: FormRenderProps) => {
    const title = this.props.editMode ? 'Editar férias' : 'Adicionar férias'
    const fieldValue = formProps.values as AbsenceDto
    const change = formProps.form.change
    return (
      <Modal open={true} size='small' onClose={this.props.goBack}>
        <ModalBody>
          <VFlow vSpacing={2}>
            <Heading level={1}>{title}</Heading>
            <HFlow hSpacing={1}>
              <VFlow vSpacing={0.5}>
                <Text fontWeight='bold'>Início</Text>
                <DateField name='startDate' onChange={this.onStartChange(fieldValue, change)} />
              </VFlow>
              <VFlow vSpacing={0.5}>
                <Text fontWeight='bold'>Fim</Text>
                <DateField name='endDate' onChange={this.onEndChange(fieldValue, change)} />
              </VFlow>
              <VFlow vSpacing={0.5}>
                <Text fontWeight='bold'>Nº de dias</Text>
                <NumberField name='days' onChange={this.onDaysChange(fieldValue, change)} maxLength={3} />
              </VFlow>
            </HFlow>
          </VFlow>
        </ModalBody>

        <ModalFooter>
          <SaveRemoveFooter
            onSaveClick={formProps.handleSubmit}
            onRemoveClick={this.delete(fieldValue.id)}
            editMode={false}
          />
        </ModalFooter>
      </Modal>
    )
  }

  private handleSubmit = value => {
    return this.props.save(value)
  }

  private delete = (id: Number) => () => {
    this.props.delete(id).then(res => this.props.goBack())
  }

  private onStartChange = (vacation, change) => (date: Date) => {
    const value = date && moment(date)
    if (!value) {
      change('days', null)
    } else if (vacation.endDate) {
      change('days', inclusiveDiff(vacation.endDate, value))
    } else if (vacation.days) {
      const endDate = moment(value).add(vacation.days, 'days')
      change('endDate', endDate.format(DATE_ISO))
    }
  }

  private onEndChange = (vacation, change) => (date: Date) => {
    const value = date && moment(date)
    if (!value) {
      change('days', null)
    } else if (vacation.startDate) {
      change('days', inclusiveDiff(value, vacation.startDate))
    }
  }

  private onDaysChange = (vacation, change) => value => {
    const days = value.currentTarget.value
    if (!days || Number(days) === 0) {
      change('endDate', null)
    } else if (vacation.startDate) {
      const endDate = moment(vacation.startDate).add(days - 1, 'days')
      change('endDate', endDate.format(DATE_ISO))
    }
  }
}

export default VacationCreateView
