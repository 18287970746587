import { Icon, Tag, Theme, Tooltip, useStyles, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { Styles } from 'bold-ui/lib/styles/withStyles'
import { VTabLink, VTabs } from 'components/vtabs/VTabs'
import React, { CSSProperties } from 'react'
import { Role } from 'utils/RoleUtils'

import CheckRole from './user/CheckRole'

export interface MenuBarProps extends WithStylesProps {
  roleText: string
}

@withStyles
export class MenuBar extends React.Component<MenuBarProps> {
  render() {
    const { css, theme } = this.props

    const styles: Styles = {
      container: {
        display: 'flex',
        background: theme.pallete.surface.main,
        borderRight: `1px solid ${theme.pallete.divider}`,
        minHeight: '100vh',
        height: '100%',
        paddingTop: '1rem',
        flexDirection: 'column',
      },
      imgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '4.875rem',
        height: '3rem',
      },
    }

    return (
      <div className={css(styles.container)}>
        <VTabs>
          <VFlow vSpacing={1}>
            <Tooltip text='Registro diário'>
              <VTabLink to='/dailyregister'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='timerFilled' />
                </div>
              </VTabLink>
            </Tooltip>
            <Tooltip text='Horário semanal'>
              <VTabLink to='/schedule'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='calendarFilled' />
                </div>
              </VTabLink>
            </Tooltip>
            <Tooltip text='Férias'>
              <VTabLink to='/vacation'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='beach' />
                </div>
              </VTabLink>
            </Tooltip>
            <Tooltip text='Colaboradores'>
              <VTabLink to='/collaborator'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='multipleUsers' />
                </div>
              </VTabLink>
            </Tooltip>
            <Tooltip text='Meu perfil'>
              <VTabLink to='/profile'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='userCard' />
                </div>
              </VTabLink>
            </Tooltip>
            <CheckRole roles={[Role.ADMINISTRATOR, Role.LEADER]}>
              <Tooltip text='Relatórios'>
                <VTabLink to='/report'>
                  <div className={css(styles.imgContainer)}>
                    <Icon icon='fileWithItensFilled' />
                  </div>
                </VTabLink>
              </Tooltip>
            </CheckRole>
            <Tooltip text='Links úteis'>
              <VTabLink to='/usefullinks'>
                <div className={css(styles.imgContainer)}>
                  <Icon icon='link' />
                </div>
              </VTabLink>
            </Tooltip>
          </VFlow>
        </VTabs>
        <RoleLabel {...this.props} />
      </div>
    )
  }
}

const RoleLabel = props => {
  const { roleText } = props
  const { classes } = useStyles(roleLabelStyles)
  return (
    <CheckRole roles={[Role.ADMINISTRATOR, Role.LEADER]}>
      <div className={classes.roleContainer}>
        <Tag className={classes.roleLabelText}>{roleText}</Tag>
      </div>
    </CheckRole>
  )
}

const roleLabelStyles = (theme: Theme) => ({
  roleContainer: {
    textAlign: 'center',
    width: '5rem',
    marginTop: 'auto',
    position: 'fixed',
    bottom: '10px',
  } as CSSProperties,
  roleLabelText: {
    padding: '5px',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '1px',
    color: theme.pallete.gray.c40,
    background: theme.pallete.gray.c90,
    borderRadius: '2px',
  } as CSSProperties,
})
