import { ScheduleDto } from 'backend'
import { Form, HFlow, Modal, ModalBody, ModalFooter, SubmitButton, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import ScheduleTableCreate from 'view/schedule/table/create/ScheduleTableCreate'

export interface ScheduleCreateModalProps extends WithStylesProps {
  schedule?: ScheduleDto
  editMode?: boolean

  save?(form: ScheduleDto): Promise<any>

  load?(): any

  goBack(): void
}

@withStyles
class ScheduleCreateModal extends React.Component<ScheduleCreateModalProps> {
  static defaultProps: Partial<ScheduleCreateModalProps> = {
    editMode: false,
  }

  componentWillMount() {
    if (this.props.editMode) {
      this.props.load()
    }
  }

  render() {
    return (
      <Form
        onSubmit={this.save}
        onSubmitSucceeded={this.props.goBack}
        initialValues={this.props.schedule}
        render={this.renderForm}
        subscription={{ values: true, submitErrors: true }}
      />
    )
  }

  renderForm = (formProps: FormRenderProps) => {
    return (
      <div>
        <Modal open={true} size='auto' onClose={this.props.goBack}>
          <ModalBody>
            <ScheduleTableCreate schedule={formProps.values as ScheduleDto} editMode={this.props.editMode} />
          </ModalBody>

          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <SubmitButton handleSubmit={formProps.handleSubmit}>Salvar</SubmitButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private save = value => {
    return this.props.save(value)
  }
}

export default ScheduleCreateModal
