import { Heading, Tabs, useStyles, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import { TabLink } from 'components/router/TabLink'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { baseStyles } from 'view/baseStyles'
import { BalanceReportView } from 'view/report/balance/BalanceReportView'
import { VacationReportView } from 'view/report/vacation/VacationReportView'
import { SupposedGraduationView } from './supposedgraduation/SupposedGraduationView'

export interface ReportRootViewProps extends RouteComponentProps<any> {}

export function ReportRootView(props: ReportRootViewProps) {
  const { classes } = useStyles(baseStyles)
  const { match } = props
  return (
    <>
      <Container style={classes.headerContainer}>
        <VFlow vSpacing={1.5}>
          <Heading level={1}> Relatórios</Heading>
          <Tabs>
            <TabLink to='/report/timebalance'>Banco de horas</TabLink>
            <TabLink to='/report/vacation'>Férias</TabLink>
            <TabLink to='/report/graduation'>Previsão de conclusão</TabLink>
          </Tabs>
        </VFlow>
      </Container>

      <Container style={classes.contentContainer}>
        <Switch>
          <Route path={`${match.url}/timebalance`} component={BalanceReportView} />
          <Route path={`${match.url}/vacation`} component={VacationReportView} />
          <Route path={`${match.url}/graduation`} component={SupposedGraduationView} />
          <Redirect exact from={`${match.url}`} to={`${match.url}/timebalance`} />
        </Switch>
      </Container>
    </>
  )
}
