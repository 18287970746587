import React, { CSSProperties } from 'react'

import { Container } from 'components/Container'
import { Heading, useStyles, VFlow, Theme, Link } from 'bold-ui'

export function UsefulLinksRootView() {
  const { classes } = useStyles(createStyles)

  return (
    <Container style={classes.content}>
      <VFlow vSpacing={1.25}>
        <Heading level={1}>Links úteis</Heading>
        <Link
          href='https://sites.google.com/bridge.ufsc.br/drive-compartilhado/'
          target='_blank'
          style={classes.textLinks}
        >
          Drive Bridge
        </Link>
      </VFlow>
    </Container>
  )
}

export const createStyles = () => ({
  content: {
    paddingTop: '3rem',
    paddingBottom: '2rem',
  } as CSSProperties,
  textLinks: {
    textDecoration: 'none',
    fontSize: '1rem',
    '&:hover': {
      textDecoration: 'underline',
    },
  } as CSSProperties,
})
