import { connect } from 'react-redux'
import {
  NotificationItem, removeNotification, restartNotificationTimeout, stopNotificationTimeout
} from 'store/modules/notification'

import { NotificationWrapper } from './NotificationWrapper'

export const mapStateToProps = (state: any) => {
  return {
    notifications: state.notification.items,
  }
}

export const mapDispatchToProps = (dispatch: any) => ({
  removeNotification: (id: NotificationItem['id']) => {
    dispatch(removeNotification(id))
  },
  restartNotificationTimeout: (id: NotificationItem['id']) => {
    dispatch(restartNotificationTimeout(id))
  },
  stopNotificationTimeout: (id: NotificationItem['id']) => {
    dispatch(stopNotificationTimeout(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWrapper)
