import { ProfileDto } from 'backend'
import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import * as React from 'react'
import { degrees, shifts, supposedDates } from 'types/enums'

interface ClassInformationPanelProps {
  profileDto: ProfileDto
}

export default function ClassInformationPanel(props: ClassInformationPanelProps) {
  const { profileDto } = props
  const educationDto = profileDto && profileDto.universityEducation

  return (
    <Grid wrap>
      <Cell xs={12}>
        <InfoLabel title='Grau acadêmico' placeholder='-'>
          <Text>
            {educationDto && profileDto.universityEducation.degree
              ? degrees[profileDto.universityEducation.degree]
              : '-'}
          </Text>
        </InfoLabel>
      </Cell>
      {educationDto && educationDto.studying && (
        <Cell xs={6}>
          <InfoLabel title='Matrícula' placeholder='-'>
            <Text>{educationDto.studying ? educationDto.academicRegistration : '-'}</Text>
          </InfoLabel>
        </Cell>
      )}
      {educationDto && educationDto.studying && (
        <Cell xs={6}>
          <InfoLabel title='Previsão de conclusão' placeholder='-'>
            <Text>{educationDto.studying ? supposedDates[educationDto.supposedDate] : '-'}</Text>
          </InfoLabel>
        </Cell>
      )}
      {educationDto && educationDto.studying && (
        <Cell xs={12}>
          <InfoLabel title='Turno' placeholder='-'>
            <Text>{educationDto.studying ? shifts[educationDto.shift] : '-'}</Text>
          </InfoLabel>
        </Cell>
      )}
      <Cell xs={6}>
        <InfoLabel title='Curso (Graduação)' placeholder='-'>
          <Text>{educationDto && educationDto.graduation ? educationDto.graduation.name : '-'}</Text>
        </InfoLabel>
      </Cell>
      {educationDto && educationDto.master && (
        <Cell xs={6}>
          <InfoLabel title='Curso (Mestrado)' placeholder='-'>
            <Text>{educationDto.master ? educationDto.master : '-'}</Text>
          </InfoLabel>
        </Cell>
      )}
      {educationDto && educationDto.phd && (
        <Cell xs={12}>
          <InfoLabel title='Curso (Douturado)' placeholder='-'>
            <Text>{educationDto.phd ? educationDto.phd : '-'}</Text>
          </InfoLabel>
        </Cell>
      )}
    </Grid>
  )
}
