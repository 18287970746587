import api from 'api'
import { AbsenceDto, VacationDto } from 'backend'
import { VFlow } from 'bold-ui'
import { EmptyContainer } from 'components/EmptyContainer'
import { useRouter } from 'hooks/useRouter'
import { Route } from 'react-router'
import { useRequester } from 'requester/useRequester'
import VacationBalance from 'view/vacation/VacationBalance'
import VacationCreateContainer from 'view/vacation/VacationCreateContainer'
import VacationListFutureView from './list/VacationListFutureView'
import VacationListPastView from './list/VacationListPastView'
import VacationEditContainer from './VacationEditContainer'
import React, { useEffect } from 'react'

interface VacationViewProps {
  historyVacations?: AbsenceDto[]
  futureVacations?: AbsenceDto[]
  loadHistory(): any
  loadFuture(): any
}

export function VacationView(props: VacationViewProps) {
  const { historyVacations, futureVacations, loadHistory, loadFuture } = props
  const { request: vacationRequest, result: vacation } = useRequester<VacationDto>(api.vacation.fetchBalance)
  const { match } = useRouter()

  useEffect(() => {
    loadLists()
  }, [])

  const loadLists = () => {
    loadHistory()
    loadFuture()
    vacationRequest()
  }

  const handleOnUpdateDelete = () => {
    loadLists()
  }

  const renderVacationEditContainer = () => {
    return <VacationEditContainer onSubmitSucceeded={loadLists} />
  }

  const renderVacationCreateContainer = () => {
    return <VacationCreateContainer onSubmitSucceeded={loadLists} />
  }

  const hasFuture = futureVacations && futureVacations.length > 0
  const hasHistory = historyVacations && historyVacations.length > 0

  return (
    <div>
      <VFlow vSpacing={2}>
        <VacationBalance vacation={vacation} />

        {hasFuture ? (
          <VacationListFutureView
            title='Férias agendadas'
            vacations={futureVacations}
            onUpdate={handleOnUpdateDelete}
          />
        ) : null}

        {hasHistory ? <VacationListPastView title='Histórico de férias' vacations={historyVacations} /> : null}

        {!hasFuture && !hasHistory && (
          <EmptyContainer
            message='Você não possui férias cadastradas…'
            buttonLabel='Agendar férias'
            buttonLink='/vacation/create'
          />
        )}
      </VFlow>

      <Route exact path={`${match.url}/create`} render={renderVacationCreateContainer} />
      <Route path={`${match.url}/:vacationId(\\d+)/edit`} render={renderVacationEditContainer} />
    </div>
  )
}

export default VacationView
