import { ThemeProvider } from 'bold-ui'
import { ThemeContext, useThemeSwitch } from 'config/theme'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from 'store'
import { LoginPortal } from 'view/LoginPortal'

import { Rollbar } from './components/Rollbar'

export function App() {
  const [currentTheme, toggleTheme] = useThemeSwitch()

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={currentTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <Rollbar />
            <LoginPortal />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
