import { DailyActivityRowItem } from 'backend'
import { HFlow, Table, TableBody, TableHead, TableHeader, TableRow, Text, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import WeeklyTableFooter from 'view/dailyregister/history/WeeklyTableFooter'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import DailyRegisterRowVisualize from 'view/dailyregister/table/visualize/DailyRegisterRowVisualize'
import { calculateBalance, calculatePeriodAmount } from 'view/dailyregister/utils/DailyRegisterUtils'
import StartEndTimeHeader from 'view/schedule/table/StartEndTimeHeader'

interface DailyRegisterHistoryWeeklyTableReadProps extends WithStylesProps {
  values?: DailyActivityRowItem[]
  weekNumber: number
}

@withStyles
class DailyRegisterHistoryWeeklyTableRead extends React.Component<DailyRegisterHistoryWeeklyTableReadProps> {
  render() {
    const { theme, values, weekNumber } = this.props

    const tableStyle = createDailyTableStyles(theme)
    const hasFooter = values.length === 7
    return (
      <>
        <Table>
          {weekNumber === 0 ? (
            <TableHead>
              <HeaderTitleRow style={tableStyle.header} />
            </TableHead>
          ) : null}
          <TableBody>
            {values.map((value, i) => {
              return <DailyRegisterRowVisualize key={i} dailyRegister={value} />
            })}
          </TableBody>
        </Table>
        {hasFooter ? this.renderFooter(values) : null}
      </>
    )
  }

  renderFooter = values => {
    const { periodAmount, expectedPeriodAmount } = calculatePeriodAmount(values)
    const balance = calculateBalance(expectedPeriodAmount, periodAmount)
    return <WeeklyTableFooter balance={balance} weeklyAmount={periodAmount} readonly={true} />
  }
}

const HeaderTitleRow = ({ style }) => {
  return (
    <TableRow className={style}>
      <TableHeader>
        <HFlow alignItems='flex-start'>
          <Text> Dia</Text>
        </HFlow>
      </TableHeader>
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <TableHeader>
        <Text> {''}</Text>
      </TableHeader>
      <TableHeader>
        <HFlow hSpacing={0.5}>
          <Text> Total</Text>
          <Text> Saldo</Text>
        </HFlow>
      </TableHeader>
    </TableRow>
  )
}

export default DailyRegisterHistoryWeeklyTableRead
