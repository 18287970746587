import axios, { AxiosPromise } from 'axios'

export class Auth {
  loadUserInfo(): AxiosPromise<any> {
    return axios.get('/api/users/current')
  }

  login(username: string, password: string) {
    return axios.post('/api/login', `username=${username}&password=${password}`)
  }

  logout(): AxiosPromise<any> {
    window.localStorage && window.localStorage.setItem('logout', Date.now().toString())
    return axios.post('/api/logout')
  }

  updateUserInfo(): AxiosPromise<any> {
    return axios.get('/api/users/update')
  }
}
