import { TableHeader, TableRow, Text, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { createTableStyles } from 'view/schedule/table/tableStyles'

@withStyles
class WeeklyDayTitleRow extends React.Component<WithStylesProps> {
  render() {
    const styles = createTableStyles(this.props.theme)

    return (
      <TableRow style={styles.row}>
        <TableHeader>
          <Text>Segunda</Text>
        </TableHeader>
        <TableHeader>
          <Text>Terça</Text>
        </TableHeader>
        <TableHeader>
          <Text>Quarta</Text>
        </TableHeader>
        <TableHeader>
          <Text>Quinta</Text>
        </TableHeader>
        <TableHeader>
          <Text>Sexta</Text>
        </TableHeader>
      </TableRow>
    )
  }
}

export default WeeklyDayTitleRow
