import { ProfileSelectsDto } from 'backend'
import { Cell, Grid, HFlow, TextField, VFlow } from 'bold-ui'
import { useRoles } from 'hooks/useRoles'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Role } from 'utils/RoleUtils'
import { FunctionSelectField } from './item/FunctionSelectField'
import { JobBondSelectField } from './item/JobBondSelectField'
import { ProfileSelectedField } from './item/ProfileSelectedField'
import { TeamSelectField } from './item/TeamSelectField'

export interface SummaryInformationFieldGroupProps {
  profileSelectDto: ProfileSelectsDto
}

export function SummaryInformationFieldGroup(props: SummaryInformationFieldGroupProps) {
  const { profileSelectDto } = props
  const [role, setRole] = useState(false)

  const userRoles = useRoles()
  useEffect(() => {
    setRole(userRoles.includes(Role.COLLABORATOR) || userRoles.includes(Role.LEADER))
  }, [userRoles])

  return (
    <Grid wrap>
      <HFlow hSpacing={0}>
        <VFlow hSpacing={0} vSpacing={0.5} style={{ width: '320px' }}>
          <Cell>
            <TextField label='Nome completo' name='name' required maxLength={65} />
          </Cell>
          <Cell>
            <TeamSelectField label='Equipe' name='team' required selectTeamDto={profileSelectDto.teamDto} />
          </Cell>
        </VFlow>

        <VFlow vSpacing={0.5}>
          <Cell>
            <ProfileSelectedField label='Perfil' name='role' required disabled={role} />
          </Cell>
          <Cell>
            <FunctionSelectField label='Função' name='job' required jobFunctions={profileSelectDto.jobFunctionDto} />
          </Cell>
        </VFlow>
        <Cell>
          <JobBondSelectField label='Vínculo' name='jobBond' required />
        </Cell>
      </HFlow>
    </Grid>
  )
}
