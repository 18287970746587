import React from 'react'
import { HFlow, VFlow, Text } from 'bold-ui'

export function VacationTextIntern(props) {
  const { classes } = props
  return (
    <>
      <HFlow hSpacing={2}>
        <VFlow>
          <Text>
            <br />
            <Text style={classes.bolder}>Aumentando a carga horária</Text>
            <br />
            <div className={classes.txtContainer}>
              <p className={classes.justifier}>
                Um bridger trabalhou durante 6 meses e quis alterar a carga horária de 20 para 30 horas semanais. O que
                antes era equivalente à 15 dias de férias (60 horas) será ajustado para a nova carga horária, de 6 horas
                diárias, passando a ser 10 dias de férias.
              </p>
            </div>
          </Text>
          <Text>
            <Text style={classes.bolder}>Diminuindo a carga horária</Text>
            <br />
            <div className={classes.txtContainer}>
              <p className={classes.justifier}>
                Um bridger trabalhou durante 6 meses e quis alterar a carga horária de 30 para 20 horas semanais. O que
                antes era equivalente à 15 dias de férias (90 horas) será ajustado para a nova carga horária, de 4 horas
                diárias, passando a ser 22,5 dias de férias.
              </p>
            </div>
          </Text>
        </VFlow>
        <VFlow style={classes.grayContainer} vSpacing={0.5}>
          <Text style={classes.bolder}>Acúmulo de férias</Text>
          <Text>
            A cada mês trabalhado, o bolsista tem direito à 2,5 dias de férias, equivalentes à carga horária diária.
          </Text>
          <Text style={classes.b_blue}>Atenção!</Text>
          <Text>Não esqueça de alinhar com o seu líder antes de registrar as férias.</Text>
        </VFlow>
      </HFlow>
    </>
  )
}

export default VacationTextIntern
