import api from 'api'
import { BaseFilter, DailyActivityRowItem } from 'backend'
import { Requester } from 'requester'

export default {
    createOne: new Requester<DailyActivityRowItem[],
        DailyActivityRowItem[]>('dailyRegister/createOne', api.dailyRegister.create),
    createAll: new Requester<DailyActivityRowItem[],
        DailyActivityRowItem[]>('dailyRegister/createAll', api.dailyRegister.create),
    fetchOne: new Requester<DailyActivityRowItem[], BaseFilter>('dailyRegister/fetchOne', api.dailyRegister.fetch),
    fetchAll: new Requester<DailyActivityRowItem[], BaseFilter>('dailyRegister/fetchAll', api.dailyRegister.fetch),
}
