import { CollaboratorVacationDto } from 'backend'
import { Flow, Text, useStyles, VFlow } from 'bold-ui'
import React from 'react'
import { Role } from 'utils/RoleUtils'
import { CollaboratorIdentifier } from 'view/collaborator/CollaboratorIdentifier'
import { createCollaboratorVacationTableStyles } from 'view/collaborator/vacation/table/CollaboratorVacationTableStyles'
import CheckRole from 'view/user/CheckRole'

export interface CollaboratorVacationRowItemProps {
  data: CollaboratorVacationDto
}

export function CollaboratorVacationTableHeader(props: CollaboratorVacationRowItemProps) {
  const { classes } = useStyles(createCollaboratorVacationTableStyles)

  return (
    <Flow style={classes.container} alignItems='center' justifyContent='space-between'>
      <CollaboratorIdentifier collaboratorDto={props.data.collaboratorDto} />
      <CheckRole roles={[Role.ADMINISTRATOR, Role.LEADER]}>
        <VFlow vSpacing={0} alignItems='flex-end'>
          <Text style={classes.thead}>Férias para usufruir</Text>
          <Text>{props.data.daysAvailable} dia(s)</Text>
        </VFlow>
      </CheckRole>
    </Flow>
  )
}
