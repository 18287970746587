import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { BaseFilter, ScheduleDto } from 'backend'

export class Schedule {

    create(form: ScheduleDto, config: AxiosRequestConfig): AxiosPromise<ScheduleDto> {
        return axios.post('/api/schedule', form, config)
    }

    list(params: BaseFilter): AxiosPromise<ScheduleDto[]> {
        return axios.get('/api/schedule', { params })
    }

    edit(form: ScheduleDto, config: AxiosRequestConfig): AxiosPromise<ScheduleDto> {
        return axios.put('/api/schedule/' + form.id, form, config)
    }

}
