import { Cell, Grid, NumberField } from 'bold-ui'
import * as React from 'react'
import { TelefoneField } from '../../../components/maskedfield/TelefoneField'

export interface TelephoneFieldGroupProps {}

export function TelephoneFieldGroup() {
  return (
    <Grid wrap>
      <Cell size={6}>
        <NumberField label='Sala' name='room' maxLength={3} required />
      </Cell>
      <Cell size={4}>
        <NumberField label='Ramal' name='phoneLineExtension' maxLength={4} />
      </Cell>
      <Cell size={6}>
        <TelefoneField label='Celular' name='phone' required />
      </Cell>
      <Cell size={6}>
        <TelefoneField label='Celular (2)' name='secondaryPhone' />
      </Cell>
    </Grid>
  )
}
