import { HFlow, Icons, Popper, PopperController, Styles, Text, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { IconButton } from 'components/IconButton'
import React from 'react'

export interface DailyRegisterPopperProps extends WithStylesProps {
  title: string
  contentHours: string
  contentText: string
  icon: Icons
}

@withStyles
export class DailyRegisterPopper extends React.PureComponent<DailyRegisterPopperProps> {
  render() {
    const { theme } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        width: '12rem',
        padding: '0.5rem 1rem 1rem 1rem',
        border: `1px solid ${theme.pallete.divider}`,
        boxShadow: theme.shadows.outer['20'],
        borderRadius: theme.radius.popper,
      },
    }

    return (
      <Popper renderTarget={this.renderButton} placement='bottom-start' closeOnOutsideClick={true}>
        {(ctrl: PopperController) => (
          <VFlow style={styles.container} vSpacing={0}>
            <HFlow justifyContent='space-between' alignItems='center'>
              <Text fontWeight='bold'> {this.props.title} </Text>
              <IconButton icon='timesDefault' onClick={ctrl.hide} />
            </HFlow>
            <Text color='secondary'> {this.props.contentHours} </Text>
            <Text color='secondary'> {this.props.contentText} </Text>
          </VFlow>
        )}
      </Popper>
    )
  }

  renderButton = (ctrl: PopperController) => {
    return <IconButton icon={this.props.icon} onClick={ctrl.show} kind='primary' />
  }
}
