import { DailyActivityRowItem } from 'backend'
import { ReferenceMonth } from 'bold-ui'
import moment from 'moment'
import qs from 'qs'
import { isUndefined } from 'util'
import { CURRENT_MONTH, YEAR_MONTH } from 'utils/DateUtils'

export const groupResultInWeeks = (dailyRegisters: DailyActivityRowItem[]) => {
  if (!dailyRegisters) {
    return null
  }
  const weeks = new Array<DailyActivityRowItem[]>()
  const numWeeks = Math.ceil(dailyRegisters.length / 7)
  for (let weekNumber = 0; weekNumber < numWeeks; weekNumber++) {
    const week = new Array<DailyActivityRowItem>()
    const startOfWeek = weekNumber * 7
    const endOfWeek = startOfWeek + Math.min(dailyRegisters.length - startOfWeek, 7)
    for (let weekDay = startOfWeek; weekDay < endOfWeek; weekDay++) {
      week.push(dailyRegisters[weekDay])
    }
    weeks.push(week)
  }
  return weeks
}

export const filterWeeksMonth = (weeks: DailyActivityRowItem[][]) => {
  const filterWeeks: DailyActivityRowItem[][] = []
  const newDailyregister: DailyActivityRowItem[] = []
  let month: number
  for (const week of weeks) {
    if (isUndefined(month)) {
      month = new Date([...week].splice(-1)[0].date.concat('T00:00:00')).getMonth()
    }
    for (const dailyRegister of week) {
      if (new Date(dailyRegister.date.concat('T00:00:00')).getMonth() === month) {
        newDailyregister.push(dailyRegister)
      }
    }
  }
  filterWeeks.push(newDailyregister)
  return filterWeeks
}

export const getDateFromUrl = (url: Location['search']) => {
  if (url.length === 0) {
    return CURRENT_MONTH
  }
  const searchParams = qs.parse(url, { ignoreQueryPrefix: true })
  const mom = moment(searchParams.date, YEAR_MONTH)
  const date = { year: mom.year(), month: mom.month() } as ReferenceMonth
  return date
}
