import { ProfileDto } from 'backend'
import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import moment from 'moment'
import * as React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import { civilStatus } from '../../../types/enums'

interface CollaboratorInformationPanelProps {
  profileDto: ProfileDto
}

export default function CollaboratorInformationPanel(props: CollaboratorInformationPanelProps) {
  const { profileDto } = props

  const ssnMasked = () => {
    const part1 = profileDto.ssn && profileDto.ssn.slice(0, 3)
    const part2 = profileDto.ssn && profileDto.ssn.slice(3, 6)
    const part3 = profileDto.ssn && profileDto.ssn.slice(6, 9)
    const part4 = profileDto.ssn && profileDto.ssn.slice(9, 11)
    return `${part1}.${part2}.${part3}-${part4}`
  }

  return (
    <Grid wrap>
      <Cell xs={7}>
        <InfoLabel title='Data de nascimento'>
          <Text>{profileDto.dateOfBirth ? moment(profileDto.dateOfBirth).format(DATE_PT_BR) : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={5}>
        <InfoLabel title='Estado civil' placeholder='-'>
          <Text>{profileDto.citizenStatus ? civilStatus[profileDto.citizenStatus] : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={7}>
        <InfoLabel title='E-mail pessoal' placeholder='-'>
          <Text>{profileDto.personalEmail ? profileDto.personalEmail : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={5}>
        <InfoLabel title='CPF' placeholder='-'>
          <Text>{profileDto.ssn ? ssnMasked() : '-'} </Text>
        </InfoLabel>
      </Cell>
      <Cell xs={7}>
        <InfoLabel title='RG' placeholder='-'>
          <Text>{profileDto.idRegisterNumber ? profileDto.idRegisterNumber : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={5}>
        <InfoLabel title='Órgão emissor' placeholder='-'>
          <Text>{profileDto.idRegisterIssuingEntity ? profileDto.idRegisterIssuingEntity : '-'}</Text>
        </InfoLabel>
      </Cell>
      <Cell xs={7}>
        <InfoLabel title='Data de emissão' placeholder='-'>
          <Text>
            {profileDto.idRegisterEmissionDate ? moment(profileDto.idRegisterEmissionDate).format(DATE_PT_BR) : '-'}
          </Text>
        </InfoLabel>
      </Cell>
      <Cell xs={5}>
        <InfoLabel title='Naturalidade' placeholder='-'>
          <Text>{profileDto.citizenShip ? profileDto.citizenShip.city + '/' + profileDto.citizenShip.state : '-'}</Text>
        </InfoLabel>
      </Cell>
    </Grid>
  )
}
