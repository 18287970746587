import { CollaboratorDto } from 'backend'
import { withStyles, WithStylesProps } from 'bold-ui'
import { Styles } from 'bold-ui/lib/styles/withStyles'
import { Container } from 'components/Container'
import { useRoles } from 'hooks/useRoles'
import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import CollaboratorRequester from 'store/requesters/CollaboratorRequester'
import { Role } from 'utils/RoleUtils'

import CollaboratorHeader from './CollaboratorHeader'
import CollaboratorScheduleContainer from './schedule/CollaboratorScheduleContainer'
import CollaboratorVacationContainer from './vacation/CollaboratorVacationContainer'

export interface CollaboratorListingViewProps extends RouteComponentProps<any>, WithStylesProps {
  allCollaborators: CollaboratorDto[]
  load(collaboratorsIds?: number[]): Promise<any>
}

export interface CollaboratorListingState {
  filteredCollaboratorsIds: number[]
}

@withStyles
class CollaboratorListingView extends React.Component<CollaboratorListingViewProps, CollaboratorListingState> {
  constructor(props) {
    super(props)
    this.state = { filteredCollaboratorsIds: [] }
  }

  componentDidMount() {
    this.props.load()
  }

  render() {
    const { theme, match } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.gray.c100,
        height: '12rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
        display: 'flex',
        alignItems: 'flex-end',
      },
      contentContainer: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
      },
    }

    return (
      <>
        <Container style={styles.container}>
          <CollaboratorHeader searchOptions={this.props.allCollaborators} onChangeSearch={this.searchResult} />
        </Container>

        <Container style={styles.contentContainer}>
          <Switch>
            <Route exact path={`${match.url}/schedule`} render={this.renderCollaboratorScheduleView} />
            <Route exact path={`${match.url}/vacation`} component={this.renderCollaboratorVacationView} />
            <Redirect exact from={`${match.url}`} to={`${match.url}/schedule`} />
          </Switch>
        </Container>
      </>
    )
  }

  private searchResult = (result: CollaboratorDto[]) => {
    this.setState({
      filteredCollaboratorsIds: result.map(item => item.id),
    })
  }

  private renderCollaboratorScheduleView = () => {
    return <CollaboratorScheduleContainer collaboratorsIds={this.state.filteredCollaboratorsIds} />
  }

  private renderCollaboratorVacationView = () => {
    return (
      <CollaboratorVacationContainer
        requester={this.getRequester()}
        collaboratorsIds={this.state.filteredCollaboratorsIds}
      />
    )
  }

  private getRequester = () => {
    const roles = useRoles()
    if (roles.includes(Role.COLLABORATOR)) {
      return CollaboratorRequester.listPublicVacation
    }
    return CollaboratorRequester.listVacation
  }
}

export default CollaboratorListingView
