import { HFlow, Progress, Styles, Text, withStyles, WithStylesProps } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { asPrettyTime } from 'utils/TimeUtils'
import { calculatePercent } from 'view/dailyregister/utils/DailyRegisterUtils'

export interface PeriodAmountProps extends WithStylesProps {
  title: string
  periodAmount: moment.Duration
  expectedPeriodAmount: moment.Duration
}

@withStyles
class PeriodAmount extends React.Component<PeriodAmountProps> {
  render() {
    const { css, theme, title, periodAmount, expectedPeriodAmount } = this.props
    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        height: '8rem',
        border: `1px solid ${theme.pallete.divider}`,
        borderRadius: '0.25rem',
        width: '13rem',
        color: theme.pallete.text.main,
      },
      header: {
        padding: '1rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
      },
      progress: {
        height: '0.5rem',
      },
    }
    const percent = calculatePercent(expectedPeriodAmount, periodAmount)
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Text fontWeight='bold'>{title}</Text>
        </div>
        <div className={css(styles.header)}>
          <HFlow hSpacing={0.25} alignItems='center'>
            <Text fontWeight='bold' fontSize={1}>
              {' '}
              {asPrettyTime(periodAmount)}{' '}
            </Text>
            <Text color='secondary'> de {asPrettyTime(expectedPeriodAmount)} </Text>
          </HFlow>
          <Progress style={styles.progress} value={percent} />
        </div>
      </div>
    )
  }
}

export default PeriodAmount
