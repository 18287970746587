import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { BaseFilter, DailyActivityRowItem } from 'backend'

export class DailyRegister {
  create(form: DailyActivityRowItem[], config: AxiosRequestConfig): AxiosPromise<DailyActivityRowItem[]> {
    form.forEach(value => (value.absenceType = null))
    return axios.post('/api/activity', form, config)
  }

  fetch(params: BaseFilter): AxiosPromise<DailyActivityRowItem[]> {
    return axios.get('/api/activity/', { params })
  }
}
