import { Theme, useStyles } from 'bold-ui'
import React from 'react'
import { Redirect, Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { Role } from 'utils/RoleUtils'
import DailyRegisterRootView from 'view/dailyregister/DailyRegisterRootView'
import { ReportRootView } from 'view/report/ReportRootView'
import ScheduleRootView from 'view/schedule/ScheduleRootView'
import PrivateRoute from 'view/user/PrivateRoute'
import UserBarContainer from 'view/user/UserBarContainer'
import VacationRootView from 'view/vacation/VacationRootView'

import { useRouter } from '../hooks/useRouter'

import { CollaboratorRootView } from './collaborator/CollaboratorRootView'
import { ForbiddenView } from './ForbiddenView'
import { CompleteRegisterModal } from './login/CompleteRegisterModal'
import MenuBarContainer from './MenuBarContainer'
import { ProfileRootView } from './profile/ProfileRootView'
import { UsefulLinksRootView } from './usefullinks/UsefulLinksRootView'

export default function RootLoggedView() {
  const { classes } = useStyles(styles)

  const { location } = useRouter()

  if (location.pathname === '/403') {
    return <ForbiddenView />
  }
  return (
    <>
      <CompleteRegisterModal />

      <div className={classes.container}>
        <header className={classes.header}>
          <UserBarContainer />
        </header>
        <nav className={classes.nav}>
          <MenuBarContainer />
        </nav>
        <main className={classes.main}>
          <Switch>
            <Route path='/dailyregister' component={DailyRegisterRootView} />
            <Route path='/schedule' component={ScheduleRootView} />
            <Route path='/vacation' component={VacationRootView} />
            <Route path='/collaborator' component={CollaboratorRootView} />
            <Route path='/profile' component={ProfileRootView} />
            <Route path='/usefullinks' component={UsefulLinksRootView} />
            <PrivateRoute roles={[Role.ADMINISTRATOR, Role.LEADER]} path={'/report'} component={ReportRootView} />
            <Redirect from='/' to='/dailyregister' />
          </Switch>
        </main>
      </div>
    </>
  )
}

const styles = (theme: Theme) => ({
  container: {
    background: theme.pallete.surface.main,
    display: 'grid',
    gridTemplateAreas: `"header header"
                                     "nav main"`,
    gridTemplateColumns: '5rem 1fr',
  } as React.CSSProperties,
  header: {
    gridArea: 'header',
  } as React.CSSProperties,
  nav: {
    gridArea: 'nav',
  } as React.CSSProperties,
  main: {
    gridArea: 'main',
  } as React.CSSProperties,
})
