import { TimeBalanceReportDto } from 'backend'
import { DataTable, defaultSelectFilter, Text, useTheme } from 'bold-ui'
import * as moment from 'moment'
import React, { useState } from 'react'
import { asPrettyTime } from 'utils/TimeUtils'
import { getBalanceStyle } from 'view/report/ReportUtils'

export interface BalanceTableProps {
  dto: TimeBalanceReportDto[]
  loading: boolean
  filter: string
  saldoFilter: { label: string; filter: (balance: number) => boolean }
  traineeFilter: boolean
  employeeFilter: boolean
}

export function BalanceTable(props: BalanceTableProps) {
  const { dto, loading, filter, saldoFilter, traineeFilter, employeeFilter } = props
  const theme = useTheme()

  const [sort, setSort] = useState(['collaboratorName'])

  const handleSortChange = (s: string[]) => setSort(s)
  const rows = (dto || []).sort((a, b) => {
    if (sort[0] === 'collaboratorName') {
      return a.collaboratorName < b.collaboratorName ? -1 : 1
    }

    if (sort[0] === '-collaboratorName') {
      return a.collaboratorName > b.collaboratorName ? -1 : 1
    }

    if (sort[0] === 'timeBalance') {
      return moment.duration(a.timeBalance) < moment.duration(b.timeBalance) ? -1 : 1
    }

    if (sort[0] === '-timeBalance') {
      return moment.duration(a.timeBalance) > moment.duration(b.timeBalance) ? -1 : 1
    }

    return 0
  })
  const filteredRows = defaultSelectFilter(rows, filter, item => item.collaboratorName + item.timeBalance)
    .filter(item => (saldoFilter ? saldoFilter.filter(moment.duration(item.timeBalance).asHours()) : true))
    .filter(item =>
      traineeFilter && employeeFilter
        ? true
        : employeeFilter
        ? item.collaboratorJobBond === 'EMPLOYEE'
        : traineeFilter
        ? item.collaboratorJobBond === 'INTERN'
        : true
    )

  return (
    <DataTable<TimeBalanceReportDto>
      rows={filteredRows}
      sort={sort}
      onSortChange={handleSortChange}
      loading={loading}
      columns={[
        {
          name: 'collaboratorName',
          header: 'Colaborador',
          sortable: true,
          render: item => item.collaboratorName,
        },
        {
          name: 'timeBalance',
          header: 'Saldo de horas',
          sortable: true,
          render: item => (
            <Text style={getBalanceStyle(moment.duration(item.timeBalance), theme).text}>
              {asPrettyTime(moment.duration(item.timeBalance), true)}
            </Text>
          ),
        },
      ]}
    />
  )
}
