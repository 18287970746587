import api from 'api'
import { BaseFilter, ScheduleDto } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Requester } from 'requester'
import scheduleRequester from 'store/requesters/ScheduleRequester'
import ScheduleCreateModal from 'view/schedule/table/create/ScheduleCreateModal'

export interface OwnProps extends RouteComponentProps<any> {
    editMode?: boolean,
    onSubmitSucceeded(): void
}

export const mapStateToProps = (state: any, ownProps: OwnProps) => {
    const id = ownProps.match.params.scheduleId
    const requester = new Requester<ScheduleDto[], BaseFilter>(`schedule/list${id}`, api.schedule.list)
    return {
        editMode: ownProps.editMode,
        schedule: requester.getResult(state) ? requester.getResult(state)[0] : null,
    }
}

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
    load: () => {
        const id = ownProps.match.params.scheduleId
        const requester = new Requester<ScheduleDto[], BaseFilter>(`schedule/list${id}`, api.schedule.list)
        const filter = {
            id,
        } as BaseFilter
        dispatch(requester.setParams(filter))
        return dispatch(requester.request())
    },
    save: (dto: ScheduleDto) => {
        dispatch(scheduleRequester.edit.setParams(dto))
        return dispatch(scheduleRequester.edit.request())
    },
    goBack() {
        ownProps.onSubmitSucceeded()
        ownProps.history.push(`/schedule/history`)
    },
})

export default withRouter(connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps)(ScheduleCreateModal))
