import { CollaboratorVacationDto } from 'backend'
import { Flow, Table, TableBody, Text, useStyles } from 'bold-ui'
import React from 'react'
import { CollaboratorVacationTableHeader } from 'view/collaborator/vacation/table/CollaboratorVacationTableHeader'
import { CollaboratorVacationTableHeaderText } from 'view/collaborator/vacation/table/CollaboratorVacationTableHeaderText'
import { CollaboratorVacationTableRowItem } from 'view/collaborator/vacation/table/CollaboratorVacationTableRowItem'
import { createCollaboratorVacationTableStyles } from 'view/collaborator/vacation/table/CollaboratorVacationTableStyles'

export interface CollaboratorVacationTableProps {
  data: CollaboratorVacationDto
}

export function CollaboratorVacationTable(props: CollaboratorVacationTableProps) {
  const { classes } = useStyles(createCollaboratorVacationTableStyles)
  const body =
    props.data.absencesDto !== null && props.data.absencesDto.length !== 0 ? (
      <Table>
        <CollaboratorVacationTableHeaderText />
        <TableBody>
          {props.data.absencesDto.map((absenceDto, key) => (
            <CollaboratorVacationTableRowItem data={absenceDto} key={key} />
          ))}
        </TableBody>
      </Table>
    ) : (
      <Flow style={classes.disabledTable} alignItems='center' justifyContent='center'>
        <Text color='disabled' fontWeight='bold'>
          Sem férias registradas
        </Text>
      </Flow>
    )
  return (
    <div className={classes.table}>
      <CollaboratorVacationTableHeader data={props.data} />
      <div className={classes.unabledBorder}>{body}</div>
    </div>
  )
}
