import api from 'api'
import { CollaboratorDto, CollaboratorFilter, CollaboratorScheduleDto, CollaboratorVacationDto } from 'backend'
import { PageRequester, Requester } from 'requester'

export default {
  listAll: new Requester<CollaboratorDto[], CollaboratorFilter>('collaborator/listAll', api.collaborator.listAll),
  listSchedule: new PageRequester<CollaboratorScheduleDto, CollaboratorFilter>(
    'collaborator/listSchedule',
    api.collaborator.listSchedule
  ),
  listVacation: new Requester<CollaboratorVacationDto[], CollaboratorFilter>(
    'collaborator/listVacation',
    api.collaborator.listVacation
  ),
  listPublicVacation: new Requester<CollaboratorVacationDto[], CollaboratorFilter>(
    'collaborator/listPublicVacation',
    api.collaborator.listPublicVacation
  ),
}
