import React from 'react'
import { HFlow, VFlow, Text } from 'bold-ui'

export function VacationTextEmployee(props) {
  const { classes } = props
  return (
    <>
      <HFlow hSpacing={2}>
        <VFlow>
          <Text>
            <br />
            <p />
            <div className={classes.txtContainer}>
              <p className={classes.justifier}>
                De acordo com as leis trabalhistas, todo celetista tem direito à um período de 30 dias de férias após
                completar um ano de trabalho, à partir da data do contrato, que são somados ao saldo total de férias.
              </p>
            </div>
          </Text>
        </VFlow>
        <VFlow style={classes.grayContainer} vSpacing={0.5}>
          <Text style={classes.b_blue}>Atenção!</Text>
          <Text>Não esqueça de alinhar com o seu líder antes de registrar as férias.</Text>
        </VFlow>
      </HFlow>
    </>
  )
}

export default VacationTextEmployee
