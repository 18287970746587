import { ProfileDto } from 'backend'
import { Cell, Grid, Heading, Theme, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'

import AddressPanel from './visualize/AddressPanel'
import BankDataPanel from './visualize/BankDataPanel'
import ClassInformationPanel from './visualize/ClassInformationPanel'
import CollaboratorInformationPanel from './visualize/CollaboratorInformationPanel'
import TelephonePanel from './visualize/TelephonePanel'

export interface ProfileContentProps {
  profileDto: ProfileDto
}

export default function ProfileContent(props: ProfileContentProps) {
  const { profileDto } = props
  const { classes } = useStyles(createStyles)

  if (!profileDto) {
    return null
  }

  return (
    <>
      <VFlow vSpacing={2}>
        <Grid wrap alignItems='flex-start'>
          <Cell xs={12} md={6}>
            <CollaboratorInformationPanel profileDto={profileDto} />
          </Cell>
          <Cell xs={12} md={6} style={classes.telephonePanelDivider}>
            <TelephonePanel profileDto={profileDto} />
          </Cell>
        </Grid>

        <Grid wrap>
          <Cell xs={12} md={6}>
            <VFlow>
              <Heading level={2}>Informações do curso</Heading>
              <div className={classes.card}>
                <ClassInformationPanel profileDto={profileDto} />
              </div>
            </VFlow>
          </Cell>
          <Cell xs={12} md={6}>
            <VFlow>
              <BankDataPanel profileDto={profileDto} />
            </VFlow>
          </Cell>
        </Grid>

        <VFlow>
          <Heading level={2}>Endereço</Heading>
          <Grid>
            <Cell xs={8}>
              <AddressPanel profileDto={profileDto} />
            </Cell>
          </Grid>
        </VFlow>
      </VFlow>
    </>
  )
}

export const createStyles = (theme: Theme) => ({
  card: {
    border: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
  } as React.CSSProperties,

  telephonePanelDivider: {
    borderLeft: `1px solid ${theme.pallete.divider}`,
    paddingLeft: '1rem',
  } as React.CSSProperties,
})
