import { AxiosError } from 'axios'
import { BaseFilter, DailyActivityRowItem } from 'backend'
import moment from 'moment'
import qs from 'qs'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import { DATE_ISO, YEAR_MONTH } from 'utils/DateUtils'
import DailyRegisterHistoryTableView from 'view/dailyregister/history/DailyRegisterHistoryTableView'

import { groupResultInWeeks } from './utils/DailyRegisterHistoryUtils'

export interface OwnProps extends RouteComponentProps {
  onValueChange(value: DailyActivityRowItem[][]): void
  updateWhenSubmitSucess(): void
}

const getBackUrl = (props: RouteComponentProps) => {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  const mom = moment(searchParams.date, YEAR_MONTH)
  const dateUrl = `date=${mom.year()}-${mom.month() + 1}`
  if (mom.isValid()) {
    return `/dailyregister/history/visualize?${dateUrl}`
  }
  return `/dailyregister/history/visualize`
}

export const mapStateToProps = (state: any, ownProps: OwnProps) => {
  return {
    onValueChange: ownProps.onValueChange,
    weeks: groupResultInWeeks(DailyRegisterRequester.fetchAll.getResult(state)),
    getBackUrl,
  }
}

export const mapDispatchToProps = (dispatch, ownProps: OwnProps) => ({
  load: (date: Date) => {
    const filter = {
      fromDate: moment(date)
        .startOf('month')
        .startOf('week')
        .format(DATE_ISO),
      toDate: moment(date)
        .endOf('month')
        .endOf('week')
        .format(DATE_ISO),
    } as BaseFilter
    dispatch(DailyRegisterRequester.fetchAll.setParams(filter))
    dispatch(DailyRegisterRequester.fetchAll.request())
  },
  save: (value: { weeks: DailyActivityRowItem[][] }) => {
    const values = new Array<DailyActivityRowItem>()
    value.weeks.forEach((week: DailyActivityRowItem[]) => {
      week.forEach((day: DailyActivityRowItem) => {
        values.push(day)
      })
    })
    dispatch(DailyRegisterRequester.createAll.setParams(values))
    return dispatch(DailyRegisterRequester.createAll.request()).catch((res: AxiosError) => {
      const errors = { weeks: groupResultInWeeks(res.response.data.values) }
      return Promise.reject({ response: { data: errors, status: 400 } })
    })
  },
  onSubmitSuccess() {
    ownProps.updateWhenSubmitSucess()
    ownProps.history.push(getBackUrl(ownProps))
  },
})

export default withRouter(
  connect<{}, {}, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DailyRegisterHistoryTableView)
)
