import { SelectTeamDto } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'

export interface TeamSelectFieldProps {
  selectTeamDto: SelectTeamDto[]
  required: boolean
  label: string
  name: string
}

export const TeamSelectField = (props: TeamSelectFieldProps) => {
  const { selectTeamDto, ...rest } = props

  const itemToString = (item: SelectTeamDto) => {
    return item && item.name
  }
  return <SelectField<SelectTeamDto> items={selectTeamDto} itemToString={itemToString} {...rest} />
}
