import { BaseFilter } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import { DATE_ISO } from 'utils/DateUtils'
import DailyRegisterTableVisualize from 'view/dailyregister/table/visualize/DailyRegisterTableVisualize'

export interface OwnProps {
    onValueChange(values: Object): void
}

export const mapStateToProps = (state, ownProps: OwnProps) => ({
    onValueChange: ownProps.onValueChange,
    dailyRegisters: DailyRegisterRequester.fetchAll.getResult(state),
})

export const mapDispatchToProps = (dispatch) => ({
    load: () => {
        const filter = {
            fromDate: moment().startOf('week').format(DATE_ISO),
            toDate: moment().endOf('week').format(DATE_ISO),
        } as BaseFilter
        dispatch(DailyRegisterRequester.fetchAll.setParams(filter))
        dispatch(DailyRegisterRequester.fetchAll.request())
    },
})

export default connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps)(DailyRegisterTableVisualize)
