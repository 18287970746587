import { TableCell, TableRow, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import StartEndFieldInterval from 'view/schedule/table/edit/StartEndFieldInterval'
import { createTableStyles } from 'view/schedule/table/tableStyles'

export interface StartEndFieldIntervalRowProps extends WithStylesProps {
  row: number
}

@withStyles
class StartEndFieldIntervalRow extends React.Component<StartEndFieldIntervalRowProps> {
  render() {
    const styles = createTableStyles(this.props.theme)

    return (
      <TableRow style={styles.row}>
        <TableCell style={styles.cell}>
          <StartEndFieldInterval name={`weekdayIntervals[MONDAY][${this.props.row}]`} />
        </TableCell>
        <TableCell>
          <StartEndFieldInterval name={`weekdayIntervals[TUESDAY][${this.props.row}]`} />
        </TableCell>
        <TableCell>
          <StartEndFieldInterval name={`weekdayIntervals[WEDNESDAY][${this.props.row}]`} />
        </TableCell>
        <TableCell>
          <StartEndFieldInterval name={`weekdayIntervals[THURSDAY][${this.props.row}]`} />
        </TableCell>
        <TableCell>
          <StartEndFieldInterval name={`weekdayIntervals[FRIDAY][${this.props.row}]`} />
        </TableCell>
      </TableRow>
    )
  }
}

export default StartEndFieldIntervalRow
