import { TableHead, TableHeader, TableRow, Text, useStyles } from 'bold-ui'
import React from 'react'
import { createCollaboratorVacationTableStyles } from 'view/collaborator/vacation/table/CollaboratorVacationTableStyles'

export function CollaboratorVacationTableHeaderText() {
  const { classes } = useStyles(createCollaboratorVacationTableStyles)
  return (
    <TableHead>
      <TableRow style={classes.theadText}>
        <TableHeader>
          <Text>Início</Text>
        </TableHeader>
        <TableHeader>
          <Text>Fim</Text>
        </TableHeader>
        <TableHeader>
          <Text>Nº de dias</Text>
        </TableHeader>
      </TableRow>
    </TableHead>
  )
}
