import { DailyActivityRowItem } from 'backend'
import { HFlow, Table, TableBody, TableHead, TableHeader, TableRow, Text, withStyles, WithStylesProps } from 'bold-ui'
import { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import WeeklyTableFooter from 'view/dailyregister/history/WeeklyTableFooter'
import DailyRegisterRow from 'view/dailyregister/table/DailyRegisterRow'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import { calculateBalance, calculatePeriodAmount } from 'view/dailyregister/utils/DailyRegisterUtils'
import StartEndTimeHeader from 'view/schedule/table/StartEndTimeHeader'

interface DailyRegisterHistoryWeeklyTableProps extends WithStylesProps {
  values?: DailyActivityRowItem[]
  formProps: FormRenderProps
  weekNumber: number
}

interface DailyRegisterHistoryWeeklyTableState {
  expectedWeeklyAmount: moment.Duration
  weeklyAmount: moment.Duration
}

@withStyles
class DailyRegisterHistoryWeeklyTable extends React.Component<
  DailyRegisterHistoryWeeklyTableProps,
  DailyRegisterHistoryWeeklyTableState
> {
  constructor(props) {
    super(props)
    this.state = {
      expectedWeeklyAmount: moment.duration(0),
      weeklyAmount: moment.duration(0),
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.values, nextProps.values) || !isEqual(this.state, nextState)
  }

  componentDidMount() {
    if (this.props.values) {
      this.updateAmounts(this.props.values)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.values && !isEqual(this.props.values, prevProps.values)) {
      this.updateAmounts(this.props.values)
    }
  }

  render() {
    const { theme, values, formProps, weekNumber } = this.props

    const tableStyle = createDailyTableStyles(theme)
    const hasFooter = this.props.values.length === 7
    return (
      <>
        <Table>
          {this.props.weekNumber === 0 ? (
            <TableHead>
              <HeaderTitleRow style={tableStyle.header} />
            </TableHead>
          ) : null}
          <TableBody>
            {values.map((value, i) => {
              return (
                <DailyRegisterRow
                  key={i}
                  index={i}
                  dailyRegister={value}
                  formProps={formProps}
                  name={`weeks[${weekNumber}][${i}]`}
                />
              )
            })}
          </TableBody>
        </Table>
        {hasFooter ? this.renderFooter() : null}
      </>
    )
  }

  updateAmounts = (weekValue: DailyActivityRowItem[]) => {
    const { periodAmount, expectedPeriodAmount } = calculatePeriodAmount(weekValue)
    this.setState({ weeklyAmount: periodAmount, expectedWeeklyAmount: expectedPeriodAmount })
  }

  renderFooter = () => {
    const balance = calculateBalance(this.state.expectedWeeklyAmount, this.state.weeklyAmount)
    return <WeeklyTableFooter balance={balance} weeklyAmount={this.state.weeklyAmount} />
  }
}

const HeaderTitleRow = ({ style }) => {
  return (
    <TableRow className={style}>
      <TableHeader>
        <HFlow alignItems='flex-start'>
          <Text> Dia</Text>
        </HFlow>
      </TableHeader>
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <TableHeader>
        <Text> {''}</Text>
      </TableHeader>
      <TableHeader>
        <HFlow hSpacing={0.5}>
          <Text> Total</Text>
          <Text> Saldo</Text>
        </HFlow>
      </TableHeader>
    </TableRow>
  )
}

export default DailyRegisterHistoryWeeklyTable
