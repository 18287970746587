import { GraduationReportDto, SelectTeamDto } from 'backend'
import { DataTable, defaultSelectFilter } from 'bold-ui'
import React, { useState } from 'react'
import { degrees, supposedDates } from '../../../types/enums'

export interface SupposedGraduationTableProps {
  dto: GraduationReportDto[]
  loading: boolean
  filter: string
  supposedFilter: string
  teamFilter: SelectTeamDto
}

export function SupposedGraduationTable(props: SupposedGraduationTableProps) {
  const { dto, loading, filter, supposedFilter, teamFilter } = props

  const [sort, setSort] = useState(['collaboratorName'])
  const handleSortChange = (s: string[]) => setSort(s)

  const rows = (dto || []).sort((a, b) => {
    if (sort[0] === 'collaboratorName') {
      return a.collaboratorName < b.collaboratorName ? -1 : 1
    }

    if (sort[0] === '-collaboratorName') {
      return a.collaboratorName > b.collaboratorName ? -1 : 1
    }

    if (sort[0] === 'supposedDate') {
      return supposedDates[a.supposedDate] < supposedDates[b.supposedDate] ? -1 : 1
    }

    if (sort[0] === '-supposedDate') {
      return supposedDates[a.supposedDate] > supposedDates[b.supposedDate] ? -1 : 1
    }

    if (sort[0] === 'teamName') {
      return a.teamName < b.teamName ? -1 : 1
    }

    if (sort[0] === '-teamName') {
      return a.teamName > b.teamName ? -1 : 1
    }

    if (sort[0] === 'degree') {
      return degrees[a.degree] < degrees[b.degree] ? -1 : 1
    }

    if (sort[0] === '-degree') {
      return degrees[a.degree] > degrees[b.degree] ? -1 : 1
    }
    return 0
  })

  const filteredRows = defaultSelectFilter(rows, filter, item => item.collaboratorName)
    .filter(item => (supposedFilter ? item.supposedDate === supposedFilter : true))
    .filter(item => (teamFilter ? teamFilter.name === item.teamName : true))

  return (
    <DataTable
      loading={loading}
      rows={filteredRows}
      sort={sort}
      onSortChange={handleSortChange}
      columns={[
        {
          name: 'collaboratorName',
          header: 'Nome',
          sortable: true,
          render: item => item.collaboratorName,
          style: { minWidth: '400px' },
        },
        {
          name: 'teamName',
          header: 'Equipe',
          sortable: true,
          render: item => (item && item.teamName !== '' ? item.teamName : '-'),
        },
        {
          name: 'degree',
          header: 'Grau Acadêmico',
          sortable: true,
          render: item => degrees[item.degree],
        },
        {
          name: 'supposedDate',
          header: 'Previsão de Conclusão',
          sortable: true,
          render: item => (item.supposedDate ? supposedDates[item.supposedDate] : '-'),
        },
      ]}
    />
  )
}
