import { SupposedDateEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { supposedDates } from '../../../../types/enums'

export interface SupposedDateSelectFieldProps {
  label: string
  name: string
  required: boolean
}

export const SupposedDateSelectField = (props: SupposedDateSelectFieldProps) => {
  const { required, ...rest } = props

  const itemToString = (item: string) => {
    return supposedDates[item]
  }
  const items = Object.keys(supposedDates) as SupposedDateEnum[]
  return <SelectField<SupposedDateEnum> items={items} itemToString={itemToString} required={required} {...rest} />
}
