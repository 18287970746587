import { Flow, HFlow, Text, Theme, useStyles, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { ReactComponent as ArrowRight } from 'images/arrow_right.svg'
import { ReactComponent as Logo } from 'images/b.svg'
import googleIcon from 'images/google_icn.png'
import { ReactComponent as LoginImage } from 'images/login_image.svg'
import { ReactComponent as MeuBridgeLogo } from 'images/meubridge-logo.svg'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

function OauthLoginView(props: RouteComponentProps) {
  const { location } = props

  useEffect(() => {
    if (location.pathname === '/loginerror') {
      alert('danger', 'Você não possui acesso ao Meu bridge com este endereço de e-mail.')
    }
  }, [location.pathname])

  const { classes } = useStyles(createStyles)

  return (
    <HFlow hSpacing={0}>
      <Flow justifyContent='flex-end' alignItems='center' style={classes.leftContainer}>
        <LoginImage />
      </Flow>
      <VFlow justifyContent='center' vSpacing={1.75} style={classes.rightContainer}>
        <VFlow>
          <Text style={classes.welcomeText}> Oi! Bem-vindo ao </Text>
          <div className={classes.logo}>
            <MeuBridgeLogo className={classes.logoSvg} width={300} />
          </div>
        </VFlow>
        <a href='/oauth2/authorization/google'>
          <HFlow alignItems='center' justifyContent='space-between' style={classes.loginButton}>
            <HFlow alignItems='center' hSpacing={1.5}>
              <img className={classes.googleIcn} src={googleIcon} />
              <Text> Login com Google </Text>
            </HFlow>
            <ArrowRight className={classes.arrowRight} width={24} />
          </HFlow>
        </a>
      </VFlow>
      <Logo className={classes.logoB} />.
    </HFlow>
  )
}

const createStyles = (theme: Theme) => {
  return {
    logo: {
      flexGrow: 1,
      img: {
        height: '2rem',
        width: '4.5rem',
      },
    } as React.CSSProperties,
    logoSvg: {
      '#meu, #bridge': {
        fill: `${theme.pallete.text.main} !important`,
      },
    } as React.CSSProperties,
    leftContainer: {
      background: theme.pallete.surface.main,
      width: '54vw',
      height: '100vh',
      paddingRight: '3.125rem',
    },
    rightContainer: {
      boxShadow: `inset 11px 0px 15px -5px ${theme.pallete.divider}`,
      width: '46vw',
      height: '100vh',
      paddingLeft: '3.5rem',
      background: theme.pallete.surface.main,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3rem bottom 2rem',
      a: {
        textDecoration: 'none',
        color: theme.pallete.text.main,
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    loginButton: {
      background: theme.pallete.surface.main,
      width: '21.5625rem',
      height: '4rem',
      paddingLeft: '2rem',
      paddingRight: '1.5rem',
      border: `solid 1px ${theme.pallete.divider}`,
      borderRadius: '0.25rem',
      marginTop: '1.75rem',
    },
    welcomeText: {
      color: theme.pallete.text.secondary,
      fontSize: '24px',
    },
    googleIcn: {
      paddingTop: '0.25rem',
      width: '2rem',
      height: '2rem',
    },
    arrowRight: {
      paddingTop: '0.5rem',
      '#seta': {
        fill: `${theme.pallete.text.main} !important`,
      },
    },
    logoB: {
      '#b': {
        fill: `${theme.pallete.text.main} !important`,
      },
      position: 'absolute',
      bottom: '20px',
      right: '40px',
    } as React.CSSProperties,
  }
}

export default withRouter(OauthLoginView)
