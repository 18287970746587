import { RoleEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { roles } from 'types/enums'

export interface ProfileSelectedFieldProps {
  label: string
  required: boolean
  name: string
  disabled: boolean
}

export const ProfileSelectedField = (props: ProfileSelectedFieldProps) => {
  const { ...rest } = props

  const itemToString = (item: RoleEnum) => {
    return roles[item]
  }

  const items = Object.keys(roles) as RoleEnum[]
  return <SelectField<RoleEnum> items={items} itemToString={itemToString} {...rest} />
}
