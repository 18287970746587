import { connect } from 'react-redux'
import { Role } from 'utils/RoleUtils'
import { MenuBar } from 'view/MenuBar'

const getRoleText = state => {
  if (state.auth.user && state.auth.user.roles) {
    const currentRole = state.auth.user.roles.filter(role => role !== 'ROLE_USER').pop()
    if (currentRole === Role.ADMINISTRATOR) {
      return 'ADMIN'
    }
    if (currentRole === Role.LEADER) {
      return 'LÍDER'
    }
  }
  return ''
}

export const mapStateToProps = state => {
  return {
    roleText: getRoleText(state),
  }
}

export const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuBar)
