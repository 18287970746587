import { AbsenceType, ActivityIntervalDto, DailyActivityRowItem } from 'backend'
import moment from 'moment'
import { isNull } from 'util'
import { isValidTime } from 'utils/TimeUtils'

export type NumberSignal = 'ZERO' | 'NEGATIVE' | 'POSITIVE'

export const calculateTotalDailyAmount = (activities: ActivityIntervalDto[]) => {
  const totalTime: moment.Duration = moment.duration()
  for (const activity of activities) {
    if (
      activity &&
      activity.startTime &&
      activity.endTime &&
      isValidTime(activity.startTime) &&
      isValidTime(activity.endTime)
    ) {
      totalTime.add(moment.duration(activity.endTime).subtract(moment.duration(activity.startTime)))
    }
  }
  return totalTime
}

export const calculateBalance = (
  expected: moment.Duration,
  done: moment.Duration,
  ignoredExpectedTime?: boolean,
  substractAbsenceTime?: moment.Duration
) => {
  if (!expected) {
    return done
  }
  if (ignoredExpectedTime) {
    expected = moment.duration(0)
  } else {
    expected = moment.duration(expected).subtract(substractAbsenceTime)
  }
  return moment.duration(done).subtract(moment.duration(expected))
}

export const shouldIgnoreExpectedTime = (dailyRegister: DailyActivityRowItem) => {
  return (
    dailyRegister.isAbsence !== null ||
    (dailyRegister.absenceType !== null && dailyRegister.absenceType !== 'ABSENCE_REPORTED')
  )
}

export const substractAbsenceExpectedTime = (absenceHours: string) => {
  if (absenceHours !== null) {
    return moment.duration(absenceHours)
  }
  return moment.duration(0)
}

export const calculatePeriodAmount = (dailyRegisters: DailyActivityRowItem[]) => {
  const done: moment.Duration = moment.duration()
  const expected: moment.Duration = moment.duration()
  if (dailyRegisters && dailyRegisters.length > 0) {
    for (const dailyRegister of dailyRegisters) {
      if (dailyRegister.expectedDailyTimeAmount) {
        if (shouldIgnoreExpectedTime(dailyRegister)) {
          expected.add(0)
        } else {
          expected.add(
            moment
              .duration(dailyRegister.expectedDailyTimeAmount)
              .subtract(substractAbsenceExpectedTime(dailyRegister.absenceHours))
          )
        }
      }
      if (dailyRegister.activityIntervals.length > 0) {
        done.add(moment.duration(calculateTotalDailyAmount(dailyRegister.activityIntervals)))
      }
    }
  }
  return {
    periodAmount: done,
    expectedPeriodAmount: expected,
  }
}

export const calculateWeeksPeriodAmount = (weeks: DailyActivityRowItem[][]) => {
  const done: moment.Duration = moment.duration()
  const expected: moment.Duration = moment.duration()
  if (weeks && weeks.length > 0) {
    for (const week of weeks) {
      const { periodAmount, expectedPeriodAmount } = calculatePeriodAmount(week)
      done.add(periodAmount)
      expected.add(expectedPeriodAmount)
    }
  }
  return {
    periodAmount: done,
    expectedPeriodAmount: expected,
  }
}

export const calculatePercent = (expected: moment.Duration, done: moment.Duration) => {
  let percent = 0
  if (expected && done && expected.asMinutes() > 0) {
    percent = done.asMinutes() / expected.asMinutes()
  }
  return Math.min(percent * 100, 100)
}

export const isDayWork = (dailyRegister: DailyActivityRowItem) => {
  if (isNull(dailyRegister)) {
    return false
  }
  if (isNull(dailyRegister.expectedDailyTimeAmount)) {
    return false
  }
  return true
}

export const isValidJustification = (type: AbsenceType) => {
  return new Set(['ABSENCE_REPORTED', 'MISSING_PAYMENT', 'MEDICAL_CERTIFICATE']).has(type)
}

export const getDurationSignal = (dur: moment.Duration): NumberSignal => {
  if (dur.asMinutes() > 0) {
    return 'POSITIVE'
  }
  if (dur.asMinutes() === 0) {
    return 'ZERO'
  }
  return 'NEGATIVE'
}
