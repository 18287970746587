import {
  extractInputProps,
  Field,
  FieldRenderProps,
  getFieldError,
  TimeFieldProps as BoldTimeFieldProps,
  TimeInput,
  Tooltip,
  useTheme,
} from 'bold-ui'
import React from 'react'

interface TimeFieldProps extends BoldTimeFieldProps {
  convertvalue?(value: string): string
}

export function TimeField({ convertvalue, ...rest }: TimeFieldProps) {
  const theme = useTheme()
  const renderInput = (formProps: FieldRenderProps<any>) => {
    return (
      <Tooltip
        text={getFieldError(formProps)}
        placement='top-start'
        style={{
          background: theme.pallete.status.danger.main,
          color: theme.pallete.status.danger.onColor,
        }}
      >
        <TimeInput
          style={{
            width: '3rem',
            height: '2rem',
            padding: 'calc(0.5rem - 1px) 0.15rem',
            textAlign: 'center',
          }}
          {...extractInputProps(rest)}
          {...formProps.input as any}
          status={formProps.hasError ? 'error' : undefined}
          autoComplete='off'
          clearable={false}
        />
      </Tooltip>
    )
  }

  const format = (value: string) => {
    // Omit seconds in case the initial field value is in format 'HH:mm:ss'
    return value ? value.slice(0, 5) : value
  }

  return <Field {...rest} render={renderInput} format={convertvalue ? convertvalue : format} hasWrapper={false} />
}
