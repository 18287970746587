import { Icon as BoldIcon, IconProps, Theme, useStyles } from 'bold-ui'
import React from 'react'

export function Icon(props: IconProps) {
  const { classes } = useStyles(createStyles)
  const { style, ...rest } = props
  return <BoldIcon {...rest} style={classes.icon} />
}

const createStyles = (theme: Theme) => ({
  icon: {
    color: theme.pallete.gray.c40,
  } as React.CSSProperties,
})
