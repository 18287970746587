import { Button, HFlow, Text, Theme, Tooltip, useStyles } from 'bold-ui'
import { Icon } from 'components/Icon'
import { IconButton } from 'components/IconButton'
import { useThemeContext } from 'config/theme'
import { ReactComponent as MeuBridgeLogo } from 'images/meubridge-logo.svg'
import React from 'react'

export interface UserBarProps {
  userName: string
  userTeam: string
  userImgLink: string

  logout(): any
}

export function UserBar(props: UserBarProps) {
  const { classes } = useStyles(createStyles)
  const { toggleTheme } = useThemeContext()

  const handleSwitchThemeClick = () => toggleTheme()

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <MeuBridgeLogo className={classes.logoSvg} width={129} height={25} />
      </div>

      <HFlow style={classes.user}>
        <Text tag='p'>{props.userName}</Text>
        <img src={props.userImgLink} className={classes.photo} />
      </HFlow>
      <HFlow style={classes.lightBulbo}>
        <Tooltip text='Dark Mode'>
          <Button skin='ghost' size='small' onClick={handleSwitchThemeClick}>
            <Icon icon='lightbulbFilled' />
          </Button>
        </Tooltip>
      </HFlow>
      <HFlow style={classes.logout}>
        <Tooltip text='Logout'>
          <IconButton size='small' icon='openDoor' onClick={props.logout} />
        </Tooltip>
      </HFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 4rem',
    height: '4rem',
    background: theme.pallete.surface.main,
    color: theme.pallete.text.main,
  } as React.CSSProperties,
  logo: {
    flexGrow: 1,
    img: {
      height: '2rem',
      width: '4.5rem',
    },
  } as React.CSSProperties,
  logoSvg: {
    '#meu, #bridge': {
      fill: `${theme.pallete.text.main} !important`,
    },
  } as React.CSSProperties,
  user: {
    borderRight: `1px solid ${theme.pallete.divider}`,
    paddingRight: '1rem',
    height: '4rem',
    alignItems: 'center',
  } as React.CSSProperties,
  photo: {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    borderRadius: '0.25rem',
  } as React.CSSProperties,
  logout: {
    paddingLeft: '0.5rem',
  } as React.CSSProperties,
  lightBulbo: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    height: '4rem',
    alignItems: 'center',
  } as React.CSSProperties,
})
