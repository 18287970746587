import { ValidationMessages } from './index'

export const messages: ValidationMessages = {
  beforeEqual130Years: (value, ...args) => `Deve ser anterior ou igual a 130 anos.`,
  beforeEqualToday: (value, ...args) => `Deve ser anterior ou igual à data atual.`,
  empty: (value, ...args) => `Não deve ser preenchido.`,
  invalid: (value, ...args) => `Campo inválido.`,
  length: (value, ...args) => `Deve possuir ${args[0]} caracteres.`,
  minLength: (value, ...args) => `Deve possuir ao menos ${args[0]} caracteres.`,
  maxLength: (value, ...args) => `Deve possuir no máximo ${args[0]} caracteres.`,
  match: (value, ...args) => `Um ou mais caracteres informados não são permitidos para esse campo.`,
  nome: (value, ...args) => `Informe nome e sobrenome.`,
  nomeInvalido: (value, ...args) => `Deve conter apenas letras (acentuadas ou não).`,
  nomeOneLetter: (value, ...args) => `Nome e sobrenome não podem ter somente uma letra.`,
  nomeTwoLetters: (value, ...args) => `Nome e sobrenome não podem ter apenas duas letras.`,
  range: (value, ...args) => `Deve ter valor entre ${args[0]} e ${args[1]}.`,
  minRange: (value, ...args) => `Deve ser maior ou igual a ${args[0]}.`,
  maxRange: (value, ...args) => `Deve ser menor ou igual a ${args[0]}.`,
  required: (value, ...args) => `Preenchimento obrigatório.`,
  senha: (value, ...args) => `Deve possuir ao menos uma letra e um número.`,
  senhaSpecialChars: (value, ...args) => `Deve possuir apenas letras (não acentuadas) e números.`,
}
