import api from 'api'
import { AddressDto, ProfileDto } from 'backend'
import { Cell, Grid, Heading, metaPath, NumberField, TextField, useStyles, VFlow } from 'bold-ui'
import * as React from 'react'
import { useEffect } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useRequester } from 'requester/useRequester'
import { profileStyles } from 'view/profile/profileStyles'

interface AddressFieldGroupProps {
  formProps: FormRenderProps
}

const meta = metaPath<ProfileDto>()

export function AddressFieldGroup(props: AddressFieldGroupProps) {
  const { classes } = useStyles(profileStyles)
  const { formProps } = props
  const { request: addressRequest, setParams, result: address } = useRequester<AddressDto>(api.address.load)

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setParams(event.currentTarget.value)
    addressRequest()
  }

  useEffect(() => {
    if (address) {
      formProps.form.change(meta.address.city.absolutePath(), address.city)
      formProps.form.change(meta.address.street.absolutePath(), address.street)
      formProps.form.change(meta.address.hood.absolutePath(), address.hood)
    }
  }, [address])

  return (
    <VFlow>
      <Heading style={classes.title} level={2}>
        Endereço
      </Heading>
      <div>
        <Grid wrap>
          <Cell size={3}>
            <NumberField
              label='CEP'
              name={`address.postalCode`}
              icon='zoomOutline'
              onBlurCapture={handleBlur}
              maxLength={8}
              required={true}
            />
          </Cell>
          <Cell size={3} />
          <Cell size={6}>
            <TextField label='Logradouro' name={`address.street`} required maxLength={45} />
          </Cell>
          <Cell size={6}>
            <TextField label='Município' name={`address.city`} required maxLength={45} />
          </Cell>
          <Cell size={3}>
            <TextField label='Nº' name={`address.number`} required maxLength={7} />
          </Cell>
          <Cell size={3}>
            <TextField label='Complemento' name={`address.addOn`} maxLength={45} />
          </Cell>
          <Cell size={6}>
            <TextField label='Bairro' name={`address.hood`} required maxLength={45} />
          </Cell>
        </Grid>
      </div>
    </VFlow>
  )
}
