import { AbsenceDto, AbsenceFilter } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import VacationRequester from 'store/requesters/VacationRequester'
import { inclusiveDiff } from 'utils/TimeUtils'

import VacationCreateView from './VacationCreateView'

export interface OwnProps extends RouteComponentProps<any> {
    onSubmitSucceeded(): void
}

const resolveResult = (value) => {
    if (!value || !value[0]) {
        return null
    }
    const result = value[0]
    if (result.startDate && result.endDate) {
        result.days = inclusiveDiff(result.endDate, result.startDate)
    }
    return result
}

export const mapStateToProps = (state: any) => ({
    editMode: true,
    vacation: resolveResult(VacationRequester.list.getResult(state)),
})

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
    load: () => {
        const filter = {
            id: ownProps.match.params.vacationId,
        } as AbsenceFilter
        dispatch(VacationRequester.list.setParams(filter))
        return dispatch(VacationRequester.list.request())
    },
    save: (dto: AbsenceDto) => {
        dispatch(VacationRequester.edit.setParams(dto))
        return dispatch(VacationRequester.edit.request())
    },
    delete: (id: Number) => {
        dispatch(VacationRequester.delete.setParams(id))
        return dispatch(VacationRequester.delete.request())
    },
    goBack() {
        ownProps.onSubmitSucceeded()
        ownProps.history.push(`/vacation`)
    },
})

export default withRouter(connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps)(VacationCreateView))
