import { DailyActivityRowItem } from 'backend'
import { HFlow, MonthPickerInput, ReferenceMonth, Tooltip, useStyles, VFlow } from 'bold-ui'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import DailyRegisterHistoryWeeklyTableRead from 'view/dailyregister/history/DailyRegisterHistoryWeeklyTableRead'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'

import { getDateFromUrl } from './utils/DailyRegisterHistoryUtils'

interface DailyRegisterHistoryTableReadViewProps {
  weeks: DailyActivityRowItem[][]
  month: ReferenceMonth
  readOnly: boolean
  onMonthChange(monthFromComponent: ReferenceMonth): any
}

export function DailyRegisterHistoryTableReadView(props: DailyRegisterHistoryTableReadViewProps) {
  const { readOnly, weeks, month, onMonthChange } = props
  const { location } = useRouter()
  const { theme } = useStyles()
  const style = createDailyTableStyles(theme)
  const date = getDateFromUrl(location.search)
  const dateUrl = `date=${date.year}-${date.month + 1}`

  if (!weeks) {
    return null
  }

  return (
    <VFlow hSpacing={0} style={style.container}>
      <VFlow hSpacing={0} style={style.tableHeader}>
        <HFlow justifyContent='space-between'>
          <MonthPickerInput value={month} onChange={onMonthChange} clearable={false} />
          {!readOnly && (
            <Tooltip text='Editar'>
              <ButtonLink to={`/dailyregister/history/edit?${dateUrl}`} skin='ghost' size='small'>
                <Icon icon='penFilled' />
              </ButtonLink>
            </Tooltip>
          )}
        </HFlow>
      </VFlow>
      {weeks.map((value, i) => (
        <DailyRegisterHistoryWeeklyTableRead key={i} weekNumber={i} values={value} />
      ))}
    </VFlow>
  )
}
