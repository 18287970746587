import { Heading, Tabs, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { Styles } from 'bold-ui/lib/styles/withStyles'
import { Container } from 'components/Container'
import { TabLink } from 'components/router/TabLink'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'

import DailyRegisterContainer from './DailyRegisterContainer'
import DailyRegisterHistoryContainer from './history/DailyRegisterHistoryContainer'

export interface DailyRegisterRootViewProps extends RouteComponentProps<any>, WithStylesProps {}

@withStyles
class DailyRegisterRootView extends React.Component<DailyRegisterRootViewProps> {
  render() {
    const { theme, match } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        color: theme.pallete.text.main,
        height: '9rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
      },
      headerContainer: {
        paddingTop: '3rem',
        borderBottom: `1px solid ${theme.pallete.divider}`,
        ul: {
          margin: '0rem',
        },
      },
      contentContainer: {
        paddingTop: '1rem',
      },
    }

    return (
      <>
        <Container style={styles.headerContainer}>
          <VFlow vSpacing={1.5}>
            <Heading level={1}> Registro diário</Heading>
            <Tabs>
              <TabLink to='/dailyregister/current'>Semana atual</TabLink>
              <TabLink to='/dailyregister/history'>Registros anteriores</TabLink>
            </Tabs>
          </VFlow>
        </Container>

        <Container style={styles.contentContainer}>
          <Switch>
            <Route path={`${match.url}/current`} component={DailyRegisterContainer} />
            <Route path={`${match.url}/history`} component={DailyRegisterHistoryContainer} />
            <Redirect exact from={`${match.url}`} to={`${match.url}/current`} />
          </Switch>
        </Container>
      </>
    )
  }
}

export default DailyRegisterRootView
