import { Heading } from 'bold-ui'
import React from 'react'

export interface ForbiddenViewProps {}

export class ForbiddenView extends React.Component<ForbiddenViewProps> {
  render() {
    return <Heading level={1}> Access denied. </Heading>
  }
}
