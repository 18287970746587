import api from 'api'
import { ProfileDto } from 'backend'
import { Tabs, Theme, useStyles, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import { TabLink } from 'components/router/TabLink'
import { useRouter } from 'hooks/useRouter'
import React, { useEffect } from 'react'
import { useRequester } from 'requester/useRequester'
import ProfileContent from 'view/profile/ProfileContent'
import { ProfileEditView } from 'view/profile/ProfileEditView'

import { CollaboratorDailyRegister } from './CollaboratorDailyRegister'
import CollaboratorDataHeader from './CollaboratorDataHeader'
import { Role } from 'utils/RoleUtils'
import CheckRole from 'view/user/CheckRole'
import { useRoles } from 'hooks/useRoles'
import { GpSwitch } from 'components/router/GpSwitch'
import PrivateRoute from 'view/user/PrivateRoute'

export function CollaboratorDataRootView() {
  const { match, history, location } = useRouter()
  const { classes } = useStyles(createStyles)
  const userId = match.params.userId
  const goBackurl: string = `/collaborator/data/${userId}/profile`
  const roles = useRoles()

  const getRequesterByRole = () => {
    if (roles.includes(Role.ADMINISTRATOR)) {
      return api.profile.loadProfileAndAdmData
    } else if (roles.includes(Role.LEADER)) {
      return api.profile.loadProfileById
    }
  }

  const { request, setParams, result } = useRequester<ProfileDto, number>(getRequesterByRole())

  const onSubmit = (value: ProfileDto) => {
    value.id = userId
    value.accounts[0].primary = true
    return api.profile.update(value)
  }

  const handleSuccess = () => {
    history.push(goBackurl)
  }

  useEffect(() => {
    setParams(userId)
    request()
  }, [location.pathname, userId])

  const renderProfileEditView = () => (
    <ProfileEditView onSubmit={onSubmit} handleSuccess={handleSuccess} profileDto={result} goBackUrl={goBackurl} />
  )

  const renderProfileContent = () => {
    return <ProfileContent profileDto={result} />
  }

  const renderDailyRegister = () => {
    return <CollaboratorDailyRegister userId={userId} />
  }

  const Header = () => {
    if (location.pathname.includes('edit')) {
      return null
    }
    return (
      <>
        <div className={classes.header}>
          <CollaboratorDataHeader
            profileDto={result}
            url={`/collaborator/data/${userId}/profile/edit`}
            showEditButton={!location.pathname.includes('dailyregister')}
          />
        </div>
        <VFlow vSpacing={1.25} style={classes.tabs}>
          <Tabs>
            <CheckRole roles={[Role.ADMINISTRATOR]}>
              <TabLink to={`/collaborator/data/${userId}/profile`}>Dados pessoais</TabLink>
            </CheckRole>
            <TabLink to={`/collaborator/data/${userId}/dailyregister`}>Registro diário</TabLink>
          </Tabs>
        </VFlow>
      </>
    )
  }

  return (
    <>
      <Container style={classes.container}>
        <VFlow>
          <Header />
          <GpSwitch>
            <PrivateRoute
              exact
              path={`${match.url}/dailyregister`}
              render={renderDailyRegister}
              roles={[Role.ADMINISTRATOR, Role.LEADER]}
            />
            <PrivateRoute
              exact
              path={`${match.url}/profile`}
              render={renderProfileContent}
              roles={[Role.ADMINISTRATOR]}
            />
            <PrivateRoute
              exact
              path={`${match.url}/profile/edit`}
              render={renderProfileEditView}
              roles={[Role.ADMINISTRATOR]}
            />
          </GpSwitch>
        </VFlow>
      </Container>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  tabs: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
  } as React.CSSProperties,
  container: {
    paddingBottom: `2rem`,
  } as React.CSSProperties,
  header: {
    padding: `2rem 0rem`,
  } as React.CSSProperties,
})
