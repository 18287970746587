import { Field, FieldRenderProps, HFlow, Tooltip } from 'bold-ui'
import { TimeField } from 'components/time/TimeField'
import React from 'react'

export interface StartEndFieldIntervalProps {
  name: string
  disabled?: boolean
}

class StartEndFieldInterval extends React.Component<StartEndFieldIntervalProps> {
  render() {
    return (
      <div>
        <Field name={this.props.name} render={this.renderTimes} hasWrapper={false} />
      </div>
    )
  }

  renderTimes = (fieldProps: FieldRenderProps) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center' justifyContent='center'>
        <Tooltip text={this.props.disabled ? 'Não há horário semanal para este dia' : null}>
          <TimeField name={`${fieldProps.input.name}.startTime`} disabled={this.props.disabled} />
        </Tooltip>
        <Tooltip text={this.props.disabled ? 'Não há horário semanal para este dia' : null}>
          <TimeField name={`${fieldProps.input.name}.endTime`} disabled={this.props.disabled} />
        </Tooltip>
      </HFlow>
    )
  }
}

export default StartEndFieldInterval
