import { HolidayDto } from 'backend'

export enum Holiday {
  HOLIDAY = 'Feriado',
  ABSENCE = 'Dia abonado',
}

export const description = (holiday: HolidayDto) => {
  if (holiday.absence) {
    return Holiday.ABSENCE
  } else {
    return Holiday.HOLIDAY
  }
}
