import { Button, ButtonProps } from 'bold-ui'
import { ButtonBaseRenderProps } from 'bold-ui/lib/components/Button/ButtonBase'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, 'to' | 'replace'> {}

export function ButtonLink(props: ButtonLinkProps) {
  const { to, replace, style, ...rest } = props

  const linkStyle = {
    textDecoration: 'none',
  }

  const renderLink = (buttonProps: ButtonBaseRenderProps) => {
    const {
      type, // remove 'type="button"'
      disabled, // `<a>` tag does not support the 'disabled' prop
      ...other
    } = buttonProps

    return (
      <Link
        {...other as any}
        to={to}
        replace={replace}
        aria-disabled={disabled === true ? true : undefined}
        tabIndex={disabled ? -1 : buttonProps.tabIndex}
      />
    )
  }

  return <Button {...rest} style={[linkStyle, style]} render={renderLink} />
}
