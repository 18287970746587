import { DegreesEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { degrees } from 'types/enums'

export interface DegreeSelectFieldProps {
  label: string
  name: string
}

export const DegreeSelectField = (props: DegreeSelectFieldProps) => {
  const { ...rest } = props

  const itemToString = (item: DegreesEnum) => {
    return degrees[item]
  }

  const items = Object.keys(degrees) as DegreesEnum[]
  return <SelectField<DegreesEnum> items={items} itemToString={itemToString} {...rest} required />
}
