import { ScheduleIntervalDto } from 'backend'
import { TableCell, TableRow, withStyles, WithStylesProps } from 'bold-ui'
import React from 'react'
import { createTableStyles } from 'view/schedule/table/tableStyles'
import StartEndTextInterval from 'view/schedule/table/visualize/StartEndTextInterval'

export interface StartEndTextIntervalRowProps extends WithStylesProps {
  row: number
  intervals: { [index: string]: ScheduleIntervalDto[] }
}

// tslint:disable no-string-literal

@withStyles
class StartEndTextIntervalRow extends React.Component<StartEndTextIntervalRowProps> {
  render() {
    const { intervals, row, theme } = this.props

    if (!intervals) {
      return null
    }

    const styles = createTableStyles(theme)

    if (intervals && this.hasAtLeastOneInterval(intervals, row)) {
      return (
        <TableRow style={styles.row}>
          <TableCell>
            <StartEndTextInterval
              startTime={intervals['MONDAY'][row] ? intervals['MONDAY'][row].startTime : null}
              endTime={intervals['MONDAY'][row] ? intervals['MONDAY'][row].endTime : null}
            />
          </TableCell>
          <TableCell>
            <StartEndTextInterval
              startTime={intervals['TUESDAY'][row] ? intervals['TUESDAY'][row].startTime : null}
              endTime={intervals['TUESDAY'][row] ? intervals['TUESDAY'][row].endTime : null}
            />
          </TableCell>
          <TableCell>
            <StartEndTextInterval
              startTime={intervals['WEDNESDAY'][row] ? intervals['WEDNESDAY'][row].startTime : null}
              endTime={intervals['WEDNESDAY'][row] ? intervals['WEDNESDAY'][row].endTime : null}
            />
          </TableCell>
          <TableCell>
            <StartEndTextInterval
              startTime={intervals['THURSDAY'][row] ? intervals['THURSDAY'][row].startTime : null}
              endTime={intervals['THURSDAY'][row] ? intervals['THURSDAY'][row].endTime : null}
            />
          </TableCell>
          <TableCell>
            <StartEndTextInterval
              startTime={intervals['FRIDAY'][row] ? intervals['FRIDAY'][row].startTime : null}
              endTime={intervals['FRIDAY'][row] ? intervals['FRIDAY'][row].endTime : null}
            />
          </TableCell>
        </TableRow>
      )
    }
    return null
  }

  hasAtLeastOneInterval(intervals, row) {
    return (
      intervals['MONDAY'][row] ||
      intervals['TUESDAY'][row] ||
      intervals['WEDNESDAY'][row] ||
      intervals['THURSDAY'][row] ||
      intervals['FRIDAY'][row]
    )
  }
}

export default StartEndTextIntervalRow
