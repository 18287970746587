import { CollaboratorDto } from 'backend'
import { Form, Heading, SelectField, Styles, Tabs, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { Container } from 'components/Container'
import { TabLink } from 'components/router/TabLink'
import { css } from 'emotion'
import React from 'react'

export interface CollaboratorHeaderProps extends WithStylesProps {
  searchOptions: CollaboratorDto[]
  onChangeSearch: any
}

@withStyles
class CollaboratorHeader extends React.Component<CollaboratorHeaderProps> {
  render() {
    const styles: Styles = {
      select: {
        color: this.props.theme.pallete.text.main,
      },
    }
    return (
      <Container>
        <VFlow vSpacing={1.25}>
          <Heading level={1}>Colaboradores</Heading>
          <div className={css(styles.select)}>
            <Form render={this.renderSelectField} />
          </div>
          <Tabs>
            <TabLink to='/collaborator/schedule'>Horário semanal</TabLink>
            <TabLink to='/collaborator/vacation'>Férias</TabLink>
          </Tabs>
        </VFlow>
      </Container>
    )
  }

  private renderSelectField = () => {
    return (
      <SelectField
        id='collaborator'
        name='collaborator'
        placeholder='Busque por um colaborador'
        items={this.props.searchOptions}
        itemToString={this.itemToString}
        itemIsEqual={this.itemIsEqual}
        onChange={this.props.onChangeSearch}
        multiple
      />
    )
  }

  private itemToString = (option: CollaboratorDto) => option && option.name
  private itemIsEqual = (a: CollaboratorDto, b: CollaboratorDto) => a.id === b.id
}

export default CollaboratorHeader
