import { UserDto } from 'backend'

export enum Role {
  ADMINISTRATOR = 'ROLE_ADM',
  COLLABORATOR = 'ROLE_COLLAB',
  LEADER = 'ROLE_LEADER',
}

export const userContainsPrivileges = (user: UserDto, requiredRoles: string[]) => {
  return user && user.roles && user.roles.some(role => requiredRoles.includes(role))
}
