import api from 'api'
import { BaseFilter, ScheduleDto } from 'backend'
import { VFlow } from 'bold-ui'
import { EmptyContainer } from 'components/EmptyContainer'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Route, RouteComponentProps } from 'react-router'
import { useRequester } from 'requester/useRequester'
import { DATE_ISO } from 'utils/DateUtils'

import ScheduleHistoryVisualizeView from './ScheduleHistoryVisualizeView'
import ScheduleModalEditContainer from './ScheduleModalEditContainer'

interface ScheduleHistoryViewProps extends RouteComponentProps<any> {}

export const ScheduleHistoryView = (props: ScheduleHistoryViewProps) => {
  const { match } = props

  const { request, setParams, result: schedules } = useRequester<ScheduleDto[]>(api.schedule.list)

  useEffect(() => {
    const filter = {
      toDate: moment()
        .subtract(1, 'day')
        .format(DATE_ISO),
    } as BaseFilter

    setParams(filter)
    request()
  }, [])

  const renderScheduleModalEditContainer = () => {
    return <ScheduleModalEditContainer editMode={true} onSubmitSucceeded={request} />
  }

  if (!schedules || schedules.length <= 0) {
    return <EmptyContainer message='Você ainda não possui horários anteriores...' hasButton={false} />
  }

  return (
    <VFlow>
      {schedules.map((value, i) => (
        <ScheduleHistoryVisualizeView key={i} schedule={value} />
      ))}
      <Route path={`${match.url}/:scheduleId(\\d+)/edit`} render={renderScheduleModalEditContainer} />
    </VFlow>
  )
}
