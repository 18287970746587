import { CollaboratorFilter, CollaboratorScheduleDto } from 'backend'
import { VFlow } from 'bold-ui'
import { EmptyContainer } from 'components/EmptyContainer'
import { isEqual } from 'lodash'
import React from 'react'
import { Page, PageParams } from 'requester/index'

import CollaboratorScheduleRowItem from './item/CollaboratorScheduleRowItem'

export interface CollaboratorScheduleViewProps {
  collaboratorsIds: number[]
  collaboratorsSchedules: CollaboratorScheduleDto[]
  load(ids: CollaboratorFilter): Promise<Page<CollaboratorScheduleDto>>
  clear(): void
}

class CollaboratorScheduleView extends React.Component<CollaboratorScheduleViewProps> {
  componentDidMount() {
    this.loadData(this.props)
  }

  componentWillReceiveProps(nextProps: CollaboratorScheduleViewProps) {
    if (!isEqual(this.props.collaboratorsIds, nextProps.collaboratorsIds)) {
      this.loadData(nextProps)
    }
  }

  render() {
    if (!this.props.collaboratorsSchedules) {
      return <EmptyContainer message='Selecione colaboradores para ver o horário semanal...' hasButton={false} />
    }

    return (
      <VFlow>
        {this.props.collaboratorsSchedules.map((item, key) => (
          <CollaboratorScheduleRowItem key={key} collaboratorSchedule={item} />
        ))}
      </VFlow>
    )
  }

  private loadData = (props: CollaboratorScheduleViewProps) => {
    const ids: number[] = props.collaboratorsIds

    if (ids && ids.length > 0) {
      const filter = {
        ids,
        page: 0,
        // pagination is a future work, but the back-end is almost done
        size: 150,
      } as CollaboratorFilter & PageParams
      props.load(filter)
    } else {
      props.clear()
    }
  }
}

export default CollaboratorScheduleView
