import { ScheduleIntervalDto } from 'backend'
import { HFlow, Icon, Styles, Text, Tooltip, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { ButtonLink } from 'components/router/ButtonLink'
import moment from 'moment'
import React from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import TotalScheduleAmount from 'view/schedule/table/TotalScheduleAmount'

export interface ScheduleTableVisualizeHeaderProps extends WithStylesProps {
  intervals: { [index: string]: ScheduleIntervalDto[] }
  startDate: string
  endDate: string
}

@withStyles
class ScheduleTableVisualizeHeader extends React.Component<ScheduleTableVisualizeHeaderProps> {
  render() {
    const { startDate, endDate, intervals, theme } = this.props

    const styles: Styles = {
      container: {
        background: theme.pallete.surface.main,
        borderBottom: `1px solid ${theme.pallete.divider}`,
        height: '5rem',
        padding: '0rem 1.5rem',
      },
    }

    return (
      <HFlow alignItems={'center'} justifyContent='space-between' style={styles.container}>
        <HFlow hSpacing={2.625}>
          <VFlow vSpacing={0.125}>
            <Text fontWeight='bold'>Início</Text>
            <Text color='secondary'> {this.getFormattedDate(startDate)} </Text>
          </VFlow>
          <VFlow vSpacing={0.125}>
            <Text fontWeight='bold'>Fim</Text>
            <Text color='secondary'> {this.getFormattedDate(endDate)} </Text>
          </VFlow>
          <VFlow vSpacing={0}>
            <Text fontWeight='bold'>Total semanal</Text>
            <TotalScheduleAmount color='secondary' intervals={intervals} />
          </VFlow>
        </HFlow>
        <HFlow alignItems='center' hSpacing={0.25}>
          <Tooltip text='Editar'>
            <ButtonLink skin='ghost' size='small' to={`/schedule/current/edit`}>
              <Icon icon='penFilled' />
            </ButtonLink>
          </Tooltip>
        </HFlow>
      </HFlow>
    )
  }

  getFormattedDate(date: string) {
    return date ? moment(date).format(DATE_PT_BR) : 'DD/MM/AAAA'
  }
}

export default ScheduleTableVisualizeHeader
