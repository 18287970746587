import { Styles, Theme, withStyles, WithStylesProps } from 'bold-ui'
import { Interpolation } from 'emotion'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

const createStyles = (theme: Theme): Styles => {
  return {
    ul: {
      listStyle: 'none',
      padding: '0',
      margin: '0 -1rem',
      fontSize: theme.typography.sizes.button,
    },
    a: {
      display: 'inline-block',
      color: theme.pallete.text.main,
      margin: '0 1rem',
      transition: '.2s color',

      '&.active': {
        color: theme.pallete.primary.main,
        borderRight: '2px solid currentColor',
      },
    },
  }
}

export interface VTabsProps extends WithStylesProps {
  style?: Interpolation
}

@withStyles
export class VTabs extends React.Component<VTabsProps> {
  render() {
    const { css, theme, children, style } = this.props
    const styles = createStyles(theme)

    return (
      <ul className={css(styles.ul, style)} role='verticaltablist'>
        {children}
      </ul>
    )
  }
}

export interface VTabLinkProps extends WithStylesProps, Pick<NavLinkProps, 'to' | 'replace' | 'exact'> {
  active?: boolean
}

@withStyles
export class VTabLink extends React.Component<VTabLinkProps> {
  static defaultProps: Partial<VTabLinkProps> = {
    active: false,
  }

  render() {
    const { css, theme, active, children, ...rest } = this.props
    const styles = createStyles(theme)

    return (
      <li className={css(styles.li)} role='presentation'>
        <NavLink className={css(styles.a)} isActive={this.isActive} role='tab' {...rest}>
          {children}
        </NavLink>
      </li>
    )
  }

  private isActive = (match, location) => {
    return this.props.active || match
  }
}
