import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'
import { RootState } from 'store/modules'
import { userContainsPrivileges } from 'utils/RoleUtils'

interface PrivateRouteProps extends RouteProps {
  roles: string[]
  navigate?: boolean
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
  render() {
    const { navigate, ...rest } = this.props
    if (navigate) {
      return <Route {...rest} />
    }
    return <Redirect to='/403' />
  }
}

export default connect((state: RootState, ownProps: PrivateRouteProps) => {
  return {
    ...ownProps,
    navigate: userContainsPrivileges(state.auth.user, ownProps.roles),
  }
})(PrivateRoute)
