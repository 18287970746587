import { DailyActivityRowItem } from 'backend'
import {
  HFlow,
  JustifyContent,
  Styles,
  TableCell,
  TableRow,
  Text,
  Theme,
  Tooltip,
  VFlow,
  withStyles,
  WithStylesProps,
} from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { isNull } from 'util'
import { DAY_MONTH, WEEKDAY_SHORT } from 'utils/DateUtils'
import { Holiday } from 'utils/HolidayUtils'
import { asPrettyTime } from 'utils/TimeUtils'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import {
  calculateBalance,
  calculateTotalDailyAmount,
  getDurationSignal,
  NumberSignal,
  shouldIgnoreExpectedTime,
  substractAbsenceExpectedTime,
} from 'view/dailyregister/utils/DailyRegisterUtils'
import StartEndTextInterval from 'view/schedule/table/visualize/StartEndTextInterval'

import { DailyRegisterPopper } from './DailyRegisterPopper'

export interface DailyRegisterRowVisualizeProps extends WithStylesProps {
  dailyRegister?: DailyActivityRowItem
}

@withStyles
class DailyRegisterRowVisualize extends React.Component<DailyRegisterRowVisualizeProps> {
  render() {
    const { dailyRegister, theme } = this.props

    if (!dailyRegister) {
      return null
    }

    const styles = createDailyTableStyles(theme)

    let totalDailyAmount = moment.duration(0)
    let balance = moment.duration(0)
    const ignoredExpectedTime: boolean = shouldIgnoreExpectedTime(dailyRegister)

    if (dailyRegister.activityIntervals.length > 0) {
      totalDailyAmount = calculateTotalDailyAmount(dailyRegister.activityIntervals)
    }

    if (!moment(dailyRegister.date).isAfter(moment())) {
      balance = calculateBalance(
        moment.duration(dailyRegister.expectedDailyTimeAmount),
        totalDailyAmount,
        ignoredExpectedTime,
        substractAbsenceExpectedTime(dailyRegister.absenceHours)
      )
    }

    const balanceStyle = getBalanceStyle(balance, theme) as Styles
    return (
      <TableRow>
        <TableCell style={styles.firstColumn}>
          <VFlow vSpacing={0} alignItems='flex-start'>
            <HFlow hSpacing={0.5}>
              <Text color='secondary' fontWeight='bold'>
                {moment(dailyRegister.date).format(DAY_MONTH)}
              </Text>
              <Text color='secondary'>
                {moment(dailyRegister.date)
                  .format(WEEKDAY_SHORT)
                  .replace('-feira', '')}
              </Text>
            </HFlow>
            <Text color='primary' fontWeight='bold'>
              {!isNull(dailyRegister.isAbsence) ? (dailyRegister.isAbsence ? Holiday.ABSENCE : Holiday.HOLIDAY) : null}
            </Text>
          </VFlow>
        </TableCell>
        <TableCell style={styles.timeField}>
          <StartEndTextInterval
            startTime={dailyRegister.activityIntervals[0] && dailyRegister.activityIntervals[0].startTime}
            endTime={dailyRegister.activityIntervals[0] && dailyRegister.activityIntervals[0].endTime}
          />
        </TableCell>
        <TableCell style={styles.timeField}>
          <StartEndTextInterval
            startTime={dailyRegister.activityIntervals[1] && dailyRegister.activityIntervals[1].startTime}
            endTime={dailyRegister.activityIntervals[1] && dailyRegister.activityIntervals[1].endTime}
          />
        </TableCell>
        <TableCell style={styles.timeField}>
          <StartEndTextInterval
            startTime={dailyRegister.activityIntervals[2] && dailyRegister.activityIntervals[2].startTime}
            endTime={dailyRegister.activityIntervals[2] && dailyRegister.activityIntervals[2].endTime}
          />
        </TableCell>
        <TableCell style={styles.buttons}>
          <HFlow hSpacing={0} justifyContent={this.getButtonsJustify(dailyRegister)}>
            {dailyRegister.noteText ? (
              <Tooltip text='Visualizar observação'>
                <DailyRegisterPopper
                  title='Observação'
                  contentHours={'Horas abonadas: ' + asPrettyTime(moment.duration(dailyRegister.absenceHours))}
                  contentText={dailyRegister.noteText}
                  icon='fileEmptyOutline'
                />
              </Tooltip>
            ) : null}
          </HFlow>
        </TableCell>
        <TableCell>
          <HFlow hSpacing={0.5} justifyContent='space-between' alignItems='center'>
            <Text color='secondary'> {asPrettyTime(totalDailyAmount)}</Text>
            <Text color='secondary' style={balanceStyle.text}>
              {asPrettyTime(balance, true)}
            </Text>
          </HFlow>
        </TableCell>
      </TableRow>
    )
  }

  getButtonsJustify = (dailyRegister: DailyActivityRowItem): JustifyContent => {
    if (dailyRegister.noteText && !dailyRegister.absenceType) {
      return 'flex-start'
    } else if (!dailyRegister.noteText && dailyRegister.absenceType) {
      return 'flex-end'
    }
    return 'space-between'
  }
}

export const getBalanceStyle = (balance: moment.Duration, theme: Theme): Styles => {
  const signal: NumberSignal = getDurationSignal(balance)
  switch (signal) {
    case 'NEGATIVE':
      return {
        text: {
          color: theme.pallete.status.danger.main,
          fontWeight: 'bold',
        },
      }
    case 'POSITIVE':
      return {
        text: {
          color: theme.pallete.primary.main,
          fontWeight: 'bold',
        },
      }
    default:
      return {
        text: {
          color: theme.pallete.text.main,
          fontWeight: 'normal',
        },
      }
  }
}

export default DailyRegisterRowVisualize
