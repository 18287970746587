import React from 'react'
import { Redirect, Switch } from 'react-router'

export const GpSwitch: React.SFC = props => {
  return (
    <Switch>
      {props.children}
      <Redirect to='/403' />
    </Switch>
  )
}
