import axios, { AxiosPromise } from 'axios'
import {
  BaseFilter,
  CollaboratorDto,
  CollaboratorFilter,
  CollaboratorScheduleDto,
  CollaboratorVacationDto,
  DailyActivityRowItem,
  ProfileDto,
} from 'backend'
import { Page } from 'requester/index'

export class Collaborator {
  fetchOne(params: BaseFilter): AxiosPromise<CollaboratorDto> {
    return axios.get('/api/collaborator/fetchOne', { params })
  }

  listAll(params: CollaboratorFilter): AxiosPromise<CollaboratorDto[]> {
    return axios.get('/api/collaborator/listAll', { params })
  }

  listSchedule(params: CollaboratorFilter): AxiosPromise<Page<CollaboratorScheduleDto>> {
    return axios.get('/api/collaborator/listSchedule', { params })
  }

  listVacation(params: CollaboratorFilter): AxiosPromise<CollaboratorVacationDto[]> {
    return axios.get('/api/collaborator/listVacation', { params })
  }

  listPublicVacation(params: CollaboratorFilter): AxiosPromise<CollaboratorVacationDto[]> {
    return axios.get('/api/collaborator/listPublicVacation', { params })
  }

  listDailyActivity(params: BaseFilter): AxiosPromise<DailyActivityRowItem[]> {
    return axios.get('/api/collaborator/listDailyActivity', { params })
  }

  edit(form: ProfileDto, userId: number): AxiosPromise<ProfileDto> {
    return axios.put('/api/collaborator' + userId, form)
  }
}
