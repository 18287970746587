import { Theme } from 'bold-ui'
import React from 'react'

export const profileStyles = (theme: Theme) => ({
  component: {
    border: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
  } as React.CSSProperties,
  container: {
    background: `${theme.pallete.surface.main}`,
    width: '928px',
    margin: '2.5rem auto',
  } as React.CSSProperties,
  header: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
    paddingBottom: '2rem',
    marginBottom: '2rem',
  } as React.CSSProperties,
  summary: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
    paddingBottom: '1.5rem',
  } as React.CSSProperties,
  telephone: {
    borderLeft: `1px solid ${theme.pallete.divider}`,
    marginTop: '2.9rem',
    height: '8rem',
  } as React.CSSProperties,
  photo: {
    width: '8rem',
    height: '8rem',
    objectFit: 'contain',
    borderRadius: '4rem',
  } as React.CSSProperties,
  footer: {
    borderTop: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
    textAlign: 'right',
    marginTop: '2rem',
  } as React.CSSProperties,
  checkbox: {
    marginTop: '1.6rem',
  } as React.CSSProperties,
  title: {
    marginTop: '1rem',
  },
  tag: {
    background: '#0099ff',
  } as React.CSSProperties,
  welcomeModal: {
    width: '38rem',
  } as React.CSSProperties,
})
