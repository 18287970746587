import { SelectCitizenShipDto } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'

export interface GentileSelectFieldProps {
  citizenShipDto: SelectCitizenShipDto[]
  label: string
  name: string
}

export const GentileSelectField = (props: GentileSelectFieldProps) => {
  const { citizenShipDto, ...rest } = props

  const itemToString = (item: SelectCitizenShipDto) => {
    return item && `${item.city} - ${item.state}`
  }

  return <SelectField<SelectCitizenShipDto> items={citizenShipDto} itemToString={itemToString} {...rest} required />
}
