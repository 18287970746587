import { AxiosError } from 'axios'
import { DailyActivityRowItem } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import { todayISO } from 'utils/DateUtils'

import TodayRegisterView from './TodayRegisterView'

export interface OwnProps {
  todayAmount: moment.Duration
  percent: number
  disabled?: boolean
  onValueChange(item: DailyActivityRowItem): void
  load(): any
  onSubmitSucceeded(): void
}

export const mapStateToProps = (state, ownProps: OwnProps) => ({
  dailyRegister: DailyRegisterRequester.fetchOne.getResult(state)
    ? DailyRegisterRequester.fetchOne.getResult(state)[0]
    : null,
  todayAmount: ownProps.todayAmount,
  percent: ownProps.percent,
  onValueChange: ownProps.onValueChange,
})

export const mapDispatchToProps = dispatch => ({
  save: (form: DailyActivityRowItem) => {
    form.date = todayISO()
    dispatch(DailyRegisterRequester.createOne.setParams([form]))
    return dispatch(DailyRegisterRequester.createOne.request()).catch((res: AxiosError) => {
      const errors = res.response.data.values[0]
      return Promise.reject({ response: { data: errors, status: 400 } })
    })
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TodayRegisterView)
