import {
  Heading,
  HFlow,
  Modal,
  ModalBody,
  ModalFooter,
  TextAreaField,
  VFlow,
  withStyles,
  WithStylesProps,
} from 'bold-ui'
import { TimeField } from 'components/time/TimeField'
import moment from 'moment'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { asPrettyTime } from 'utils/TimeUtils'
import SaveRemoveFooter from 'view/dailyregister/modal/SaveRemoveFooter'

export interface ObservationModalProps extends WithStylesProps {
  initialValue?: any
  isOpen: boolean
  onCloseOrBackdropClick(): void
  onSaveClick(value): void
  onRemoveClick(): void
}

@withStyles
class ObservationModal extends React.Component<ObservationModalProps> {
  render() {
    return <Form onSubmit={this.onSaveClick} render={this.renderModal} initialValues={this.props.initialValue} />
  }

  renderModal = (props: FormRenderProps) => {
    const initialValueIsNotEmpty = props.initialValues && props.initialValues.noteText
    const requiredNote = valueNote => (valueNote ? undefined : 'Campo obrigatório')

    const maxAbsenceHours = value =>
      moment.duration(value).as('ms') > moment.duration(this.props.initialValue.expectedDailyTimeAmount).as('ms')
        ? 'Hora abonada deve ser menor ou igual que a hora prevista para este dia'
        : undefined

    const durationToTime = value => asPrettyTime(moment.duration(value))

    return (
      <div>
        <Modal open={this.props.isOpen} size='small' onClose={this.onCloseOrBackdropClick(props)}>
          <ModalBody>
            <VFlow>
              <Heading level={1}>{initialValueIsNotEmpty ? 'Editar observação' : 'Adicionar observação'}</Heading>
              <Heading level={3}>Horas abonadas</Heading>

              <HFlow hSpacing={0.5}>
                <VFlow vSpacing={0.1}>
                  <TimeField name='absenceHours' convertvalue={durationToTime} validate={maxAbsenceHours} />
                </VFlow>
              </HFlow>

              <Heading level={3}>Observação</Heading>
              <TextAreaField rows={4} name={'noteText'} validate={requiredNote} maxLength={255} />
            </VFlow>
          </ModalBody>
          <ModalFooter>
            <SaveRemoveFooter
              editMode={initialValueIsNotEmpty}
              onSaveClick={props.handleSubmit}
              onRemoveClick={this.onRemoveClick(props)}
            />
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  onRemoveClick = (formProps: FormRenderProps) => () => {
    formProps.form.reset({})
    this.props.onRemoveClick()
    this.props.onCloseOrBackdropClick()
  }

  onSaveClick = value => {
    this.props.onSaveClick(value)
    this.props.onCloseOrBackdropClick()
  }

  onCloseOrBackdropClick = (formProps: FormRenderProps) => () => {
    formProps.form.reset()
    this.props.onCloseOrBackdropClick()
  }
}

export default ObservationModal
