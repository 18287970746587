import { Heading, HFlow, Theme, useStyles, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import { ButtonLink } from 'components/router/ButtonLink'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route } from 'react-router-dom'

import VacationContainer from './VacationContainer'

export interface VacationRootViewProps extends RouteComponentProps<any> {}

export function VacationRootView(props: VacationRootViewProps) {
  const { match } = props
  const { classes } = useStyles(createStyles)

  return (
    <>
      <Container style={classes.headerContainer}>
        <VFlow vSpacing={1.25}>
          <HFlow justifyContent='space-between' alignItems='center'>
            <Heading level={1}>Férias</Heading>
            <ButtonLink kind='primary' to={'/vacation/create'}>
              Agendar férias
            </ButtonLink>
          </HFlow>
        </VFlow>
      </Container>

      <Container style={classes.contentContainer}>
        <Route path={`${match.url}`} component={VacationContainer} />
      </Container>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    background: theme.pallete.surface.main,
    color: theme.pallete.text.main,
    borderBottom: `1px solid ${theme.pallete.divider}`,
  } as React.CSSProperties,
  headerContainer: {
    paddingBottom: '2.5rem',
    paddingTop: '2.5rem',
    borderBottom: `1px solid ${theme.pallete.divider}`,
    ul: {
      margin: '0rem',
    },
  } as React.CSSProperties,
  contentContainer: {
    paddingTop: '1rem',
  } as React.CSSProperties,
})

export default VacationRootView
