import { BaseFilter } from 'backend'
import moment from 'moment'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import DailyRegisterRequester from 'store/requesters/DailyRegisterRequester'
import TimeBalanceRequester from 'store/requesters/TimeBalanceRequester'
import { DATE_ISO, todayISO } from 'utils/DateUtils'

import DailyRegisterView from './DailyRegisterView'

export interface OwnProps extends RouteComponentProps {}

export const mapStateToProps = state => ({
  timeBalance: TimeBalanceRequester.fetchCurrent.getResult(state),
  userId: state.auth.user.userId,
})

export const mapDispatchToProps = dispatch => ({
  loadToday: () => {
    const filter = {
      fromDate: todayISO(),
      toDate: todayISO(),
    } as BaseFilter
    dispatch(DailyRegisterRequester.fetchOne.setParams(filter))
    dispatch(DailyRegisterRequester.fetchOne.request())
  },

  loadWeek: () => {
    const filter = {
      fromDate: moment()
        .startOf('week')
        .format(DATE_ISO),
      toDate: moment()
        .endOf('week')
        .format(DATE_ISO),
    } as BaseFilter
    dispatch(DailyRegisterRequester.fetchAll.setParams(filter))
    dispatch(DailyRegisterRequester.fetchAll.request())
  },

  loadBalance: (userId: number) => {
    dispatch(TimeBalanceRequester.fetchCurrent.request())
  },
})

export default withRouter(
  connect<{}, {}, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DailyRegisterView)
)
