import { AbsenceDto, VacationReportDto } from 'backend'
import { DataTable, defaultSelectFilter, Text, useStyles, useTheme, VFlow } from 'bold-ui'
import moment from 'moment'
import React, { useState } from 'react'
import { DATE_PT_BR } from 'utils/DateUtils'
import { baseStyles } from 'view/baseStyles'
import { asPrettySald, getNumberStyle } from 'view/report/ReportUtils'

export interface VacationReportTableProps {
  dto: VacationReportDto[]
  loading: boolean
  filter: string
  saldFilter: { label: string; filter: (sald: number) => boolean }
  initialDate: Date
  finalDate: Date
  employeeFilter: boolean
  traineeFilter: boolean
}

export function VacationReportTable(props: VacationReportTableProps) {
  const { dto, loading, filter, saldFilter, initialDate, finalDate, employeeFilter, traineeFilter } = props
  const theme = useTheme()

  const [sort, setSort] = useState(['collaboratorName'])
  const handleSortChange = (s: string[]) => setSort(s)

  const rows = (dto || []).sort((a, b) => {
    if (sort[0] === 'collaboratorName') {
      return a.collaboratorName < b.collaboratorName ? -1 : 1
    }

    if (sort[0] === '-collaboratorName') {
      return a.collaboratorName > b.collaboratorName ? -1 : 1
    }

    if (sort[0] === 'vacationSald') {
      return a.vacationSald < b.vacationSald ? -1 : 1
    }

    if (sort[0] === '-vacationSald') {
      return a.vacationSald > b.vacationSald ? -1 : 1
    }

    if (sort[0] === 'scheduledDays') {
      return a.scheduledDays < b.scheduledDays ? -1 : 1
    }

    if (sort[0] === '-scheduledDays') {
      return a.scheduledDays > b.scheduledDays ? -1 : 1
    }

    if (sort[0] === 'vacations') {
      if (a.vacations && b.vacations) {
        const menorDataA = a.vacations.flatMap(vac => new Date(vac.startDate)).sort()[0]
        const menorDataB = b.vacations.flatMap(vac => new Date(vac.startDate)).sort()[0]

        if (typeof menorDataA === 'undefined' && typeof menorDataB !== 'undefined') {
          return 1
        }
        if (typeof menorDataA !== 'undefined' && typeof menorDataB === 'undefined') {
          return -1
        }
        return menorDataA < menorDataB ? -1 : 1
      }
    }

    if (sort[0] === '-vacations') {
      if (a.vacations && b.vacations) {
        const menorDataA = a.vacations.flatMap(vac => new Date(vac.startDate)).sort()[0]
        const menorDataB = b.vacations.flatMap(vac => new Date(vac.startDate)).sort()[0]

        if (typeof menorDataA === 'undefined' && typeof menorDataB !== 'undefined') {
          return 1
        }
        if (typeof menorDataA !== 'undefined' && typeof menorDataB === 'undefined') {
          return -1
        }
        return menorDataA > menorDataB ? -1 : 1
      }
    }

    return 0
  })

  const filteredRows = defaultSelectFilter(rows, filter, item => item.collaboratorName + item.vacationSald)
    .filter(item => (saldFilter ? saldFilter.filter(item.vacationSald) : true))
    .filter(item =>
      initialDate && finalDate
        ? item.vacations.find(
            vacation => initialDate <= new Date(vacation.endDate) && finalDate >= new Date(vacation.startDate)
          )
        : true
    )
    .filter(item =>
      traineeFilter && employeeFilter
        ? true
        : employeeFilter
        ? item.collaboratorJobBond === 'EMPLOYEE'
        : traineeFilter
        ? item.collaboratorJobBond === 'INTERN'
        : true
    )

  const { classes } = useStyles(baseStyles)

  function asScheduledTime(startDate: string, endDate: string) {
    const startDateFormated = moment(startDate).format(DATE_PT_BR)
    const endDateFormated = moment(endDate).format(DATE_PT_BR)
    const period = startDateFormated + ' - ' + endDateFormated
    let dateStyle = classes.date
    if (new Date(endDate) < new Date()) {
      dateStyle = classes.dateDisabled
    }

    return <Text style={dateStyle}>{period}</Text>
  }

  function asScheduledRows(vacations: AbsenceDto[]) {
    if (vacations.length === 0) {
      return '-'
    } else {
      vacations.sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
      return (
        <VFlow vSpacing={0}>{vacations.map(vacation => asScheduledTime(vacation.startDate, vacation.endDate))}</VFlow>
      )
    }
  }

  return (
    <DataTable<VacationReportDto>
      rows={filteredRows}
      sort={sort}
      onSortChange={handleSortChange}
      loading={loading}
      columns={[
        {
          name: 'collaboratorName',
          header: 'Nome',
          sortable: true,
          render: item => item.collaboratorName,
        },
        {
          name: 'vacations',
          header: 'Período agendado',
          sortable: true,
          render: item => {
            return asScheduledRows(item.vacations)
          },
        },
        {
          name: 'scheduledDays',
          header: 'Total períodos',
          sortable: true,
          render: item => item.scheduledDays,
        },
        {
          name: 'vacationSald',
          header: 'Saldo',
          sortable: true,
          render: item => (
            <Text style={getNumberStyle(item.vacationSald, theme).text}> {asPrettySald(item.vacationSald, true)} </Text>
          ),
        },
      ]}
    />
  )
}
