import { CollaboratorFilter } from 'backend'
import { connect } from 'react-redux'
import CollaboratorRequester from 'store/requesters/CollaboratorRequester'

import CollaboratorListingView from './CollaboratorListingView'

export const mapState = state => ({
  allCollaborators: CollaboratorRequester.listAll.getResult(state),
})

export const mapDispatch = dispatch => ({
  load: (collaboratorsIds?: number[]) => {
    const filter = {
      ids: collaboratorsIds,
    } as CollaboratorFilter
    dispatch(CollaboratorRequester.listAll.setParams(filter))
    dispatch(CollaboratorRequester.listAll.request())
  },
})

export default connect(
  mapState,
  mapDispatch
)(CollaboratorListingView)
