import api from 'api'
import { AbsenceDto } from 'backend'
import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Theme, useStyles } from 'bold-ui'
import React, { useState } from 'react'

import VacationRowFutureVisualize from './VacationRowFutureVisualize'

interface VacationListFutureViewProps {
  title: string
  vacations: AbsenceDto[]
  onUpdate: Function
}

export function VacationListFutureView(props: VacationListFutureViewProps) {
  const { classes } = useStyles(createStyles)
  const { title, vacations, onUpdate } = props

  const [id, setId] = useState()
  const onDelete = (id: number) => {
    api.vacation.delete(id).then(() => {
      onUpdate()
      setId(undefined)
    })
  }

  return (
    <>
      <div>
        <Text style={classes.title}> {title} </Text>
        {vacations.map((value, i) => (
          <div className={classes.container}>
            <VacationRowFutureVisualize key={i} vacation={value} onDelete={() => setId(value.id)} />
          </div>
        ))}
      </div>
      <Modal size='small' onClose={() => setId(undefined)} open={id != null}>
        <ModalBody>
          <HFlow alignItems='center'>
            <Icon icon='exclamationTriangleOutline' style={{ marginRight: '0.5rem' }} size={3} fill='danger' />
            <Heading level={3}>Você deseja excluir as férias?</Heading>
          </HFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => setId(undefined)}>Cancelar</Button>
            <Button kind='danger' onClick={() => onDelete(id)}>
              Excluir
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    padding: '0.5rem 0rem 0rem',
  } as React.CSSProperties,
  header: {
    padding: '1rem',
  } as React.CSSProperties,
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  } as React.CSSProperties,
})

export default VacationListFutureView
