import moment from 'moment'

import { msg } from '..'

const asFormat = val => moment(val).format('YYYY-MM-DD')

export const beforeEqual130Years = (value: string): string => {
  if (!value) {
    return null
  }
  const today = moment().toDate()
  const minDate = new Date(today.getFullYear() - 130, today.getMonth(), today.getDate())
  const minDateFormatted = asFormat(minDate)
  if (minDateFormatted > value) {
    return msg('beforeEqual130Years', value)
  }
  return null
}

export const beforeEqualToday = (value: string): string => {
  if (!value) {
    return null
  }
  const maxDateFormatted = asFormat(moment().toDate())
  if (maxDateFormatted < value) {
    return msg('beforeEqualToday', value)
  }
  return null
}

export const dataNasc = [beforeEqual130Years, beforeEqualToday]
