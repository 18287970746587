import api from 'api'
import { AbsenceDto, AbsenceFilter } from 'backend'
import { Requester } from 'requester'

export default {
    list: new Requester<AbsenceDto[], AbsenceFilter>('absence/list', api.vacation.list),
    listHistory: new Requester<AbsenceDto[], AbsenceFilter>('absence/listHistory', api.vacation.list),
    listFuture: new Requester<AbsenceDto[], AbsenceFilter>('absence/listFuture', api.vacation.list),
    create: new Requester<AbsenceDto, AbsenceDto>('absence/create', api.vacation.create),
    edit: new Requester<AbsenceDto, AbsenceDto>('absence/edit', api.vacation.edit),
    delete: new Requester<any, Number>('absence/delete', api.vacation.delete),
}
