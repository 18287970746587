import { DailyActivityRowItem, TimeBalanceDto } from 'backend'
import { HFlow, ReferenceMonth, VFlow } from 'bold-ui'
import { isEqual } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import DailyRegisterHistoryTableContainer from 'view/dailyregister/history/DailyRegisterHistoryTableContainer'
import { getDateFromUrl } from 'view/dailyregister/history/utils/DailyRegisterHistoryUtils'
import PeriodAmount from 'view/dailyregister/PeriodAmount'
import { calculateWeeksPeriodAmount } from 'view/dailyregister/utils/DailyRegisterUtils'

import TimeBalance from '../TimeBalance'

import { DailyRegisterHistoryTableReadView } from './DailyRegisterHistoryTableReadView'
import { filterWeeksMonth } from './utils/DailyRegisterHistoryUtils'

export interface DailyRegisterHistoryViewProps extends RouteComponentProps<any> {
  timeBalance: TimeBalanceDto
  weeks: DailyActivityRowItem[][]
  userId: number
  loadBalance(): any
  loadWeeks(date: Date): any
}

export interface DailyRegisterState {
  monthlyAmount: moment.Duration
  monthlyExpected: moment.Duration
  month: ReferenceMonth
}

class DailyRegisterHistoryView extends React.Component<DailyRegisterHistoryViewProps, DailyRegisterState> {
  constructor(props) {
    super(props)

    this.state = {
      monthlyAmount: moment.duration(0),
      monthlyExpected: moment.duration(0),
      month: getDateFromUrl(props.location.search),
    }
  }

  componentDidMount() {
    this.props.loadBalance()
    this.props.loadWeeks(this.createDateFromMonth(this.state.month))
    if (this.props.weeks) {
      this.updatePeriodAmountValues(this.props.weeks)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.weeks && !isEqual(this.props.weeks, prevProps.weeks)) {
      this.props.loadBalance()
      this.updatePeriodAmountValues(this.props.weeks)
    }
  }

  render() {
    const { match, timeBalance, weeks } = this.props

    return (
      <VFlow>
        <HFlow hSpacing={2}>
          <Switch>
            <Route exact path={`${match.url}/edit`} render={this.renderDailyRegisterHistoryTableContainer} />
            <Route
              exact
              path={`${match.url}/visualize`}
              render={this.renderDailyRegisterHistoryTableReadContainer(weeks)}
            />
            <Redirect exact from={`${match.url}`} to={`${match.url}/visualize`} />
          </Switch>
          <VFlow vSpacing={1.5}>
            <PeriodAmount
              title='Total mensal'
              expectedPeriodAmount={this.state.monthlyExpected}
              periodAmount={this.state.monthlyAmount}
            />
            <TimeBalance timeBalance={timeBalance ? timeBalance.balance : '0'} />
          </VFlow>
        </HFlow>
      </VFlow>
    )
  }

  renderDailyRegisterHistoryTableContainer = () => {
    return (
      <DailyRegisterHistoryTableContainer
        onValueChange={this.updatePeriodAmountValues}
        updateWhenSubmitSucess={this.updateWhenSubmitSucess}
      />
    )
  }

  renderDailyRegisterHistoryTableReadContainer = (weeks: DailyActivityRowItem[][]) => () => {
    return (
      <DailyRegisterHistoryTableReadView
        readOnly={false}
        onMonthChange={this.onMonthChange}
        month={this.state.month}
        weeks={weeks}
      />
    )
  }

  onMonthChange = (referenceMonth: ReferenceMonth) => {
    this.props.history.replace({
      search: qs.stringify({
        date: `${referenceMonth.year}-${referenceMonth.month + 1}`,
      }),
    })

    this.setState({ month: referenceMonth })
    this.props.loadWeeks(this.createDateFromMonth(referenceMonth))
  }

  updatePeriodAmountValues = (weeks: DailyActivityRowItem[][]) => {
    const { expectedPeriodAmount, periodAmount } = calculateWeeksPeriodAmount(filterWeeksMonth(weeks))
    this.setState({ monthlyAmount: periodAmount, monthlyExpected: expectedPeriodAmount })
  }

  updateWhenSubmitSucess = () => {
    this.props.loadWeeks(this.createDateFromMonth(this.state.month))
  }

  private createDateFromMonth = (month: ReferenceMonth) => {
    return new Date(month.year, month.month, 1)
  }
}

export default DailyRegisterHistoryView
