// https://codesandbox.io/s/7k742qpo36

import { isEqual } from 'lodash'
import React from 'react'
import { FormSpy } from 'react-final-form'

export interface OnBlurProps {
    active?: any
    values?: Object
    hasSubmitErrors?: boolean
    ignoreFields?: Object[]
    callback?(value: any): Promise<any>
}

export interface OnBlurState {
    values: Object
    submitting: boolean
}

class OnBlur extends React.Component<OnBlurProps, OnBlurState> {

    private promise: Promise<any>

    constructor(props) {
        super(props)
        this.state = { values: props.values, submitting: false }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.active && this.props.active !== nextProps.active) {
            // blur occurred
            if (!this.props.ignoreFields || this.props.ignoreFields.indexOf(this.props.active) === -1) {
                this.save(this.props.active)
            }
        }
    }

    save = async blurredField => {
        if (this.promise) {
            await this.promise
        }
        const { values, callback } = this.props

        if (!isEqual(this.state.values, values)) {
            this.setState({ submitting: true, values })
            this.promise = callback(values)
            await this.promise
            delete this.promise
            this.setState({ submitting: false })
        }
    }

    render() {
        // This component doesn't have to render anything, but it can render
        // submitting state.
        return null
    }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in save prop nicely
export default props => (
    <FormSpy
        {...props}
        subscription={{ active: true, values: true }}
        component={OnBlur}
    />
)
