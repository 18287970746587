import { DailyActivityRowItem } from 'backend'
import { Button, Form, HFlow, Theme, Tooltip, useStyles } from 'bold-ui'
import { alert } from 'components/alert'
import { Icon } from 'components/Icon'
import OnBlur from 'components/OnBlur'
import { ButtonLink } from 'components/router/ButtonLink'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { RouteComponentProps } from 'react-router'
import { YEAR_MONTH } from 'utils/DateUtils'
import DailyRegisterHistoryWeeklyTable from 'view/dailyregister/history/DailyRegisterHistoryWeeklyTable'

interface DailyRegisterHistoryTableViewProps extends RouteComponentProps {
  weeks?: DailyActivityRowItem[][]

  load?(date: Date): any

  save?(forms: DailyActivityRowItem[][]): any

  getBackUrl?(props: RouteComponentProps)

  onValueChange(forms: DailyActivityRowItem[][]): void

  onSubmitSuccess?(): void
}

class DailyRegisterHistoryTableView extends React.Component<DailyRegisterHistoryTableViewProps> {
  componentWillMount() {
    this.load()
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.weeks, nextProps.weeks)
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        onSubmitSucceeded={this.props.onSubmitSuccess}
        render={this.renderForm}
        initialValues={{ weeks: this.props.weeks }}
        subscription={{ values: true }}
      />
    )
  }

  renderForm = (formProps: FormRenderProps) => {
    if (isEmpty(formProps.values.weeks)) {
      return null
    }
    const values = formProps.values.weeks as DailyActivityRowItem[][]

    return (
      <TableContainer
        header={
          <HFlow justifyContent='flex-end' hSpacing={0}>
            <OnBlur callback={this.onBlur} />

            <Tooltip text='Cancelar'>
              <ButtonLink to={this.props.getBackUrl(this.props)} skin='ghost' size='small'>
                <Icon icon='timesDefault' />
              </ButtonLink>
            </Tooltip>
            <Tooltip text='Salvar'>
              <Button
                skin='ghost'
                kind='primary'
                size='small'
                onClick={formProps.handleSubmit}
                disabled={formProps.pristine}
              >
                <Icon icon='checkDefault' />
              </Button>
            </Tooltip>
          </HFlow>
        }
      >
        {values.map((value, i) => (
          <DailyRegisterHistoryWeeklyTable key={i} weekNumber={i} values={value} formProps={formProps} />
        ))}
      </TableContainer>
    )
  }

  handleSubmit = (form: DailyActivityRowItem[][]) => {
    return this.props.save(form)
  }

  onBlur = values => {
    return this.props.onValueChange(values.weeks)
  }

  load = () => {
    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const mom = moment(searchParams.date, YEAR_MONTH)
    if (searchParams.date && mom.isValid()) {
      this.props.load(mom.toDate())
    } else if (!searchParams.date) {
      this.props.load(new Date())
    } else {
      alert('danger', 'Data informada inválida.')
      this.props.history.push('/dailyregister/history/visualize')
    }
  }
}

export default DailyRegisterHistoryTableView

export function TableContainer({ children, header }) {
  const { classes } = useStyles(historyStyles)

  return (
    <div className={classes.container}>
      <div className={classes.tableHeader}>{header}</div>

      {children}
    </div>
  )
}

export const historyStyles = (theme: Theme): { container: React.CSSProperties; tableHeader: React.CSSProperties } => ({
  container: {
    width: '43rem',
    border: `solid 1px ${theme.pallete.divider}`,
    borderRadius: '0.25rem',
    table: {
      border: 'none',
    },
  } as React.CSSProperties,
  tableHeader: {
    borderBottom: `solid 1px ${theme.pallete.divider}`,
    textAlign: 'right',
    padding: '0.5rem 1rem',
  } as React.CSSProperties,
})
