import { ScheduleIntervalDto } from 'backend'
import { FormControl, Heading, HFlow, Text, VFlow, withStyles, WithStylesProps } from 'bold-ui'
import { DateField } from 'components/date/DateField'
import React from 'react'
import TotalScheduleAmount from 'view/schedule/table/TotalScheduleAmount'

export interface ScheduleTableCreateHeaderProps extends WithStylesProps {
  intervals: { [index: string]: ScheduleIntervalDto[] }
  editMode?: boolean
}

@withStyles
class ScheduleTableCreateHeader extends React.Component<ScheduleTableCreateHeaderProps> {
  render() {
    const title = this.props.editMode ? 'Editar horário ' : 'Criar novo horário '
    return (
      <VFlow vSpacing={1.5}>
        <Heading level={1}> {title} </Heading>
        <HFlow justifyContent='space-between' alignItems='center'>
          <HFlow>
            <FormControl label='Início'>
              <DateField name='startDate' autoComplete='off' style={styleDateField} />
            </FormControl>
            <FormControl label='Fim'>
              <DateField name='endDate' autoComplete='off' style={styleDateField} />
            </FormControl>
          </HFlow>
          <HFlow justifyContent='flex-end'>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Text fontWeight='bold'> Total semanal </Text>
              <TotalScheduleAmount size={1} intervals={this.props.intervals} />
            </HFlow>
          </HFlow>
        </HFlow>
      </VFlow>
    )
  }
}

const styleDateField = { width: 160 }

export default ScheduleTableCreateHeader
