import { CollaboratorScheduleDto } from 'backend'
import { Theme, useStyles } from 'bold-ui'
import React from 'react'
import ScheduleTableVisualize from 'view/schedule/table/visualize/ScheduleTableVisualize'

import CollaboratorScheduleRowItemFooter from './CollaboratorScheduleRowItemFooter'
import CollaboratorScheduleRowItemHeader from './CollaboratorScheduleRowItemHeader'

export interface CollaboratorScheduleRowItemProps {
  collaboratorSchedule: CollaboratorScheduleDto
}

export function CollaboratorScheduleRowItem(props: CollaboratorScheduleRowItemProps) {
  const { classes } = useStyles(createStyles)

  return (
    <div className={classes.component}>
      <CollaboratorScheduleRowItemHeader
        collaborator={props.collaboratorSchedule.collaboratorDto}
        rightInfo={
          props.collaboratorSchedule.scheduleDto ? props.collaboratorSchedule.scheduleDto.weekdayIntervals : null
        }
      />
      <ScheduleTableVisualize schedule={props.collaboratorSchedule.scheduleDto} />
      <CollaboratorScheduleRowItemFooter
        endDate={props.collaboratorSchedule.scheduleDto ? props.collaboratorSchedule.scheduleDto.endDate : null}
      />
    </div>
  )
}
export default CollaboratorScheduleRowItem

const createStyles = (theme: Theme) => {
  return {
    component: {
      border: `1px solid ${theme.pallete.divider}`,
    } as React.CSSProperties,
  }
}
