import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { AbsenceDto, BaseFilter, VacationDto } from 'backend'

export class Vacation {
  create(form: AbsenceDto, config: AxiosRequestConfig): AxiosPromise<AbsenceDto> {
    return axios.post('/api/absence', form, config)
  }

  list(params: BaseFilter, config?: AxiosRequestConfig): AxiosPromise<AbsenceDto[]> {
    return axios.get('/api/absence/', { ...config, params })
  }

  edit(form: AbsenceDto, config: AxiosRequestConfig): AxiosPromise<AbsenceDto> {
    return axios.put('/api/absence/' + form.id, form, config)
  }

  delete(id: Number, config?: AxiosRequestConfig): AxiosPromise<any> {
    return axios.delete('/api/absence/' + id, config)
  }

  fetchBalance(config: AxiosRequestConfig): AxiosPromise<VacationDto> {
    return axios.get('/api/vacation', { ...config })
  }
}
