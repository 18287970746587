import { DailyActivityRowItem } from 'backend'
import {
  HFlow,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  withStyles,
  WithStylesProps,
} from 'bold-ui'
import { Icon } from 'components/Icon'
import { ButtonLink } from 'components/router/ButtonLink'
import { isEqual } from 'lodash'
import React from 'react'
import { createDailyTableStyles } from 'view/dailyregister/table/dailyTableStyles'
import DailyRegisterRowVisualize from 'view/dailyregister/table/visualize/DailyRegisterRowVisualize'
import StartEndTimeHeader from 'view/schedule/table/StartEndTimeHeader'

interface DailyRegisterTableVisualizeProps extends WithStylesProps {
  dailyRegisters?: DailyActivityRowItem[]

  load?(): any

  onValueChange(values: Object): void
}

@withStyles
class DailyRegisterTableVisualize extends React.Component<DailyRegisterTableVisualizeProps> {
  componentWillMount() {
    this.props.load()
  }

  componentDidMount() {
    this.props.onValueChange(this.props.dailyRegisters)
  }

  componentDidUpdate(prevProps) {
    if (this.props.dailyRegisters && !isEqual(this.props.dailyRegisters, prevProps.dailyRegisters)) {
      this.props.onValueChange(this.props.dailyRegisters)
    }
  }

  render() {
    const { css, theme, dailyRegisters } = this.props

    if (!dailyRegisters) {
      return null
    }
    const style = createDailyTableStyles(theme)

    return (
      <div className={css(style.container)}>
        <div className={css(style.tableHeader)}>
          <Tooltip text='Editar'>
            <ButtonLink to='/dailyregister/current/edit' skin='ghost' size='small'>
              <Icon icon='penFilled' />
            </ButtonLink>
          </Tooltip>
        </div>
        <Table>
          <TableHead>
            <HeaderTitleRow style={css(style.header)} />
          </TableHead>
          <TableBody>
            {dailyRegisters.map((value, i) => (
              <DailyRegisterRowVisualize key={i} dailyRegister={value} />
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const HeaderTitleRow = ({ style }) => {
  return (
    <TableRow className={style}>
      <TableHeader>
        <HFlow alignItems='flex-start'>
          <Text> Dia</Text>
        </HFlow>
      </TableHeader>
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <StartEndTimeHeader />
      <TableHeader>
        <Text> {''}</Text>
      </TableHeader>
      <TableHeader>
        <HFlow hSpacing={0.5}>
          <Text> Total</Text>
          <Text> Saldo</Text>
        </HFlow>
      </TableHeader>
    </TableRow>
  )
}

export default DailyRegisterTableVisualize
