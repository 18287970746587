import { CollaboratorFilter } from 'backend'
import { connect } from 'react-redux'

import CollaboratorVacationView from './CollaboratorVacationView'

export const mapState = (state, ownProps) => {
  return {
    collaboratorsVacations: ownProps.requester.getResult(state),
  }
}

export const mapDispatch = (dispatch, ownProps) => ({
  load: (filter: CollaboratorFilter) => {
    dispatch(ownProps.requester.setParams(filter))
    dispatch(ownProps.requester.request())
  },
  clear: () => {
    dispatch(ownProps.requester.clearResult())
  },
})

export default connect(
  mapState,
  mapDispatch
)(CollaboratorVacationView)
