import { connect } from 'react-redux'
import { actions } from 'store/modules/auth'
import { UserBar } from 'view/user/UserBar'

export const mapStateToProps = (state) => ({
    userName: state.auth.user.name,
    userTeam: '',
    userImgLink: state.auth.user.imgLink,
})

export const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(actions.logout())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBar)
