import { CitizenStatusEnum, DegreesEnum, JobBondEnum, RoleEnum, ShiftEnum, SupposedDateEnum } from 'backend'

export const civilStatus: { [key in CitizenStatusEnum]: string } = {
  SINGLE: 'Solteiro(a)',
  MARRIED: 'Casado(a)',
  UNION: 'União estável',
  DIVORCED: 'Divorciado(a)',
  WIDOW: 'Viúvo(a)',
}

export const degrees: { [key in DegreesEnum]: string } = {
  GRADUATION: 'Graduação',
  MASTER: 'Mestrado',
  PHD: 'Doutorado',
}

export const shifts: { [key in ShiftEnum]: string } = {
  MORNING: 'Matutino',
  AFTERNOON: 'Verspertino',
  NIGHT: 'Noturno',
  ALLDAY: 'Diurno',
}

export const roles: { [key in RoleEnum]: string } = {
  ADMINISTRATOR: 'Administrador',
  LEADER: 'Lider',
  COLLABORATOR: 'Colaborador',
}

export const jobBonds: { [key in JobBondEnum]: string } = {
  INTERN: 'Bolsista',
  EMPLOYEE: 'CLT',
}

export const supposedDates: { [key in SupposedDateEnum]: string } = {
  S20191: '2019.1',
  S20192: '2019.2',
  S20201: '2020.1',
  S20202: '2020.2',
  S20211: '2021.1',
  S20212: '2021.2',
  S20221: '2022.1',
  S20222: '2022.2',
  S20231: '2023.1',
  S20232: '2023.2',
  S20241: '2024.1',
  S20242: '2024.2',
  S20251: '2025.1',
  S20252: '2025.2',
  S20261: '2026.1',
  S20262: '2026.2',
  S20271: '2027.1',
  S20272: '2027.2',
  S20281: '2028.1',
  S20282: '2028.2',
  S20291: '2029.1',
  S20292: '2029.2',
}
