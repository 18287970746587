import { maxLength, minLength, RuleNestedArray } from 'utils/validation'

import { msg } from '..'

const AT_LEAST_A_LETTER = /^(?=.*[a-zA-Z]).*$/
const AT_LEAST_A_NUMBER = /^(?=.*[0-9]).*$/
const NOT_SPECIAL_CHARS_AND_AT_LEAST_A_NUMBER = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]*$/

export const senha: RuleNestedArray = [
  minLength(8),
  maxLength(20),
  (inputValue: string): string => {
    // Testar se possui ao menos uma letra e um numero
    if (!AT_LEAST_A_LETTER.test(inputValue) || !AT_LEAST_A_NUMBER.test(inputValue)) {
      return msg('senha', inputValue)
    }

    // Verificar se possui algum caractere especial
    if (!NOT_SPECIAL_CHARS_AND_AT_LEAST_A_NUMBER.test(inputValue) || inputValue === '') {
      return msg('senhaSpecialChars', inputValue)
    }
    return null
  },
]
