import { Theme } from 'bold-ui'

export const baseStyles = (
  theme: Theme
): {
  container: React.CSSProperties
  date: React.CSSProperties
  headerContainer: React.CSSProperties
  contentContainer: React.CSSProperties
  dateDisabled: React.CSSProperties
} => ({
  headerContainer: {
    paddingTop: '3rem',
    borderBottom: `1px solid ${theme.pallete.divider}`,
    ul: {
      margin: '0rem',
    },
  } as React.CSSProperties,
  container: {
    background: theme.pallete.surface.main,
    color: theme.pallete.text.main,
    height: '9rem',
    borderBottom: `1px solid ${theme.pallete.divider}`,
  } as React.CSSProperties,
  contentContainer: {
    paddingTop: '1rem',
  } as React.CSSProperties,
  date: {
    color: theme.pallete.text.main,
  } as React.CSSProperties,
  dateDisabled: {
    color: theme.pallete.text.disabled,
  } as React.CSSProperties,
})
