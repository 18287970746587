import { AbsenceDto } from 'backend'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import VacationRequester from 'store/requesters/VacationRequester'
import VacationCreateView from 'view/vacation/VacationCreateView'

export interface OwnProps extends RouteComponentProps<any> {
    onSubmitSucceeded(): void
}

export const mapStateToProps = () => ({

})

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
    save: (dto: AbsenceDto) => {
        dto.type = 'VACATION'
        dispatch(VacationRequester.create.setParams(dto))
        return dispatch(VacationRequester.create.request())
    },
    goBack() {
        ownProps.onSubmitSucceeded()
        ownProps.history.push(`/vacation`)
    },
})

export default withRouter(connect<{}, {}, OwnProps>(mapStateToProps, mapDispatchToProps)(VacationCreateView))
