import { Styles, Theme } from 'bold-ui'
import * as moment from 'moment'
import { getDurationSignal, NumberSignal } from 'view/dailyregister/utils/DailyRegisterUtils'

export const getBalanceStyle = (balance: moment.Duration, theme: Theme): Styles => {
  const signal: NumberSignal = getDurationSignal(balance)
  switch (signal) {
    case 'NEGATIVE':
      return {
        text: {
          color: theme.pallete.status.danger.main,
          fontWeight: 'bold',
        },
      }
    case 'POSITIVE':
      return {
        text: {
          color: theme.pallete.primary.main,
          fontWeight: 'bold',
        },
      }
    default:
      return {
        text: {
          color: theme.pallete.text.disabled,
          fontWeight: 'bold',
        },
      }
  }
}

export const getNumberSignal = (number: number): NumberSignal => {
  if (number > 0) {
    return 'POSITIVE'
  }
  if (number === 0) {
    return 'ZERO'
  }
  return 'NEGATIVE'
}

export const getNumberStyle = (vacationNumber: number, theme: Theme): Styles => {
  const signal: NumberSignal = getNumberSignal(vacationNumber)
  switch (signal) {
    case 'NEGATIVE':
      return {
        text: {
          color: theme.pallete.status.danger.main,
          fontWeight: 'bold',
        },
      }
    case 'POSITIVE':
      return {
        text: {
          color: theme.pallete.primary.main,
          fontWeight: 'bold',
        },
      }
    default:
      return {
        text: {
          color: theme.pallete.text.disabled,
          fontWeight: 'bold',
        },
      }
  }
}

export const asPrettySald = (vacationSald: number, withSignal?: boolean) => {
  let prefix
  if (withSignal && vacationSald === 0) {
    prefix = ''
  } else if (withSignal) {
    const isNegative = vacationSald < 0
    prefix = isNegative ? '' : '+'
  }
  return prefix + vacationSald
}

export const createStylesTable = (theme: Theme) => ({
  textInput: {
    height: '2rem',
    marginLeft: '1rem',
  },
  check: {
    paddingTop: '0.4rem',
    marginLeft: '2rem',
  },
  table: {
    paddingRight: '31.25rem',
  },
  combobox: {
    width: '18rem',
  },
})
