import api from 'api'
import { TimeBalanceReportDto } from 'backend'
import { Checkbox, FormControl, HFlow, Select, TextInput, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import React, { useEffect, useState } from 'react'
import { useRequester } from 'requester/useRequester'
import { BalanceTable } from 'view/report/balance/BalanceTable'

export function BalanceReportView() {
  const { request: balanceReportRequest, result: balanceSald, isLoading } = useRequester<TimeBalanceReportDto[]>(
    api.report.fetchTimeBalance
  )

  const items = [
    { label: 'Mais de 10 horas positivas', filter: balance => balance > 10 },
    { label: 'Mais de 10 horas negativas', filter: balance => balance < -10 },
    { label: 'Mais de 20 horas positivas', filter: balance => balance > 20 },
    { label: 'Mais de 20 horas negativas', filter: balance => balance < -20 },
    { label: 'Mais de 30 horas positivas', filter: balance => balance > 30 },
    { label: 'Mais de 30 horas negativas', filter: balance => balance < -30 },
  ]
  const itemToString = (item: any) => item && item.label

  const [filter, setFilter] = useState('')
  const [saldoFilter, setSaldoFilter] = useState(null)
  const [traineeFilter, setTraineeFilter] = useState(null)
  const [employeeFilter, setEmployeeFilter] = useState(null)
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e ? e.target.value : '')
  const handleSelectChange = item => setSaldoFilter(item)
  const handleCheckboxTraineeChange = (e: React.ChangeEvent<HTMLInputElement>) => setTraineeFilter(e.target.checked)
  const handleCheckboxEmployeeChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmployeeFilter(e.target.checked)

  useEffect(() => {
    balanceReportRequest()
  }, [])

  return (
    <>
      <Container>
        <VFlow vSpacing={2}>
          <HFlow hSpacing={2}>
            <VFlow>
              <FormControl label='Colaborador'>
                <TextInput
                  size={30}
                  placeholder='Pesquise pelo nome do colaborador'
                  value={filter}
                  onChange={handleFilterChange}
                />
              </FormControl>
              <HFlow>
                <FormControl name='checkEmployee'>
                  <Checkbox label='CLT' onChange={handleCheckboxEmployeeChange} />
                </FormControl>
                <FormControl name='checkTrainee'>
                  <Checkbox label='Bolsista' onChange={handleCheckboxTraineeChange} />
                </FormControl>
              </HFlow>
            </VFlow>

            <FormControl label='Saldo'>
              <Select items={items} itemToString={itemToString} value={saldoFilter} onChange={handleSelectChange} />
            </FormControl>
          </HFlow>

          <BalanceTable
            dto={balanceSald}
            loading={isLoading}
            filter={filter}
            saldoFilter={saldoFilter}
            traineeFilter={traineeFilter}
            employeeFilter={employeeFilter}
          />
        </VFlow>
      </Container>
    </>
  )
}
