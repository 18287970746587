import api from 'api'
import { VacationReportDto } from 'backend'
import { Checkbox, DatePickerInput, FormControl, HFlow, Select, TextInput, VFlow } from 'bold-ui'
import { Container } from 'components/Container'
import React, { useEffect, useState } from 'react'
import { useRequester } from 'requester/useRequester'
import { VacationReportTable } from 'view/report/vacation/VacationReportTable'

export function VacationReportView() {
  const { request: vacationReportRequest, result: vacationList, isLoading } = useRequester<VacationReportDto[]>(
    api.report.fetchVacation
  )

  const [filter, setFilter] = useState('')
  const [traineeFilter, setTraineeFilter] = useState(null)
  const [employeeFilter, setEmployeeFilter] = useState(null)
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)

  useEffect(() => {
    vacationReportRequest()
  }, [])

  const items = [
    { label: 'Mais de 10 dias positivos', filter: balance => balance > 10 },
    { label: 'Mais de 10 dias negativos', filter: balance => balance < -10 },
    { label: 'Mais de 20 dias positivos', filter: balance => balance > 20 },
    { label: 'Mais de 20 dias negativos', filter: balance => balance < -20 },
    { label: 'Mais de 30 dias positivos', filter: balance => balance > 30 },
    { label: 'Mais de 30 dias negativos', filter: balance => balance < -30 },
  ]
  const itemToString = (item: any) => item && item.label
  const [saldFilter, setSaldFilter] = useState(null)
  const handleSelectChange = item => setSaldFilter(item)

  const [initialDate, setInitialDate] = useState<Date>()
  const handleInitialDateChange = (date: Date) => setInitialDate(date)

  const [finalDate, setFinalDate] = useState<Date>()
  const handleFinalDateChange = (date: Date) => setFinalDate(date)
  const handleCheckboxTraineeChange = (e: React.ChangeEvent<HTMLInputElement>) => setTraineeFilter(e.target.checked)
  const handleCheckboxEmployeeChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmployeeFilter(e.target.checked)
  return (
    <>
      <Container>
        <VFlow vSpacing={2}>
          <HFlow hSpacing={2}>
            <VFlow>
              <FormControl label='Colaborador'>
                <TextInput size={30} placeholder='Pesquise pelo nome do colaborador' onChange={handleFilterChange} />
              </FormControl>
              <HFlow>
                <FormControl name='checkEmployee'>
                  <Checkbox label='CLT' onChange={handleCheckboxEmployeeChange} />
                </FormControl>
                <FormControl name='checkTrainee'>
                  <Checkbox label='Bolsista' onChange={handleCheckboxTraineeChange} />
                </FormControl>
              </HFlow>
            </VFlow>
            <FormControl label='Período'>
              <DatePickerInput value={initialDate} onChange={handleInitialDateChange} />
            </FormControl>
            <FormControl label={<span>&nbsp;</span>}>
              <DatePickerInput value={finalDate} onChange={handleFinalDateChange} />
            </FormControl>
            <FormControl label='Saldo'>
              <Select items={items} itemToString={itemToString} value={saldFilter} onChange={handleSelectChange} />
            </FormControl>
          </HFlow>
          <VacationReportTable
            dto={vacationList}
            loading={isLoading}
            filter={filter}
            saldFilter={saldFilter}
            initialDate={initialDate}
            finalDate={finalDate}
            employeeFilter={employeeFilter}
            traineeFilter={traineeFilter}
          />
        </VFlow>
      </Container>
    </>
  )
}
