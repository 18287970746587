import { JobBondEnum } from 'backend'
import { SelectField } from 'bold-ui'
import React from 'react'
import { jobBonds } from 'types/enums'

export interface JobBondSelectFieldProps {
  label: string
  name: string
  required: boolean
}

export const JobBondSelectField = (props: JobBondSelectFieldProps) => {
  const { ...rest } = props

  const itemToString = (item: JobBondEnum) => {
    return jobBonds[item]
  }

  const items = Object.keys(jobBonds) as JobBondEnum[]
  return <SelectField<JobBondEnum> items={items} itemToString={itemToString} {...rest} />
}
