import { useRouter } from 'hooks/useRouter'
import * as React from 'react'
import { Redirect, Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { Role } from 'utils/RoleUtils'
import PrivateRoute from 'view/user/PrivateRoute'

import CollaboratorListingContainer from './CollaboratorListingContainer'
import { CollaboratorDataRootView } from './dailyregister/CollaboratorDataRootView'

export function CollaboratorRootView() {
  const { match } = useRouter()

  return (
    <Switch>
      <PrivateRoute
        roles={[Role.ADMINISTRATOR, Role.LEADER]}
        path={`${match.url}/data/:userId`}
        component={CollaboratorDataRootView}
      />
      <Route path={`${match.url}/`} component={CollaboratorListingContainer} />
      <Redirect from={`${match.url}`} to={`${match.url}/data/:userId`} />
    </Switch>
  )
}
