import { VacationDto } from 'backend'
import { Button, HFlow, Icon, Text, Theme, useStyles } from 'bold-ui'
import vacationPng from 'images/vacation.png'
import React, { useState } from 'react'
import VacationTextEmployee from './VacationTextEmployee'
import VacationTextIntern from './VacationTextIntern'

interface VacationBalanceProps {
  vacation: VacationDto
}

const VacationBalance = (props: VacationBalanceProps) => {
  const { vacation } = props

  const { classes } = useStyles(createStyles)
  const [isVisible, setVisible] = useState(false)

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setVisible(visible => !visible)
  }

  if (!vacation) {
    return null
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.image}>
          <div className={classes.content}>
            <HFlow>
              <Text style={classes.title} size={1.5}>
                {vacation.daysAvailable} dia(s)
                <Text style={classes.subtitle} size={0.8}>
                  para usufruir
                </Text>
              </Text>
            </HFlow>
          </div>
        </div>
      </div>
      <div className={classes.borderous}>
        <Button style={classes.link} onClick={handleLinkClick}>
          <Icon icon={isVisible ? 'angleUp' : 'angleDown'} />
          <Text>Como é feito o cálculo de férias?</Text>
        </Button>
        {isVisible && vacation.isIntern && <VacationTextIntern classes={classes} />}
        {isVisible && !vacation.isIntern && <VacationTextEmployee classes={classes} />}
      </div>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    backgroundImage: 'linear-gradient(97deg, #0099ff, #56bbfe)',
    borderRadius: '0.25rem 0.25rem 0 0',
  } as React.CSSProperties,
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    color: theme.pallete.gray.c100,
  } as React.CSSProperties,
  subtitle: {
    padding: '0rem 0.5rem',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: theme.pallete.gray.c100,
  } as React.CSSProperties,
  content: {
    padding: '1.625rem 1.625rem',
  } as React.CSSProperties,
  image: {
    backgroundImage: `url('${vacationPng}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 118px bottom',
    backgroundSize: '69px, 4.5rem',
  } as React.CSSProperties,
  link: {
    border: 'none',
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    fontSize: '0.875rem',
    color: theme.pallete.text.main,
    textDecoration: 'none',
    transition: 'background 0.2s ease',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.pallete.text.main,
      backgroundColor: `${theme.pallete.surface.main} !important`,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  } as React.CSSProperties,
  bolder: {
    fontWeight: 'bold',
  } as React.CSSProperties,
  borderous: {
    borderLeft: `1px solid ${theme.pallete.divider}`,
    borderBottom: `1px solid ${theme.pallete.divider}`,
    borderRight: `1px solid ${theme.pallete.divider}`,
    padding: '1.0375rem 1.5rem 1.0375rem 1.5rem',
  } as React.CSSProperties,
  justifier: {
    textAlign: 'justify',
  } as React.CSSProperties,
  b_blue: {
    fontWeight: 'bold',
    color: '#0099ff',
  } as React.CSSProperties,
  txtContainer: {
    width: '35rem',
  } as React.CSSProperties,
  grayContainer: {
    backgroundColor: theme.pallete.gray.c90,
    width: '17.1875rem',
    borderRadius: '0.25em',
    padding: '1.625rem 1.25rem 1.625rem 1.25rem',
  } as React.CSSProperties,
})

export default VacationBalance
