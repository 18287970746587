import { Alert, withStyles, WithStylesProps } from 'bold-ui'
import { Interpolation, keyframes } from 'emotion'
import React from 'react'
import { NotificationItem } from 'store/modules/notification'

export interface NotificationWrapperProps extends WithStylesProps {
  notifications: NotificationItem[]
  removeNotification(id: NotificationItem['id']): any
  restartNotificationTimeout(id: NotificationItem['id'])
  stopNotificationTimeout(id: NotificationItem['id'])
}

@withStyles
class NotificationWrapperCmp extends React.Component<NotificationWrapperProps> {
  render() {
    const { removeNotification, restartNotificationTimeout, stopNotificationTimeout } = this.props

    const fadeInFromTop = keyframes({
      from: {
        opacity: 0,
        transform: 'translateY(-10%)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    })

    const notificationStyles: Interpolation = {
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: '0',
      animation: `${fadeInFromTop} 400ms linear`,
    }
    return (
      <div>
        {this.props.notifications.map(notif => {
          const onClick = () => removeNotification(notif.id)
          const enter = () => stopNotificationTimeout(notif.id)
          const leave = () => restartNotificationTimeout(notif.id)
          return (
            <Alert
              key={notif.id}
              onCloseClick={onClick}
              styles={{
                wrapper: notificationStyles,
                container: {
                  flexGrow: 0,
                  flexBasis: 960,
                },
              }}
              type={notif.type}
              onMouseEnter={enter}
              onMouseLeave={leave}
            >
              {notif.message}
            </Alert>
          )
        })}
      </div>
    )
  }
}

export const NotificationWrapper = withStyles(NotificationWrapperCmp)
